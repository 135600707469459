import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./HistoryModal.module.scss";
import Modal from "../../ui/modal/Modal";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import SearchSortView from "../../ui/searchSort/searchSortView/SearchSortView";
import HistoryList from "../../historyList/HistoryList";
import subscriptionManager from "../../../backend/managers/SubscriptionManager";

function HistoryModal({ isModalOpen, onClose, dataSource }) {
  const OPTIONS = subscriptionManager.getHistoryOptions();

  const [selectedFilterType, setSelectedFilterType] = useState(OPTIONS[0]);
  const [historyListDataSource, setHistoryListDataSource] = useState(
    dataSource || []
  );
  const [searchInputValue, setSearchInputValue] = useState("");

  const getFilteredData = () => {
    return selectedFilterType?.id === "all"
      ? dataSource
      : dataSource.filter(
          (history) => history?.type === selectedFilterType?.id
        );
  };

  useEffect(() => {
    if (selectedFilterType) {
      setHistoryListDataSource(
        searchInputValue
          ? subscriptionManager.searchHistoryLocally(
              searchInputValue,
              getFilteredData()
            )
          : getFilteredData()
      );
    }
    // eslint-disable-next-line
  }, [selectedFilterType]);

  useEffect(() => {
    if (!searchInputValue) {
      setHistoryListDataSource(getFilteredData());
      return;
    }
    const timeoutId = setTimeout(() => {
      setHistoryListDataSource(
        subscriptionManager.searchHistoryLocally(searchInputValue, getFilteredData())
      );
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <Modal
      className={classes.modal}
      isOpen={isModalOpen}
      onClose={onClose}
      backdropClassName={classes.backdrop}
    >
      <h4>{t("allHistory")}</h4>
      <SearchSortView
        hasSort={true}
        className={classes.searchSortView}
        searchInputClassName={classes.searchInput}
        expButtonClassName={classes.expButton}
        options={OPTIONS}
        selectedSortType={selectedFilterType}
        onSelectSortType={setSelectedFilterType}
        pickerViewImageName={"radio-purple-checked-big"}
        searchInputValue={searchInputValue}
        onClearSearchIput={() => setSearchInputValue("")}
        onChangeSearchInput={(value) => setSearchInputValue(value)}
      />
      <HistoryList
        className={classes.historyList}
        dataSource={historyListDataSource}
      />
      <Line />
      <div className={classes.actionContainer}>
        <Button title={t("close")} onClick={onClose} />
      </div>
    </Modal>
  );
}

export default HistoryModal;
