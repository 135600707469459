import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import classes from "./ForgotPassword.module.scss";
import SigninLayout from "../../components/signinLayout/SigninLayout";
import authManager from "../../backend/managers/AuthManager";
import { validateEmail } from "../../utils/Validators";

function ForgotPassword() {
  const navigate = useNavigate();

  const validateEmailHandler = (credentials) => {
    const { email } = credentials;
    return validateEmail(email);
  };

  const submitEmailHandler = async (credentials) => {
    const result = await authManager.forgotPasswordAPi(credentials);
    if (result.status === 0) {
      navigate(`/login?email=${credentials?.email}`);
    }
    return result;
  };

  return (
    <>
      <SigninLayout
        initialState={{ email: "" }}
        title={t("forgotPassword") + " ?"}
        label={t("forgotPasswordLabel")}
        input1={{
          id: "email",
          label: t("email"),
          placeholder: t("placeholderEmail"),
          type: "text",
        }}
        btnTitle={t("confirm")}
        signSectionClassName={classes.emailSignSection}
        validateForm={validateEmailHandler}
        onSubmitForm={submitEmailHandler}
      />
      {/* <SigninLayout
        title={t("error_connection_title")}
        label={t("error_connection_message")}
        signSectionClassName={classes.errorSignSection}
      /> */}
    </>
  );
}

export default ForgotPassword;
