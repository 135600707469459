import { useState } from "react";

import classes from "./StoreLocation.module.scss";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import ContentHelper from "../../../utils/ContentHelper";
import SortHelper from "../../../utils/SortHelper";

function StoreLocationRow({ data, index, thLength, onDelete, onEdit }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const deleteHandler = (e, item, type) => {
    e.stopPropagation();
    onDelete(item, type);
  };

  const editHandler = (e, item, type, emirate) => {
    e.stopPropagation();
    onEdit(item, type, emirate);
  };

  return (
    <>
      <div
        style={{
          backgroundColor:
            index % 2 === 0 ? "var(--neutral-white)" : "var(--grey-150)",
          gridTemplateColumns: `repeat(${thLength}, 1fr)`,
        }}
        onClick={toggleExpand}
        className={classes.gridRow}
      >
        <div className={`${classes.col} ${classes.emiratesColumn}`}>
          <img
            src={require(`../../../assets/img/icons/${
              isExpanded ? "arrow-bottom" : "arrow-forward-light"
            }.png`)}
            alt="arrow"
          />
          <span>{ContentHelper.getNameByLang(data)}</span>
        </div>
        <div className={classes.col}>{data?.areas?.length}</div>
        <div className={classes.col}>{data?.ranking}</div>
        <div className={`${classes.col} ${classes.actionsContainer}`}>
          <ButtonIcon
            imagePath={"buttons/edit-purple"}
            isSvg={true}
            // className={classes.btnEdit}
            onClick={(e) => editHandler(e, data, "emirate")}
          />
          {/* <ButtonIcon
            imagePath={"buttons/delete-purple"}
            isSvg={true}
            onClick={(e) => deleteHandler(e, data, "emirate")}
          /> */}
        </div>
      </div>
      {isExpanded &&
        SortHelper.arrangeData(data?.areas, false, "ranking")?.map(
          (town, i) => (
            <div
              key={town?.id}
              className={classes.gridRow}
              style={{
                gridTemplateColumns: `repeat(${thLength}, 1fr)`,
                backgroundColor:
                  index % 2 === 0
                    ? i % 2 === 0
                      ? "var(--grey-150)"
                      : "var(--neutral-white)"
                    : i % 2 === 0
                    ? "var(--neutral-white)"
                    : "var(--grey-150)",
              }}
            >
              <div></div>
              <div className={classes.col}>
                {ContentHelper.getNameByLang(town)}
              </div>
              <div className={classes.col}>{town?.ranking}</div>
              <div className={`${classes.col} ${classes.actionsContainer}`}>
                <ButtonIcon
                  imagePath={"buttons/edit-purple"}
                  isSvg={true}
                  className={classes.btnEdit}
                  onClick={(e) => editHandler(e, town, "town", data)}
                />
                <ButtonIcon
                  imagePath={"buttons/delete-purple"}
                  isSvg={true}
                  onClick={(e) => deleteHandler(e, town, "town")}
                />
              </div>
            </div>
          )
        )}
    </>
  );
}

export default StoreLocationRow;
