import classes from "./Loader.module.scss";

function Loader({ positionNotFixed, className }) {
  return (
    <div className={classes.loaderContainer}>
      <div
        className={`${classes.loader} ${
          !positionNotFixed && classes.position
        } ${className}`}
      ></div>
    </div>
  );
}

export default Loader;
