import { t } from "i18next";

import classes from "./AddActorModal.module.scss";
import Modal from "../../ui/modal/Modal";
import PickerView from "../../pickerViews/pickerView/PickerView";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Button from "../../ui/button/Button";

function AddActorModal({ isModalOpen, onClose }) {
  return (
    <Modal
      className={classes.modal}
      backdropClassName={classes.backdrop}
      isOpen={isModalOpen}
      onClose={onClose}
      top={"35%"}
    >
      <h4>{t("add_in_database")}</h4>
      <div className={classes.row}>
        <label>
          {t("category")}
          <span className={classes.star}>*</span>
        </label>
        <PickerView
          dataSource={[{ id: "actor" }, { id: "director" }]}
          allowMultipleSelections={true}
          imageName="checkbox-checked"
          onSelectItems={() => {}}
          pickerCellHeight={35}
          pickerCellImageHeight={20}
          pickerViewClassName={classes.pickerView}
          pickerCellClassName={classes.pickerCell}
        />
      </div>
      <CustomInput
        className={classes.customInput}
        label={t("firstName")}
        row={true}
        required={true}
      />
      <CustomInput
        className={classes.customInput}
        label={t("lastName")}
        row={true}
        required={true}
      />
      <div>
        <Button title={t("cancel")} className={classes.btnCancel} />
        <Button title={t("btnSave")} />
      </div>
    </Modal>
  );
}

export default AddActorModal;
