import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./ExpandablePickerView.module.scss";
import InputImage from "../../ui/inputs/inputImage/InputImage";
import PickerView from "../pickerView/PickerView";
import SearchInput from "../../ui/inputs/searchInput/SearchInput";
import SortHelper from "../../../utils/SortHelper";
import { recipientsActions } from "../../../store/reducers/Recipients-slice";

function ExpandablePickerView({
  title,
  className,
  pickerViewClassName,
  dataSource = [],
  hasSearch,
  onSelectItems = () => {},
  isDisabled,
  onSearch,
  hasArrowIcon,
  initialSelectedItems,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [image, setImage] = useState("checkbox");
  const [selectedItems, setSelectedItems] = useState(
    initialSelectedItems || []
  );
  const [data, setData] = useState(dataSource || []);
  const [searchInputValue, setSearchInputValue] = useState("");

  const isEmpty = useSelector((state) => state.recipients.isEmpty);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty) {
      setSelectedItems([]);
      dispatch(recipientsActions.setIsEmpty(false));
      setSearchInputValue("");
    }
  }, [isEmpty]);

  const toggleExpandedPickerHandler = () => {
    setIsExpanded((prevState) => !prevState);
    setData(dataSource);
    setSearchInputValue("");
  };

  const clickInputHandler = (e) => {
    e.stopPropagation();
    if (image === "checkbox-checked") {
      setSelectedItems([]);
    } else {
      setSelectedItems(dataSource);
    }
  };

  const selectItemsHandler = (items) => {
    setSelectedItems(items);
  };

  useEffect(() => {
    const totalItems = dataSource?.length;
    const selectedCount = selectedItems.filter((e) => e !== null)?.length;
    const image =
      selectedCount === totalItems
        ? "checkbox-checked"
        : selectedCount === 0
        ? "checkbox"
        : "checkbox-dashed";
    setImage(image);
    onSelectItems(selectedItems);
    // eslint-disable-next-line
  }, [selectedItems]);

  const searchSubscriberHandler = (inputValue) => {
    setSearchInputValue(inputValue);
    setData(onSearch(dataSource, inputValue));
  };

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  return (
    <div className={`${classes.expPickerView} ${className}`}>
      <div className={classes.row} onClick={toggleExpandedPickerHandler}>
        <div className={classes.titleContainer}>
          <InputImage
            className={classes.img}
            imagePath={`input-select/${image}`}
            onClick={!isDisabled ? clickInputHandler : () => {}}
          />
          <label>{title}</label>
        </div>
        {hasArrowIcon && (
          <img
            alt="arrow"
            src={require(`../../../assets/img/icons/${
              isExpanded ? "arrow-bottom" : "arrow-forward"
            }.png`)}
            className={classes.arrowIcon}
          />
        )}
      </div>
      {isExpanded && dataSource && (
        <>
          {hasSearch && (
            <SearchInput
              className={classes.searchInput}
              onChangeInput={searchSubscriberHandler}
              value={searchInputValue}
              onClear={() => {
                setSearchInputValue("");
                setData(dataSource);
              }}
            />
          )}
          <PickerView
            pickerViewClassName={`${classes.pickerView} ${pickerViewClassName}`}
            pickerCellClassName={classes.pickerCell}
            allowMultipleSelections={true}
            dataSource={SortHelper.arrangeData(data, false, "subscriberName")}
            initialSelectedItems={selectedItems}
            pickerCellHeight={30}
            pickerCellImageHeight={18}
            circleClassName={classes.circle}
            onSelectItems={selectItemsHandler}
            isDisabled={isDisabled}
            disabledImageName={"checkbox-checked"}
          />
        </>
      )}
    </div>
  );
}

export default ExpandablePickerView;
