import { configureStore } from "@reduxjs/toolkit";
import castSlice from "./reducers/Cast-slice";
import errorSlice from "./reducers/Error-slice";
import mainHeaderSlice from "./reducers/MainHeader-slice";
import recipientsSlice from "./reducers/Recipients-slice";

// Configure Redux store using Redux Toolkit's configureStore
const store = configureStore({
  reducer: {
    cast: castSlice.reducer,
    error: errorSlice.reducer,
    mainHeader: mainHeaderSlice.reducer,
    recipients: recipientsSlice.reducer,
  },
});

// Export the configured Redux store to be used in the application
export default store;
