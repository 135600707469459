import { useState } from "react";
import { t } from "i18next";

import classes from "./Rating.module.scss";

function Rating({
  selectedStars,
  setSelectedStars,
  style,
  className,
  disabled,
}) {
  const [hover, setHover] = useState();

  const stars = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <div className={`${classes.gridRow} ${className}`} style={style}>
      <label>{t("rating")}</label>
      <div className={classes.row}>
        {stars.map((i) => {
          return (
            <img
              key={i}
              className={`${classes.star} ${
                !disabled && hover >= i && classes.hovered
              }`}
              style={{ cursor: disabled ? "default" : "pointer" }}
              alt="star"
              src={require(`../../../assets/img/icons/${
                !disabled && hover >= i
                  ? "filled"
                  : selectedStars >= i
                  ? `filled${disabled ? "-disabled" : ""}`
                  : `empty${disabled ? "-disabled" : ""}`
              }-star.png`)}
              onMouseEnter={() => (!disabled ? setHover(i) : {})}
              onMouseLeave={() => setHover(0)}
              onClick={() => (!disabled ? setSelectedStars(i) : {})}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Rating;
