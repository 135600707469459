import moment from "moment";

class DateHelper {
  static formatDate(date, pattern) {
    return moment(date).format(pattern);
  }

  static getDuration(time, unit) {
    return moment.duration(time, unit);
  }
}

export default DateHelper;
