// Import necessary dependencies
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import language resources for English and Arabic
import en from "./En";
import ar from "./Ar";

// Initialize i18n for internationalization and translation
i18n
  .use(initReactI18next) // Integrates i18n with react-i18next
  .init({
    resources: {
      en, // English language resources
      ar, // Arabic language resources
    },
    lng: "en", // Sets the default language to English
    interpolation: {
      escapeValue: false, // Prevents auto-escaping for interpolated values (already handled by React)
    },
  });

// Export the configured i18n instance for use throughout the application
export default i18n;