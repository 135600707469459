import { t } from "i18next";

import classes from "./ProfilePopupCard.module.scss";
import backdropStyle from "../../styles/Backdrop.module.scss";

function ProfilePopupCard({
  style,
  onClose,
  onOpenAccountModal,
  data,
  setIsConfirmationModalOpen,
}) {
  const username = data?.name || "";
  const role = data?.role || "";
  const lastConnection = data?.lastConnection || "";

  return (
    <>
      <div className={backdropStyle.backdrop} onClick={onClose} />
      <div style={style} className={classes.popup}>
        <div className={classes.column}>
          <span className={classes.username} onClick={onOpenAccountModal}>
            {username}
          </span>
          <span className={classes.userRole}>{role}</span>
        </div>
        <div className={classes.row}>
          <span
            className={classes.logout}
            onClick={() => {
              setIsConfirmationModalOpen(true);
              onClose();
            }}
          >
            {t("logout")}
          </span>
          {lastConnection && (
            <span className={classes.connexion}>
              {t("lastConnexion") + ": " + lastConnection}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfilePopupCard;
