import { forwardRef } from "react";
import classes from "./Button.module.scss";

const Button = forwardRef(
  ({ type, title, className, onClick, isDisabled, icon }, ref) => {
    return (
      <button
        ref={ref}
        type={type}
        className={`${classes.button} ${className} ${
          isDisabled && classes.disabled
        }`}
        onClick={!isDisabled ? onClick : () => {}}
        disabled={isDisabled}
      >
        {icon && <img src={icon} alt="send-icon" className={classes.icon} />}
        {title}
      </button>
    );
  }
);

export default Button;
