import { HELP_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

class HelpManager {
  getHelpApi = async (signal) => {
    try {
      const response = await loaderCenter.get(HELP_API.HELP, signal);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  saveContactApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.SAVE_CONTACT, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  saveTermsApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.SAVE_TERMS, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  createFAQSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.CREATE_FAQ_SECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  updateFAQSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.UPDATE_FAQ_SECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  reorderFAQSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.REORDER_FAQ_SECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  reorderFAQSubSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.REORDER_FAQ_SUBSECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  editFAQSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.EDIT_FAQ_SECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  editFAQSubSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.EDIT_FAQ_SUBSECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  deleteFAQSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.DELETE_FAQ_SECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteFAQSubSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.DELETE_FAQ_SUBSECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  restoreFAQSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.RESTORE_FAQ_SECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  restoreFAQSubSectionApi = async (data) => {
    try {
      const response = await loaderCenter.post(HELP_API.RESTORE_FAQ_SUBSECTION, data);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  
}

const helpManager = new HelpManager();

export default helpManager;
