import { useState, useRef, forwardRef } from "react";
import { t } from "i18next";
import classes from "./SubsectionDetailsCard.module.scss";

import DOMPurify from "dompurify";

import dragIcon from "../../assets/img/icons/drag.png";
import ButtonIcon from "../ui/buttonIcon/ButtonIcon";

const SubsectionDetailsCard = forwardRef(
  (
    {
      subsection,
      iconsList,
      selectedLang,
      isDraggable,
      provided,
    },
    ref
  ) => {
    const [showIcons, setShowIcons] = useState(true);

    const handleMouseOver = () => {
      setShowIcons(true);
    };

    const handleMouseOut = () => {
      setShowIcons(false);
    };


    return (
      <>
        <div
          key={subsection?.id}
          className={`${classes.subsection}`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          ref={ref}
        >
          <div
            className={`${classes.subsectionHeader} ${
              isDraggable && showIcons ? classes.dragMenu : ""
            }`}
          >
            <div className={classes.titleDragIconWrapper}>
              {showIcons && isDraggable && (
                <img
                  src={dragIcon}
                  alt={"dragIcon"}
                  className={classes.dragIcon}
                />
              )}
              <h4>{subsection?.title[selectedLang]}</h4>
            </div>
            <div className={classes.actionBtnsContainer}>
              {showIcons &&
                iconsList &&
                iconsList.map((icon) => (
                  <ButtonIcon
                    key={icon.id}
                    className={classes.actionBtn}
                    imagePath={icon.imagePath}
                    activeImagePath={icon.activeImagePath}
                    onClick={icon.onClick}
                  />
                ))}
            </div>
          </div>
          <div
            className={classes.detailsContainer}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(subsection?.details[selectedLang]),
            }}
          />
        </div>
      </>
    );
  }
);
export default SubsectionDetailsCard;
