import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./components/layout/Layout";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Customers from "./pages/dashboard/customers/Customers";
import Messages from "./pages/dashboard/messages/Messages";
import LiveTV from "./pages/dashboard/contents/liveTV/LiveTV";
import VOD from "./pages/dashboard/contents/vod/VOD";
import Series from "./pages/dashboard/contents/series/Series";
import Packages from "./pages/dashboard/contents/packages/Packages";
import Dashboard from "./pages/dashboard/dashboard/Dashboard";
import Users from "./pages/dashboard/users/Users";
import Subscriptions from "./pages/dashboard/customers/Subscriptions";
import Rows from "./pages/dashboard/contents/rows/Rows";
import Verticals from "./pages/dashboard/contents/verticals/Verticals";
import ErrorPage from "./pages/404/ErrorPage";
import NewContents from "./pages/dashboard/contents/newContents/NewContents";
import Preferences from "./pages/dashboard/portal/preferences/Preferences";
import TwoFactorAuthentication from "./pages/auth/TwoFactorAuthentication";
import Help from "./pages/dashboard/portal/help/Help";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
      <Route path="/login?email=:email" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/2FA" element={<TwoFactorAuthentication />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route path="/customers/:customerId" element={<Subscriptions />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/live-tv" element={<LiveTV />} />
      <Route path="/movies" element={<VOD />} />
      <Route path="/series" element={<Series />} />
      <Route path="/packages" element={<Packages />} />
      <Route path="/rows" element={<Rows />} />
      <Route path="/verticals" element={<Verticals />} />
      <Route path="/new-contents" element={<NewContents />} />
      <Route path="/preferences" element={<Preferences />} />
      <Route path="/help" element={<Help />} />
      <Route path="/users" element={<Users />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
