import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./ContactUsCard.module.scss";
import HelpCard from "../HelpCard";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import helpManager from "../../../backend/managers/HelpManager";
import { validateEmail } from "../../../utils/Validators";

function ContactUsCard({ data, isLoading, onSave }) {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDetails] = useState({ en: "", ar: "" });
  const [error, setError] = useState();

  useEffect(() => {
    setEmail(data?.email || "");
    setPhone(data?.phone || "");
    setDetails(data?.details || { en: "", ar: "" });
  }, [data]);

  const saveHandler = (htmlContents) => {
    const err = validateEmail(email);
    if (err) {
      setError(err);
    } else {
      onSave(email, phone, htmlContents);
    }
  };

  return (
    <HelpCard
      text={t("fill_in_contact")}
      onSave={saveHandler}
      isLoading={isLoading}
      details={details}
    >
      <div className={classes.inputsContainer}>
        <CustomInput
          label={t("support_mail")}
          placeholder={t("placeholderEmail")}
          row={true}
          className={classes.input}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError();
          }}
          type={"email"}
          span={error}
          spanClassName={classes.error}
        />
        <CustomInput
          label={t("phone_number")}
          placeholder={t("placeholderPhone")}
          row={true}
          className={classes.input}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          type={"number"}
        />
      </div>
    </HelpCard>
  );
}

export default ContactUsCard;
