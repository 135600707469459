import { t } from "i18next";

import classes from "./MenuCardLayout.module.scss";
import Card from "../ui/card/Card";
import ButtonIcon from "../ui/buttonIcon/ButtonIcon";

function MenuCardLayout({
  children,
  buttons,
  displayAllNumber,
  isDisplayAllSelected,
  onSelectDisplayAll,
  pageTitle,
  noDisplayAll,
  menuTitle,
  className,
  cardClassName,
  menuItemsListClassName,
}) {
  return (
    <div className={`${classes.layout} ${className}`}>
      <h2>{pageTitle}</h2>
      <div className={classes.headerContent}>{children[0]}</div>
      <div>
        <div className={classes.menuCardContainer}>
          <Card className={`${classes.card} ${cardClassName}`}>
            {menuTitle && (
              <span className={classes.menuTitle}>{menuTitle}</span>
            )}
            {(!noDisplayAll || buttons) && (
              <div
                className={`${classes.row} ${noDisplayAll && buttons && classes.justifyEnd
                  }`}
              >
                {!noDisplayAll && (
                  <>
                    <span
                      className={`${classes.displayAll} ${isDisplayAllSelected && classes.selected
                        }`}
                      onClick={onSelectDisplayAll}
                    >
                      {t("displayAll")}
                    </span>
                    {(displayAllNumber || displayAllNumber === 0) && (
                      <span className={classes.number}>{displayAllNumber}</span>
                    )}
                  </>
                )}
                {buttons && (
                  <div className={classes.actionsContainer}>
                    {buttons?.map((button) => {
                      const isDisabled =
                        (button.id !== "add" && isDisplayAllSelected) ||
                        button?.disabled;
                      return (
                        <ButtonIcon
                          key={button?.id}
                          className={`${classes.btn} ${isDisabled && classes.btnDisabled
                            }`}
                          imagePath={
                            isDisabled
                              ? button?.imagePath + "-disabled"
                              : button?.imagePath
                          }
                          activeImagePath={
                            isDisabled
                              ? button?.imagePath + "-disabled"
                              : button?.activeImagePath
                          }
                          onClick={isDisabled ? () => { } : button?.action}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div
              className={`${classes.menuItemsList} ${menuItemsListClassName}`}
            >
              {children[1]}
            </div>
          </Card>
          {children[4]}
          {/* StandBy (zones) */}
          {children[3]}
        </div>
      </div>
      <div>{children[2]}</div>
    </div>
  );
}

export default MenuCardLayout;
