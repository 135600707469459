function InputImage({ alt, imagePath, className, style, onClick }) {
  return (
    <img
      className={className}
      style={style}
      alt={alt}
      src={require(`../../../../assets/img/${imagePath}.png`)}
      onClick={onClick}
    />
  );
}

export default InputImage;
