// CustomPaginationFooter.js
import React from 'react';
import { t } from "i18next";
import { Box, Select, MenuItem } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import classes from './CustomPaginationFooter.module.scss';

const CustomPaginationFooter = ({ totalItems, rowsPerPage, page, setPage, setRowsPerPage, rowsPerPageOptions }) => {
  const totalPages = Math.ceil(totalItems / rowsPerPage);
  const startItem = page * rowsPerPage + 1;
  const endItem = Math.min((page + 1) * rowsPerPage, totalItems);

  const handleNext = () => {
    if (page < totalPages - 1) setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page > 0) setPage(page - 1);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="2px"
      className={classes.paginationContainer}
    >
      <div style={{ fontSize: '12px' }}>
        Showing {startItem} - {endItem} of {totalItems}
      </div>

      <div className={classes['pagination-container']}>
          {page > 0 && (
            <button className={classes['arrow-button']} onClick={handlePrevious}>
              <KeyboardArrowLeftIcon /> 
              </button>
          )}

          {Array.from({ length: 2 }).map((_, index) => {
            const pageIndex = page + index; 

            return (
              pageIndex < totalPages && (
                <button
                  key={pageIndex}
                  onClick={() => setPage(pageIndex)}
                  className={`${classes['pagination-button']} ${
                    page === pageIndex ? classes.active : ''
                  }`}
                >
                  {pageIndex + 1}
                </button>
              )
            );
          })}

          {page < totalPages - 2 && (
            <button className={classes['arrow-button']} onClick={handleNext}>
              <KeyboardArrowRightIcon /> 
            </button>
          )}
        </div>

      <div className={classes.selectContainer}>
        <p className={classes.rowsPerPageText}>{t("rows_per_page")}</p>
        <Select
          className={classes.select}
          value={rowsPerPage}
          onChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset to the first page on change
          }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#d7dbdf !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#d7dbdf !important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#d7dbdf !important',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  color: '#212547',
                  '&:hover': {
                    backgroundColor: '#ede2ff',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#f1ecfb !important',
                  },
                },
              },
            },
          }}
        >
          {rowsPerPageOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </div>
    </Box>
  );
};

export default CustomPaginationFooter;
