class ContentModel {
  constructor(data) {
    this.data = data;
  }

  setData(data) {
    this.data = data;
  }

  getActors() {
    return this.data?.cast?.filter((m) => m.type === "Actor") || [];
  }

  getDirectors() {
    return this.data?.cast?.filter((m) => m.type === "Director") || [];
  }

  getActorsValue() {
    return this.data?.actors;
  }

  getDirectorsValue() {
    return this.data?.directors;
  }

  removeActor(index) {
    const actors = this.getActors();
    actors?.splice(index, 1);
    this.data.actors = actors.join(",");
  }

  removeDirector(index) {
    const directors = this.getDirectors();
    directors?.splice(index, 1);
    this.data.directors = directors.join(",");
  }

  // getContentPosterImage(type) {
  //   if (this.data?.images?.length > 0 && this.data?.images[0]) {
  //     const imageData = this.data?.images[0];
  //     return `http://preprod-octoroom.vianeos.com/oroom_api/public/media/images/${type}/${this.data.id}/${imageData.id}.${imageData.extension}`;
  //   }
  //   return null;
  // }

  getPosterImage() {
    if (this?.data?.images?.length > 0) {
      return this.data?.images?.find(
        (image) => image?.name === "dsk_tab_poster"
      )?.url;
    }
    return null;
  }

  getLandscapeImage() {
    if (this?.data?.images?.length > 0) {
      return this.data?.images.find(
        (image) => image?.name === "landscape_poster"
      )?.url;
    }
    return null;
  }

  getBackgroundImage() {
    if (this?.data?.images?.length > 0) {
      return this.data?.images[0]?.url;
    }
    return null;
  }

  getPreviewImage() {
    if (this?.data?.images?.length > 0) {
      return this.data?.images[0]?.url;
    }
    return null;
  }

  // getContentBackgroundImage(type) {
  //   const imageData = this.data.poster;
  //   return `http://preprod-octoroom.vianeos.com/oroom_api/public/media/images/${type}/${this.data.id}/${imageData.id}.${imageData.extension}`;
  // }

  // getNameByLang(lang) {
  //   const selectedLang = lang ? lang : "en";
  //   const translation = this.data?.translation;
  //   if (translation) {
  //     const foundedLang = translation[selectedLang];
  //     if (foundedLang) {
  //       return foundedLang.name;
  //     } else {
  //       const firstLang = translation[Object.keys(translation)[0]];
  //       return firstLang.name;
  //     }
  //   }
  // }

  getNameByLang(lang) {
    const selectedLang = lang ? lang : "en";
    const title = this.data?.title;
    if (title) {
      const foundedLangName = title[selectedLang];
      if (foundedLangName) {
        return foundedLangName;
      } else {
        const firstLangName = title[Object.keys(title)[0]];
        return firstLangName;
      }
    }
  }

  getDescriptionByLang(lang) {
    const selectedLang = lang ? lang : "en";
    const shortDescription = this.data?.shortDescription;
    if (shortDescription) {
      const foundedLangDesc = shortDescription[selectedLang];
      if (foundedLangDesc) {
        return foundedLangDesc;
      } else {
        const firstLangDesc =
          shortDescription[Object.keys(shortDescription)[0]];
        return firstLangDesc;
      }
    }
  }
}

export default ContentModel;
