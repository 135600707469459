import { t } from "i18next";
import { useSelector } from "react-redux";

import classes from "./MainHeader.module.scss";
import "../../../styles/ul.module.scss";

function MainHeader() {
  const mainData1 = useSelector((state) => state.mainHeader.mainData);
  const mainData = JSON.parse(localStorage.getItem("mainData"));

  const customers =
    mainData
      ?.find((item) => item?.id === "customers")
      ?.content?.split(" ")[0] || "";
  const subscriptions =
    mainData
      ?.find((item) => item?.id === "subscriptions")
      ?.content?.split(" ")[0] || "";
  const active_devices =
    mainData
      ?.find((item) => item?.id === "active_devices")
      ?.content?.split(" ")[0] || "";

  return (
    <header className={classes.mainHeader}>
      <ul className={classes.info}>
        <li>
          {t("customers") + ": "}
          <span className={classes.value}>{customers}</span>
        </li>
        <div className={classes.dot} />
        <li>
          {t("subscriptions") + ": "}
          <span className={classes.value}>{subscriptions}</span>
        </li>
        <div className={classes.dot} />
        <li>
          {t("active_devices") + ": "}
          <span className={classes.value}>{active_devices}</span>
        </li>
      </ul>
    </header>
  );
}

export default MainHeader;
