import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./SubscriptionModal.module.scss";
import Modal from "../../ui/modal/Modal";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Button from "../../ui/button/Button";
import Line from "../../ui/line/Line";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import HistoryModal from "../historyModal/HistoryModal";
import HistoryList from "../../historyList/HistoryList";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import Loader from "../../ui/loader/Loader";
import subscriptionManager from "../../../backend/managers/SubscriptionManager";
import { errorActions } from "../../../store/reducers/Error-slice";

function SubscriptionModal({
  isModalOpen,
  onClose,
  isEditing,
  subscription,
  onResendPassword,
  onUnlinkSubscription,
}) {
  const [viewAllHistory, setViewAllHistory] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedSubscription, setUpdatedSubscription] = useState(subscription);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEditing) {
          setIsLoading(true);
          const result = await subscriptionManager.getSubscriptionDetailsApi(
            subscription?.id
          );
          result?.status === 0 && setUpdatedSubscription(result?.data);
          setIsLoading(false);
        }
      } catch (err) {
        dispatch(errorActions.setErrorMessage(err?.message));
        onClose();
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [isEditing]);

  const isActive = updatedSubscription?.isActive || "";
  const lastAction = updatedSubscription?.lastAction || "";
  const mac = updatedSubscription?.macAddress || "";
  const refnumber = updatedSubscription?.refnumber || "";
  const loginName = updatedSubscription?.loginName || "";
  const ip = updatedSubscription?.ipAddress || "";
  const email = updatedSubscription?.email || "";
  const offer = updatedSubscription?.offer || "";
  const add_ons = updatedSubscription?.addOns || [];
  const ppv = updatedSubscription?.ppv || [];
  const history = updatedSubscription?.subscriptionHistory || [];
  const preferences = updatedSubscription?.preferences;
  const ageFilter = preferences?.ageFilter;
  const vertical = preferences?.vertical;
  const language = preferences?.language;
  const location = preferences?.location;

  return (
    <>
      <Modal
        className={classes.modal}
        isOpen={isModalOpen}
        onClose={onClose}
        top={"1rem"}
      >
        <h2>{t("edit")}</h2>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.container}>
              <div className={classes.section}>
                <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {t("device")}
                  
                  <div
                    className={classes.infoIcon}
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: isActive ? '#08c34b' : (isActive === null ? '#858b99' : '#d82316'), 
                    }}
                  />
                              
                </h3>
                <div className={classes.contentContainer}>
                  <CustomInput
                    row={true}
                    label={t("Android_ID")}
                    value={mac}
                    disabled={true}
                  />
                  <CustomInput
                    row={true}
                    label={t("reference_number")}
                    value={refnumber}
                    disabled={true}
                  />
                  <CustomInput
                    row={true}
                    label={t("Login_ID")}
                    value={loginName}
                    disabled={true}
                  />
                  <CustomInput
                    row={true}
                    label={t("last_action")}
                    value={lastAction}
                    disabled={true}
                  />
                  <CustomInput
                    row={true}
                    label={t("ip")}
                    value={ip}
                    disabled={true}
                  />
                  <div className={classes.passwordRow}>
                    <CustomInput
                      row={true}
                      label={t("password")}
                      type={"password"}
                      className={classes.customInput}
                      disabled={true}
                      value={"********"}
                    />
                    <ButtonIcon
                      imagePath={"buttons/submit"}
                      className={classes.btnSubmit}
                      onClick={onResendPassword}
                      isSvg={true}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.section}>
                <h3>{t("subscription")}</h3>
                <div className={classes.contentContainer}>
                  <CustomInput
                    row={true}
                    label={t("offer")}
                    value={offer}
                    disabled={true}
                  />
                  {add_ons?.length > 0 && (
                    <div className={classes.row}>
                      <label>{t("add_ons")}</label>
                      <div>
                        {add_ons?.map((addOn) => (
                          <CustomInput
                            key={addOn}
                            value={addOn}
                            disabled={true}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {ppv?.length > 0 && (
                    <div className={classes.row}>
                      <label>{t("ppv")}</label>
                      <div>
                        {ppv?.map((e) => (
                          <CustomInput key={e} value={e} disabled={true} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.section}>
                <h3>{t("preferences")}</h3>
                <div className={classes.contentContainer}>
                  {ageFilter && (
                    <CustomInput
                      row={true}
                      label={t("ageFilter")}
                      value={ageFilter}
                      disabled={true}
                    />
                  )}
                  {vertical && (
                    <CustomInput
                      row={true}
                      label={t("vertical")}
                      value={vertical}
                      disabled={true}
                    />
                  )}
                  {language && (
                    <CustomInput
                      row={true}
                      label={t("language")}
                      value={language}
                      disabled={true}
                    />
                  )}
                  {location && (
                    <CustomInput
                      row={true}
                      label={t("location")}
                      value={location}
                      disabled={true}
                    />
                  )}
                </div>
              </div>
              <div className={`${classes.section} ${classes.marginBottom}`}>
                <h3>{t("history")}</h3>
                {history?.length > 0 ? (
                  <>
                    <div
                      className={`${classes.contentContainer} ${classes.marginLeft}`}
                    >
                      <HistoryList dataSource={history} maxLength={5} />
                    </div>
                    {history?.length > 5 && (
                      <div className={classes.actionContainer}>
                        <Button
                          title={t("view_more_history")}
                          className={classes.btnViewMore}
                          onClick={() => setViewAllHistory(true)}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <p className={classes.noHistory}>{t("no_history")}</p>
                )}
              </div>
              <div className={classes.actionContainer}>
                <Button
                  title={t("unlink_sub")}
                  className={classes.btnUnlink}
                  onClick={() => setIsConfirmationModalOpen(true)}
                />
              </div>
            </div>
            <Line />
            <div className={classes.actionContainer}>
              <Button
                title={t("close")}
                className={classes.btnSave}
                onClick={onClose}
              />
            </div>
          </>
        )}
        {viewAllHistory && (
          <HistoryModal
            isModalOpen={viewAllHistory}
            onClose={() => setViewAllHistory(false)}
            dataSource={history}
          />
        )}
      </Modal>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          text={t("unlink_this_device")}
          description={t("unlink_confirmation_Message")}
          btnConfirmClassName={`${classes.btnConfirm}`}
          btnConfirmTitle={t("unlink")}
          btnCancelTitle={t("cancel")}
          onConfirm={onUnlinkSubscription}
        />
      )}
    </>
  );
}

export default SubscriptionModal;
