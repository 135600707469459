import { useEffect, useRef, useState } from "react";
import { t } from "i18next";

import classes from "./RowCard.module.scss";
import Card from "../../ui/card/Card";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import LanguagesList from "../../languagesList/LanguagesList";
import ContentCard from "../contentCard/ContentCard";
import useElementWidth from "../../../hooks/useElementWidth";

function RowCard({
  row,
  onEdit,
  onOpenDeleteConf,
  onAdd,
  onEditContentRank,
  isModalLoading,
}) {
  const initialTitles = row?.title;
  const content = row?.vods;
  const langs = ["en", "ar"];

  const [titles, setTitles] = useState(initialTitles);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const editNameHandler = () => {
    if (initialTitles?.en !== titles?.en || initialTitles?.ar !== titles?.ar) {
      onEdit({ id: row?.id, order: row?.rowOrder, title: titles });
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing) {
      setTitles(initialTitles);
    }
  }, [isEditing]);

  return (
    <Card className={classes.card}>
      {!isEditing ? (
        <div className={classes.titleContainer}>
          <h3>{initialTitles["en"]}</h3>
          <div className={classes.actionsContainer}>
            <ButtonIcon
              alt="edit"
              className={classes.btn}
              imagePath={"buttons/edit"}
              activeImagePath={"buttons/edit-active"}
              onClick={() => setIsEditing(true)}
            />
            <ButtonIcon
              alt="delete"
              className={classes.btn}
              imagePath={"buttons/delete"}
              activeImagePath={"buttons/delete-active"}
              onClick={onOpenDeleteConf}
            />
            <ButtonIcon
              alt="add"
              className={classes.btn}
              imagePath={"buttons/add"}
              activeImagePath={"buttons/add-active"}
              onClick={onAdd}
            />
          </div>
        </div>
      ) : (
        <div>
          {divWidth && (
            <LanguagesList
              dataSource={langs}
              className={classes.langs}
              selectedLang={selectedLanguage}
              setSelectedLang={setSelectedLanguage}
              width={divWidth}
            />
          )}
          <CustomInput
            value={titles[selectedLanguage] || ""}
            onChange={(e) =>
              setTitles({ ...titles, [selectedLanguage]: e.target.value })
            }
            hasIcon={true}
            imagePath={"icons/confirm"}
            activeImagePath={"icons/confirm-active"}
            onClick={editNameHandler}
            btnClassName={classes.btnConfirm}
            className={classes.customInput}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                editNameHandler();
              }
            }}
          />
        </div>
      )}

      <div className={classes.container} ref={ref}>
        {content?.length > 0 ? (
          <ul className={classes.contentList}>
            {content?.map((vod) => (
              <ContentCard
                key={vod?.id}
                className={classes.itemCard}
                content={vod}
                type={"row"}
                onEditContentRank={(e, rank, vodId) =>
                  onEditContentRank(e, rank, vodId, row?.id)
                }
                isModalLoading={isModalLoading}
              />
            ))}
          </ul>
        ) : (
          <span className={classes.noItem}>{t("noItemsRow")}</span>
        )}
      </div>
    </Card>
  );
}

export default RowCard;
