import { CONTENT_API, VERTICALS_API } from "../ApiUrls";
import ContentHelper from "../../utils/ContentHelper";
import SortHelper from "../../utils/SortHelper";
import loaderCenter from "../LoaderCenter";

let VERTICALS = [], ORIGINAL_VODS = [], ALLVERTICALS = [];


class VerticalManager {
  getVerticalsApi = async (signal) => {
    try {
      const response = await loaderCenter.get(VERTICALS_API.VERTICALS, signal);

      const responseData = await response.json();
      const data = responseData?.data;

      if (data?.length > 0) {
        responseData.data = data.map((v) => {
          let vods = v?.vods;
          return {
            ...v,
            pickerName: ContentHelper.getNameByLang(v),
            vods: SortHelper.arrangeData(vods, true, "rank"),
          };
        });
      }

      VERTICALS = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getVerticals = () => {
    return VERTICALS;
  };

  getAllVerticalsApi = async () => {
    try {
      const response = await loaderCenter.get(VERTICALS_API.GET_ALL_VERTICALS);

      const responseData = await response.json();

      const data = responseData?.data;

      if (data?.length > 0) {
        responseData.data = data.map((v) => {
          return {
            ...v,
            pickerName: ContentHelper.getNameByLang(v)
          };
        });
      }

      ALLVERTICALS = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  }

  getAllVerticals = () => {
    return ALLVERTICALS
  }

  getOneVerticalApi = async (verticalId) => {
    try {
      const response = await loaderCenter.get(VERTICALS_API.GET_ONE_VERTICAL(verticalId));

      const responseData = await response.json();

      // ORIGINAL_VODS = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  }

  createVerticalApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        VERTICALS_API.CREATE_VERTICAL,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateVerticalApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        VERTICALS_API.UPDATE_VERTICAL,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteVerticalApi = async (verticalId) => {
    try {
      const response = await loaderCenter.post(VERTICALS_API.DELETE_VERTICAL, {
        elementId: verticalId,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  attachVodsApi = async (verticalId, vodIds) => {
    try {
      const response = await loaderCenter.post(VERTICALS_API.ATTACH_VODS, {
        verticalId,
        vodIds,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  detachVodsApi = async (verticalId, vodIds) => {
    try {
      const response = await loaderCenter.post(VERTICALS_API.DETACH_VODS, {
        verticalId,
        vodIds,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  syncVodsApi = async (verticalId, vodIds) =>{
    try {
      const response = await loaderCenter.post(VERTICALS_API.SYNC_VODS, {
        verticalId,
        vodIds
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  }

  setDefaultVerticalApi = async (data) => {
    try {
      const response = await loaderCenter.post(VERTICALS_API.SET_DEFAULT, data);

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateRankApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        VERTICALS_API.UPDATE_VOD_RANK,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  getContentsApi = async () => {
    try {
      const response = await loaderCenter.get(CONTENT_API.CONTENTS());

      const responseData = await response.json();
      if (responseData?.data) {
        responseData.data = responseData?.data?.map((vod) => {
          return { ...vod, pickerName: ContentHelper.getNameByLang(vod) };
        });
        ORIGINAL_VODS = responseData.data;
      }
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getOriginalVods = () => {
    return ORIGINAL_VODS;
  };


}

const verticalManager = new VerticalManager();

export default verticalManager;
