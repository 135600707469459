import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./SeriesModal.module.scss";
import ContentModel from "../../../models/ContentModel";
import Modal from "../../ui/modal/Modal";
import ExpandableMenu from "../../expandables/expandableMenu/ExpandableMenu";
import VodSeriesCommunContents from "../../communContents/VodSeriesCommunContents";
import Button from "../../ui/button/Button";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import seriesManager from "../../../backend/managers/SeriesManager";
import Line from "../../ui/line/Line";
// import EpisodeModal from "../episodeModal/EpisodeModal";
import serieManager from "../../../backend/managers/SerieManager";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Cast from "../../cast/Cast";
import SortHelper from "../../../utils/SortHelper";
import Loader from "../../ui/loader/Loader";
import { castActions } from "../../../store/reducers/Cast-slice";

function SeriesModal({ isModalOpen, onClose, isEditing, series, disabled }) {
  const [updatedSerie, setUpdatedSerie] = useState(series);

  const contentModel = new ContentModel(updatedSerie);
  const data = contentModel.data;

  const [titles, setTitles] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const [posterFile, setPosterFile] = useState();
  const [backgroundFile, setBackgroundFile] = useState();
  const [landscapeFile, setLandscapeFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [activeIndex, setActiveIndex] = useState(0);
  const [seasons, setSeasons] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState();
  // type is used to prevent getEpisodes api call when add a new season
  const [type, setType] = useState();
  const [openEpisodeModal, setOpenEpisodeModal] = useState(false);
  const [isEditingEpisode, setIsEditingEpisode] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState();
  const [selectedCatValues, setSelectedCatValues] = useState([]);
  const [rating, setRating] = useState(0);
  const [trailerUrl, setTrailerUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const selectItemsHandler = (selectedItems) => {
    setSelectedCatValues(selectedItems);
  };

  const seasonListRef = useRef();

  const dispatch = useDispatch();

  // const fetchSeasons = async (t) => {
  //   const result = await seriesManager.getSeriesSeasonsApi(data?.id);
  //   if (result.statusCode === 0) {
  //     const resultData = result.data;
  //     const length = resultData?.length;
  //     setSeasons(resultData);
  //     if (t === "add") {
  //       setSelectedSeasonId(resultData[length - 1]?.id);
  //     } else {
  //       length > 0 && setSelectedSeasonId(resultData[0]?.id);
  //     }
  //   }
  // };

  const fetchData = async () => {
    if (isEditing) {
      setIsLoading(true);
      const serie = await serieManager.getSerieById(series?.id);
      setUpdatedSerie(serie);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isEditing && fetchData();
  }, [isEditing]);

  useEffect(() => {
    setRating(isEditing ? data?.rating : 0);
    setSelectedCatValues(isEditing ? data?.genre : []);
    setTrailerUrl(isEditing ? data?.trailer : "");
    setPosterFile();
    setBackgroundFile();
    const objectTitles = {},
      objectDescription = {};
    isEditing &&
      Object.keys(data.title).forEach((lang) => {
        objectTitles[lang] = contentModel.getNameByLang(lang);
        objectDescription[lang] = contentModel.getDescriptionByLang(lang);
      });
    setTitles(isEditing ? objectTitles : { en: "", fr: "", ar: "" });
    setDescriptions(isEditing ? objectDescription : { en: "", fr: "", ar: "" });
    setSelectedLanguage("en");
    if (isEditing) {
      // data && fetchSeasons();
      const seasons = data?.seasons;
      setSeasons(seasons);
      setSelectedSeason(seasons && seasons?.length > 0 ? seasons[0] : null);
    } else {
      setEpisodes([]);
      setSeasons([]);
      setSelectedSeason();
    }
    dispatch(
      castActions.setCastData({
        actors: contentModel.getActors(),
        directors: contentModel.getDirectors(),
        isActorsExpanded: false,
        isDirectorsExpanded: false,
      })
    );
    // eslint-disable-next-line
  }, [isModalOpen, isEditing, updatedSerie]);

  useEffect(() => {
    const sortedEpisodes = SortHelper.arrangeData(
      selectedSeason?.episodes,
      false,
      "number"
    );
    setEpisodes(sortedEpisodes);
    //   const fetchEpisodes = async () => {
    //     const result = await seriesManager.getSeasonEpisodesApi(selectedSeasonId);
    //     if (result.statusCode === 0) {
    //       setEpisodes(result.data);
    //     }
    //   };
    //   selectedSeasonId && type !== "add" && fetchEpisodes();
  }, [selectedSeason, type]);

  const toggleExpandHandler = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const onCloseHandler = () => {
    setActiveIndex(0);
    setSelectedSeason();
    setSeasons([]);
    setEpisodes([]);
    onClose();
  };

  const selectSeasonHandler = (season) => {
    if (type === "add") {
      setType();
    }
    setSelectedSeason(season);
  };

  const addSeasonHandler = async () => {
    setType("add");
    const number = seasons.length + 1;
    const name = contentModel.getNameByLang() + " Season " + number;
    await seriesManager.addSeasonApi(data?.id, number, name);
    // await fetchSeasons("add");
    const seasonList = seasonListRef.current; // Access the season list DOM element
    if (seasonList && seasons.length > 10) {
      const lastSeason = seasonList.lastChild; // Get the last added season element
      lastSeason.scrollIntoView({ behavior: "smooth" }); // Scroll to the last added season
    }
    setEpisodes([]);
  };

  const deleteSeasonHandler = () => {
    selectedSeason && seriesManager.deleteSeasonApi(selectedSeason?.id);
  };

  const deleteEpisodeHandler = (e, episodeId) => {
    e.stopPropagation();
    selectedSeason &&
      episodeId &&
      seriesManager.deleteEpisodeApi(selectedSeason?.id, episodeId);
  };

  const openEpisodeModalHandler = (selectedEpisode) => {
    setOpenEpisodeModal(true);
    setIsEditingEpisode(selectedEpisode ? true : false);
    setSelectedEpisode(selectedEpisode);
  };

  const posterFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPosterFile(URL.createObjectURL(file));
    }
  };

  const landscapeFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setLandscapeFile(URL.createObjectURL(file));
    }
  };

  const previewFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPreviewFile(URL.createObjectURL(file));
    }
  };

  const backgroundFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setBackgroundFile(URL.createObjectURL(file));
    }
  };

  const posterImage = posterFile
    ? posterFile
    : isEditing
    ? contentModel.getPosterImage()
    : require("../../../assets/img/card-content-poster.png");

  const backgroundImage = backgroundFile
    ? backgroundFile
    : isEditing
    ? contentModel.getBackgroundImage()
    : require("../../../assets/img/card-content-background.png");

  const landscapeImage = landscapeFile
    ? landscapeFile
    : isEditing
    ? contentModel.getLandscapeImage()
    : require("../../../assets/img/card-content-background.png");

  const previewImage = previewFile
    ? previewFile
    : isEditing
    ? contentModel.getPreviewImage()
    : require("../../../assets/img/card-content-background.png");

  const categories = serieManager.getCategories();
  const age = data?.age || null;
  const languages = data?.languages || [];
  const subtitles = data?.subtitles?.split(",") || [];
  const format = data?.format;
  const langListDataSource = Object.keys(data?.title) || ["en", "ar"];
  const offers = data?.package;
  const addOns = data?.addOn?.filter((e) => e?.isOneTime === false);
  const verticals = data?.verticals;
  const ppv = data?.addOn?.filter((e) => e?.isOneTime === true);
  const imagesData = {
    posterImage,
    onPosterFileChange: posterFileChangeHandler,
    landscapeImage,
    onLandscapeFileChange: landscapeFileChangeHandler,
    backgroundImage,
    onBackgroundFileChange: backgroundFileChangeHandler,
    previewImage,
    onPreviewFileChange: previewFileChangeHandler,
  };

  return (
    <>
      <Modal
        className={classes.modal}
        isOpen={isModalOpen}
        onClose={onCloseHandler}
        top="1rem"
      >
        <h2>
          {isEditing
            ? t("modify") + " " + contentModel.getNameByLang()
            : t("createNewSeries")}
        </h2>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ExpandableMenu
              title={t("series")}
              isExpanded={activeIndex === 0}
              onToggleExpand={() => toggleExpandHandler(0)}
            >
              <VodSeriesCommunContents
                langListDataSource={langListDataSource}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                titles={titles}
                setTitles={setTitles}
                descriptions={descriptions}
                setDescriptions={setDescriptions}
                imagesData={imagesData}
                rating={rating}
                setRating={setRating}
                initialSelectedItems={selectedCatValues}
                onSelectItems={selectItemsHandler}
                categories={categories}
                age={age}
                languages={languages}
                subtitles={subtitles}
                disabled={disabled}
                format={format}
                trailerUrl={trailerUrl}
                offers={offers}
                addOns={addOns}
                verticals={verticals}
                ppv={ppv}
                contentId={data?.id}
                onRefresh={fetchData}
                expContainerClassName={classes.expContainer}
              ></VodSeriesCommunContents>
            </ExpandableMenu>
            <ExpandableMenu
              title={t("seasons_episodes")}
              isExpanded={activeIndex === 1}
              onToggleExpand={() => toggleExpandHandler(1)}
            >
              <div className={classes.seasonContainer}>
                <div className={classes.headerContainer}>
                  <span>{t("season")}</span>
                  {!disabled && (
                    <div className={classes.actions}>
                      <ButtonIcon
                        imagePath="buttons/delete"
                        activeImagePath="buttons/delete-active"
                        className={classes.btn}
                        onClick={deleteSeasonHandler}
                      />
                      <ButtonIcon
                        imagePath="buttons/add"
                        activeImagePath="buttons/add-active"
                        className={classes.btn}
                        onClick={addSeasonHandler}
                      />
                    </div>
                  )}
                </div>
                <ul
                  className={classes.seasonList}
                  style={{ height: seasons?.length > 10 ? "5.5rem" : "6rem" }}
                  ref={seasonListRef}
                >
                  {seasons?.map((season) => (
                    <Button
                      key={season?.id}
                      className={`${classes.seasonItem} ${
                        selectedSeason?.id === season?.id && classes.selected
                      }`}
                      title={t("season") + " " + season?.number}
                      onClick={() => selectSeasonHandler(season)}
                    />
                  ))}
                </ul>
              </div>
              <div className={classes.episodeContainer}>
                <div
                  className={`${classes.headerContainer} ${classes.marginLeft}`}
                >
                  <span className={!selectedSeason ? classes.disabled : ""}>
                    {t("episodes")}
                  </span>
                  {!disabled && selectedSeason && (
                    <div className={classes.actions}>
                      <ButtonIcon
                        imagePath="buttons/add"
                        activeImagePath="buttons/add-active"
                        className={classes.btn}
                        onClick={() => openEpisodeModalHandler()}
                      />
                    </div>
                  )}
                </div>
                <Line
                  className={`${classes.line} ${
                    !selectedSeason && classes.disabledLine
                  }`}
                />
                <ul className={classes.episodeList}>
                  {episodes?.map((episode) => {
                    const contentModel = new ContentModel(episode);
                    return (
                      <div
                        key={episode?.id}
                        className={classes.episodeItem}
                        onClick={() => openEpisodeModalHandler(episode)}
                      >
                        <img
                          alt="episode"
                          src={contentModel.getPosterImage()}
                        />
                        <div className={classes.episodeNameContainer}>
                          <span>{episode?.number}.</span>
                          <span className={classes.episodeName}>
                            {contentModel.getNameByLang()}
                          </span>
                        </div>
                        {!disabled && (
                          <ButtonIcon
                            className={classes.btnDelete}
                            imagePath="buttons/delete"
                            activeImagePath="buttons/delete-active"
                            onClick={(e) =>
                              deleteEpisodeHandler(e, episode?.id)
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </ul>
              </div>
            </ExpandableMenu>
            <div className={classes.actionContainer}>
              <Button
                className={classes.closeBtn}
                title={t(disabled ? "close" : "btnSave")}
                onClick={disabled ? onCloseHandler : () => {}}
              />
            </div>
          </>
        )}
      </Modal>
      {/* <EpisodeModal
        isModalOpen={openEpisodeModal}
        onClose={() => setOpenEpisodeModal(false)}
        isEditing={isEditingEpisode}
        episode={selectedEpisode}
      /> */}
    </>
  );
}

export default SeriesModal;
