import { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { scaleThreshold } from "d3-scale";
import { geoCentroid } from "d3-geo"; // Import geoCentroid from d3-geo

import classes from "./Map.module.scss";
import LegendItem from "./LegendItem";
import SearchInput from "../ui/inputs/searchInput/SearchInput";

const geoUrl =
  "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_110m_admin_0_countries.geojson";

// Color scale
const colorScale = scaleThreshold()
  .domain([250, 500, 1000])
  .range([
    "var(--primary-50)",
    "var(--primary-100)",
    "var(--primary-400)",
    "var(--primary-800)",
  ]);

const colors = [
  "var(--primary-25)",
  "var(--secondary-light)",
  "var(--light-green)",
  "var(--light-orange)",
  "var(--very-light-green)",
];

function Map({
  verticals = [],
  originalVerticals = [],
  dataSource = [],
  onChangeVerticalsByCountry,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [zoomConfig, setZoomConfig] = useState({
    zoom: 1,
    center: [0, 0],
  });

  const verticalList = selectedCountry ? verticals : originalVerticals;

  // Memoize the list of countries to avoid recalculating on every render
  const countries = useMemo(() => {
    const list = [];
    fetch(geoUrl)
      .then((response) => response.json())
      .then((data) => {
        data.features.forEach((feature) => {
          list.push({
            name: feature.properties.ADMIN,
            iso3: feature.properties.ISO_A3,
            geo: feature,
          });
        });
      });
    return list;
  }, []);

  const handleCountryClick = (geo) => {
    const countryCode = geo.properties.ISO_A3;
    if (countryCode !== "-99") {
      const centroid = geoCentroid(geo); // Calculate the center of the selected country

      setSelectedCountry(countryCode);

      // Set zoom level and center position
      setZoomConfig({
        zoom: 3,
        center: centroid,
      });

      onChangeVerticalsByCountry(countryCode);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchInput) {
        // Find the country that matches the search value
        const country = countries.find((c) =>
          c?.name?.toLowerCase().startsWith(searchInput)
        );
        if (country) {
          const centroid = geoCentroid(country.geo);

          setSelectedCountry(country.iso3); // Set the selected country by ISO3 code
          setZoomConfig({
            zoom: 3,
            center: centroid,
          });
        } else {
          setSelectedCountry();
        }
      } else {
        setZoomConfig({ zoom: 1, center: [0, 0] }); // Reset zoom and center
        setSelectedCountry();
      }
    }, 300); // Adjust the delay

    return () => clearTimeout(timeoutId); // Cleanup the timeout if searchInput changes
  }, [searchInput]);

  return (
    <div>
      <div className={classes.header}>
        <SearchInput
          className={classes.searchInput}
          placeholder={t("search_country")}
          value={searchInput}
          onChangeInput={(value) => setSearchInput(value?.toLowerCase())}
          onClear={() => setSearchInput("")}
        />
        <ul className={classes.verticalList}>
          {verticalList?.map((vertical, index) => (
            <div
              key={vertical?.id}
              className={classes.verticalItem}
              style={{ backgroundColor: colors[index] }}
            >
              {`${vertical?.value} (${vertical?.subscriber_count})`}
            </div>
          ))}
        </ul>
      </div>
      <ComposableMap
        width={1400} // Set width
        height={450} // Set height
        style={{ width: "100%", height: "auto" }} // Responsive styles
      >
        <ZoomableGroup
          zoom={zoomConfig.zoom}
          center={zoomConfig.center}
          onMoveEnd={({ zoom, center }) => setZoomConfig({ zoom, center })}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const countryName = geo.properties.ISO_A3;
                const value = dataSource?.find(
                  (item) => item?.country === countryName
                )?.subscriber_count;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke={
                      selectedCountry === geo.properties.ISO_A3
                        ? "var(--primary-dark)"
                        : "var(--black)"
                    }
                    strokeWidth={
                      selectedCountry === geo.properties.ISO_A3 ? 1 : 0.3
                    }
                    // stroke="#000" // Border color
                    // strokeWidth={0.2} // Border width
                    fill={value ? colorScale(value) : "var(--grey-100)"}
                    style={{
                      default: { outline: "none" },
                      hover: { fill: "var(--primary-dark)", outline: "none" },
                      pressed: { outline: "none" },
                    }}
                    onClick={() => handleCountryClick(geo)}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div className={classes.legendContainer}>
        <LegendItem
          text={t("legend_customers", { from: 0, to: 250 })}
          color={"var(--primary-50)"}
        />
        <LegendItem
          text={t("legend_customers", { from: 251, to: 500 })}
          color={"var(--primary-100)"}
        />
        <LegendItem
          text={t("legend_customers", { from: 501, to: 1000 })}
          color={"var(--primary-400)"}
        />
        <LegendItem text={t("more_than_1000")} color={"var(--primary-800)"} />
      </div>
    </div>
  );
}

export default Map;
