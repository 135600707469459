import { useState, useRef } from "react";
import { t } from "i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import classes from "./PreferredContent.module.scss";
import Loader from "../../ui/loader/Loader";
import Card from "../../ui/card/Card";
import Line from "../../ui/line/Line";
import DraggableItem from "../../items/draggableItem/DraggableItem";
import ConfirmationModal from "../../modals/confirmationModal/ConfirmationModal";
import NoItemsView from "../../ui/noItemsView/NoItemsView";
import ContentHelper from "../../../utils/ContentHelper";
import { select } from "react-i18next/icu.macro";

function PreferredContent({
  isCardLoading,
  dataSource,
  setPreferredContentList,
  setIsPreferredContentModalOpen,
  searchInputValue,
  handleDeleteContent,
  handleEditContent,
  handleReorderContents,
}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const ref = useRef();

  const handleDeleteItem = (e, item) => {
    e.stopPropagation();
    setSelectedContent(item);
    setIsConfirmationModalOpen(true);
  };

  const onDeleteHandler = () => {
    handleDeleteContent(selectedContent.elementId);
    setIsConfirmationModalOpen(false);
  };

  const handleEditItem = (item) => {
    handleEditContent(item);
  };

  const onDragUpdate = (update) => {
    if (!update.destination) return;
    setDraggingIndex(update.source.index);
    setHoverIndex(update.destination.index);
  };

  const onDragEnd = (result) => {
    setDraggingIndex(null);  // Reset dragging index
    setHoverIndex(null); // Reset hover index

    // Implement drag-and-drop logic here
    const { source, destination, type } = result;
    if (!destination) return;
    const reorderedContents = Array.from(dataSource);
    const [movedContent] = reorderedContents.splice(source.index, 1);
    reorderedContents.splice(destination.index, 0, movedContent);

    // Update order based on new position
    const updatedContents = reorderedContents.map((content, index) => ({
      ...content,
      rank: index + 1,
    }));
    // Create the reorder array with the correct format
    const reorderList = reorderedContents.map((content, index) => ({
      elementId: content.elementId,
      rank: (index + 1).toString(),
    }));

    setPreferredContentList(updatedContents);
    handleReorderContents(reorderList);
  };

  return (
    <div ref={ref}>
      {isCardLoading ? (
        <div className={classes.loaderContainer}>
          <Loader className={classes.loader} positionNotFixed={true} />
        </div>
      ) : (
        <>
          <Card className={classes.card}>
            {dataSource && dataSource.length ? (
              <>
                <p className={classes.cardTitle}>{t("drag_n_drop_content")}</p>
                <Line />
                <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classes.droppableContainer}
                      >
                        <div className={classes.itemsContainer}>
                          {dataSource.map((item, index) => (
                            <Draggable
                              key={item.elementId}
                              draggableId={item.elementId}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={classes.draggableItemContainer}
                                >
                                  <DraggableItem
                                    data={item}
                                    index={index}
                                    draggingIndex={draggingIndex}
                                    hoverIndex={hoverIndex} 
                                    isDragging={snapshot.isDragging}
                                    iconsList={[
                                      {
                                        id: "edit",
                                        imagePath:
                                          "buttons/edit-purple-background",
                                        onClick: (e) => handleEditItem(item),
                                      },
                                      {
                                        id: "delete",
                                        imagePath:
                                          "buttons/delete-purple-background",
                                        onClick: (e) =>
                                          handleDeleteItem(e, item),
                                      },
                                    ]}
                                    title={ContentHelper.getNameByLang(item)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            ) : (
              <NoItemsView
                text={
                  searchInputValue && searchInputValue !== ""
                    ? t("noItemsMatchSearch")
                    : t("noItemsInThisSection")
                }
                showCreateText={true}
                className={classes.noItemsView}
                onClick={() => setIsPreferredContentModalOpen(true)}
              />
            )}
          </Card>
          {isConfirmationModalOpen && (
            <ConfirmationModal
              isModalOpen={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              className={classes.confModal}
              text={t("delete_content_type")}
              description={t("delete_preferred_content_description", {
                content_title: selectedContent.title,
              })}
              btnConfirmClassName={classes.btnConfirm}
              btnConfirmTitle={t("delete")}
              btnCancelTitle={t("cancel")}
              onConfirm={onDeleteHandler}
            >
              <p>{t("item_removal_frontend_impact")}</p>
            </ConfirmationModal>
          )}
        </>
      )}
    </div>
  );
}

export default PreferredContent;
