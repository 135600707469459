import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./AccountModal.module.scss";
import styles from "../../../styles/Button.module.scss";
import Modal from "../../ui/modal/Modal";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import InputSign from "../../ui/inputs/inputSign/InputSign";
import Button from "../../ui/button/Button";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import accountManager from "../../../backend/managers/AccountManager";
import Loader from "../../ui/loader/Loader";
import Permissions from "./Permissions";
import {
  validateEmail,
  validateNewAndConfirmedPassword,
} from "../../../utils/Validators";
import EditSection from "../myAccountModal/EditSection";

const initialPermissions = {
  devices: {
    edit: false,
    delete: false,
  },
  messages: {
    edit: false,
    delete: false,
    types: [],
  },
  homeSettings: {
    edit: false,
    delete: false,
  },
  menu: {
    edit: false,
    delete: false,
  },
  customPages: {
    edit: false,
    delete: false,
  },
  profile: {
    edit: false,
    delete: true,
  },
};

const initialPasswordCredentials = {
  password: "",
  newPassword: "",
  confirmPassword: "",
};

function AccountModal({
  isModalOpen,
  onClose,
  isEditing,
  accountData,
  onResetPassword,
  onDeleteAccount,
  isOwnAccount,
  onRefreshAccounts,
  isModalLoading,
}) {
  const [updatedAccount, setUpdatedAccount] = useState(accountData);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [permissions, setPermissions] = useState(updatedAccount?.permissions);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [passwordCredentials, setPasswordCredentials] = useState(
    initialPasswordCredentials
  );
  const [confModalContent, setConfModalContent] = useState({
    text: "",
    description: "",
    btnConfirmTitle: "Yes",
    btnCancelTitle: "No",
    btnConfirmClassName: "",
    onConfirm: () => {},
  });

  const initialAccountValues = {
    name: accountData?.name,
    email: accountData?.email,
  };

  const showEditPasswordHandler = (e) => {
    e.preventDefault();
    setShowEditPassword((prevState) => !prevState);
  };

  const changePasswordInputHandler = (field, value) => {
    setPasswordCredentials((prevState) => {
      return { ...prevState, [field]: value };
    });
    setError(null);
  };

  const validatePasswordFormHandler = (credentials) => {
    const { password, newPassword, confirmPassword } = credentials;
    if (password && newPassword && confirmPassword)
      return validateNewAndConfirmedPassword(newPassword, confirmPassword);
    return t("error_inputs_required");
  };

  const callPasswordApi = async () => {
    const error = validatePasswordFormHandler(passwordCredentials);
    setError(error);
    if (error === null) {
      setIsLoading(true);
      const result = await accountManager.updateUserProfilePasswordApi({
        currentCredential: passwordCredentials.password,
        newCredential: passwordCredentials.newPassword,
        confirmCredential: passwordCredentials.confirmPassword,
      });
      if (result.status !== 0) {
        setError(result?.message);
        setIsLoading(false);
        return result;
      }
      setIsLoading(false);
      setShowEditPassword(false);
      setPasswordCredentials(initialPasswordCredentials);
    } else {
      return error;
    }
  };

  const openResetConfModalHandler = (e) => {
    e.preventDefault();
    setConfModalContent({
      text: t("resetPassword"),
      description: t("reset_password_description", {
        name: name,
      }),
      btnCancelTitle: t("no_cancel"),
      btnConfirmTitle: t("yes_reset"),
      btnConfirmClassName: classes.btnConfirm,
      onConfirm: onResetPassword,
    });
    setIsConfirmationModalOpen(true);
  };

  const openDeleteConfModalHandler = (e) => {
    e.preventDefault();
    setConfModalContent({
      text: t("delete_account"),
      description: t("conf_delete_account", { name: name }),
      btnCancelTitle: t("cancel"),
      btnConfirmTitle: t("yes"),
      onConfirm: onDeleteAccount,
    });
    setIsConfirmationModalOpen(true);
  };

  const closeModalhandler = () => {
    setShowEditPassword(false);
    onClose();
  };

  const callApiHanler = async (credentials, api) => {
    setIsLoading(true);
    const result = await api(credentials);
    if (result.status === 0) {
      onClose();
      onRefreshAccounts();
    } else {
      setError(result?.message);
    }
    setIsLoading(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      setError(t("error_name_required"));
      return;
    }
    const validEmail = validateEmail(email);
    if (validEmail !== null) {
      setError(validEmail);
      return;
    }
    if (isEditing) {
      const credentials = {
        id: accountData?.id,
        name: name,
        email: email,
        permissions: permissions,
      };
      if (isOwnAccount) {
        const error =
          passwordCredentials.password.length > 0 && (await callPasswordApi());
        !error &&
          (initialAccountValues.name !== credentials.name ||
            initialAccountValues.email !== credentials.email) &&
          callApiHanler(credentials, accountManager.updateAccountApi);
      } else {
        if (
          name !== accountData?.name ||
          email !== accountData?.email ||
          JSON.stringify(updatedAccount?.permissions) !==
            JSON.stringify(permissions)
        ) {
          callApiHanler(credentials, accountManager.updateAccountApi);
        }
      }
    } else {
      const validPass = validateNewAndConfirmedPassword(
        password,
        confirmPassword
      );
      if (validPass !== null) {
        setError(validPass);
        return;
      }
      const credentials = {
        name: name,
        email: email,
        password: password,
        passwordConfirmation: confirmPassword,
        permissions: permissions,
      };
      callApiHanler(credentials, accountManager.createAccountApi);
    }
  };

  useEffect(() => {
    if (isEditing) {
      const fetchData = async () => {
        setIsLoading(true);
        const result = await accountManager.getAccountByIdApi(accountData?.id);
        if (result?.status === 0) {
          const data = result?.data;
          setUpdatedAccount(data);
          setName(data?.name);
          setEmail(data?.email);
          setPassword("********");
          setPermissions(data?.permissions);
        } else {
          setError(result?.message);
        }
        setIsLoading(false);
      };
      fetchData();
    } else {
      setPermissions(initialPermissions);
    }
    // eslint-disable-next-line
  }, [isEditing, isModalOpen]);

  return (
    <Modal
      className={classes.modal}
      isOpen={isModalOpen}
      onClose={closeModalhandler}
      top="1rem"
    >
      <h2>{isEditing ? t("modifyAccount") : t("create_back_office_user")}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={submitHandler}>
          <div className={classes.accountSection}>
            <div className={classes.inputsContainer}>
              <CustomInput
                className={classes.input}
                label={t("name")}
                placeholder={t("name")}
                row={true}
                value={name}
                onChange={(e) => {
                  setError();
                  setName(e.target.value);
                }}
              />
              <CustomInput
                className={classes.input}
                label={t("email")}
                placeholder={t("email")}
                type={"email"}
                row={true}
                value={email}
                onChange={(e) => {
                  setError();
                  setEmail(e.target.value);
                }}
              />
              <div className={classes.rowPass}>
                <InputSign
                  className={`${
                    !isEditing ? classes.input : classes.inputPass
                  } ${isOwnAccount && classes.inputWidth}`}
                  type="password"
                  label={t("password")}
                  placeholder={t("password")}
                  row={true}
                  value={password}
                  disabled={isEditing}
                  onChange={(id, v) => {
                    setError();
                    setPassword(v);
                  }}
                  isValid={true}
                  span={t("spanPasswordConstraints")}
                />
                {isOwnAccount ? (
                  <Button
                    title={t("btnEdit")}
                    className={`${classes.btnEdit} ${styles.button}`}
                    onClick={showEditPasswordHandler}
                  />
                ) : (
                  <ButtonIcon
                    imagePath={"buttons/refresh"}
                    activeImagePath={"buttons/refresh-active"}
                    disabledImagePath={"buttons/refresh-disabled"}
                    className={classes.btnReset}
                    isDisabled={isEditing ? false : true}
                    onClick={openResetConfModalHandler}
                  />
                )}
              </div>
              {!isEditing && (
                <InputSign
                  className={`${classes.marginBottom} ${classes.input}`}
                  type="password"
                  label={t("confirmPassword")}
                  placeholder={t("confirmPassword")}
                  row={true}
                  value={confirmPassword}
                  onChange={(id, v) => {
                    setError();
                    setConfirmPassword(v);
                  }}
                  isValid={true}
                />
              )}
            </div>
            {isOwnAccount && showEditPassword && (
              <>
                <EditSection
                  className={classes.editSection}
                  title={t("editMyPassword")}
                  type="password"
                  inputs={{
                    current: {
                      id: "password",
                      label: t("labelCurrentPassword"),
                      value: passwordCredentials.password,
                      isValid: true,
                      span: t("forgotPassword") + "?",
                    },
                    new: {
                      id: "newPassword",
                      label: t("labelNewPassword"),
                      value: passwordCredentials.newPassword,
                      isValid: true,
                    },
                    confirm: {
                      id: "confirmPassword",
                      label: t("labelConfirmPassword"),
                      value: passwordCredentials.confirmPassword,
                      isValid: true,
                    },
                  }}
                  onInputChange={changePasswordInputHandler}
                />
                {error && (
                  <p className={`${classes.error} ${classes.center}`}>
                    {error}
                  </p>
                )}
              </>
            )}
          </div>
          {error && !isOwnAccount && <p className={classes.error}>{error}</p>}
          {!isOwnAccount && (
            <div className={classes.permissionsSection}>
              <h2>{t("permissions")}</h2>
              <Permissions
                initialValues={updatedAccount?.permissions}
                permissions={permissions}
                setPermissions={setPermissions}
              />
            </div>
          )}
          <div className={classes.btnActions}>
            {!isOwnAccount && isEditing && (
              <Button
                title={t("delete_this_account")}
                className={classes.btnDelete}
                onClick={openDeleteConfModalHandler}
              />
            )}
            <Button type="submit" title={t("btnSave")} />
          </div>
        </form>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          className={classes.confModal}
          text={confModalContent.text}
          description={confModalContent.description}
          btnConfirmClassName={`${confModalContent.btnConfirmClassName} ${classes.btn}`}
          btnCancelClassName={classes.btn}
          btnConfirmTitle={confModalContent.btnConfirmTitle}
          btnCancelTitle={confModalContent.btnCancelTitle}
          onConfirm={confModalContent.onConfirm}
          isLoading={isModalLoading}
        />
      )}
    </Modal>
  );
}

export default AccountModal;
