import { t } from "i18next";

import ButtonIcon from "../../buttonIcon/ButtonIcon";
import classes from "./SearchInput.module.scss";

function SearchInput({
  className,
  placeholder,
  value,
  onChangeInput = () => {},
  onClear,
}) {
  return (
    <div className={`${classes.searchInput} ${className}`}>
      <img
        alt="search"
        src={require("../../../../assets/img/icons/search.png")}
      />
      <input
        placeholder={placeholder ? placeholder : t("search")}
        value={value}
        onChange={(e) => onChangeInput(e.target.value)}
      />
      {value && (
        <ButtonIcon
          className={classes.btn}
          imagePath="icons/close"
          onClick={onClear}
        />
      )}
    </div>
  );
}

export default SearchInput;
