import { t } from "i18next";

class ContentHelper {
  static getNameByLang(data, lang) {
    const selectedLang = lang ? lang : "en";
    const title = data?.title || data?.name || data?.values;
    if (title) {
      const foundedLang = title[selectedLang];
      if (foundedLang !== null || foundedLang !== undefined) {
        return foundedLang;
      } else {
        const firstLang = title[Object.keys(title)[0]];
        return firstLang;
      }
    }
  }

  // static getNameByLang(data, lang) {
  //   const selectedLang = lang ? lang : "en";
  //   const translation = data?.translation;
  //   if (translation) {
  //     const foundedLang = translation[selectedLang];
  //     if (foundedLang) {
  //       return foundedLang.name;
  //     } else {
  //       const firstLang = translation[Object.keys(translation)[0]];
  //       return firstLang.name;
  //     }
  //   }
  // }

  static getPickerCellName(pickerCellData, selectedLanguage) {
    if (pickerCellData.pickerName) return pickerCellData.pickerName;
    if (pickerCellData.name)
      return pickerCellData.name?.en
        ? this.getNameByLang(pickerCellData, selectedLanguage)
        : pickerCellData.name;
    if (pickerCellData.subscriberName) return pickerCellData.subscriberName;
    if (pickerCellData.customerName) return pickerCellData.customerName;
    if (pickerCellData.translation) return this.getNameByLang(pickerCellData);
    if (pickerCellData.sku_name || pickerCellData.skuName)
      return pickerCellData.sku_name || pickerCellData.skuName;
    if (pickerCellData.id) return pickerCellData.id;
    return t(pickerCellData);
  }
}

export default ContentHelper;
