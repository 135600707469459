import { useState, useEffect, useRef } from "react";
import { t } from "i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import classes from "./FAQCard.module.scss";
import Card from "../../ui/card/Card";
import LanguagesList from "../../languagesList/LanguagesList";
import ExpandableMenu from "../../expandables/expandableMenu/ExpandableMenu";
import NoItemsView from "../../ui/noItemsView/NoItemsView";
import ConfirmationModal from "../../modals/confirmationModal/ConfirmationModal";
import Loader from "../../ui/loader/Loader";
import SubsectionDetailsCard from "../../subsectionDetailsCard/SubsectionDetailsCard";
import useElementWidth from "../../../hooks/useElementWidth";

const ACTION_TYPE = {
  SECTION: "section",
  SUBSECTION: "subsection",
};

function FAQCard({
  data,
  reorderFAQSections,
  reorderFAQSubSections,
  deleteFAQSection,
  deleteFAQSubSection,
  isCardLoading,
  onEditFAQ,
  onAddFAQSubSection,
}) {
  const [selectedLang, setSelectedLang] = useState("en");
  const [sectionsList, setSectionsList] = useState(data);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedSubSection, setSelectedSubSection] = useState({});

  const [actionType, setActionType] = useState("");

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const removeSectionHandler = (e, section) => {
    e.stopPropagation();
    setSelectedSection(section);
    setActionType(ACTION_TYPE.SECTION);
    setIsConfirmationModalOpen(true);
  };

  const removeSubSectionHandler = (e, subsection, sectionId) => {
    e.stopPropagation();
    setSelectedSubSection({ ...subsection, sectionId });
    setActionType(ACTION_TYPE.SUBSECTION);
    setIsConfirmationModalOpen(true);
  };

  const onDeleteHandler = () => {
    if (actionType === ACTION_TYPE.SECTION) {
      let filteredSectionList = sectionsList.filter(
        (section) => section.id !== selectedSection.id
      );
      setSectionsList(filteredSectionList);
      deleteFAQSection(selectedSection.id);
    } else if (actionType === ACTION_TYPE.SUBSECTION) {
      // Directly update the section containing the selected subsection
      setSectionsList((prevSections) =>
        prevSections.map((section) =>
          section.id === selectedSubSection.sectionId
            ? {
                ...section,
                subSections: section.subSections.filter(
                  (sub) => sub.id !== selectedSubSection.id
                ),
              }
            : section
        )
      );
      deleteFAQSubSection(selectedSubSection.id);
    }
    setIsConfirmationModalOpen(false);
  };

  const editSectionHandler = (e, section) => {
    e.stopPropagation();
    onEditFAQ(section);
  };

  const editSubSectionHandler = (e, section, index) => {
    e.stopPropagation();
    onEditFAQ(section, index);
  };

  const addSectionHandler = (e, section) => {
    e.stopPropagation();
    onAddFAQSubSection(section);
  };

  const handleDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (type === "SECTION") {
      // Check if the section's order did not change; if so, do not send the request or update the state
      if (source.index === destination.index) return;

      const reorderedSections = Array.from(sectionsList);
      const [movedSection] = reorderedSections.splice(source.index, 1);
      reorderedSections.splice(destination.index, 0, movedSection);

      setSectionsList(reorderedSections);
      let orderedSections = reorderedSections.map((section, index) => ({
        id: section.id,
        order: index + 1,
      }));
      reorderFAQSections({ reorder: orderedSections });
    } else if (type === "SUBSECTION") {
      const sectionId = parseInt(source.droppableId);
      const sectionIndex = sectionsList.findIndex((sec) => sec.id == sectionId);
      const section = sectionsList[sectionIndex];

      if (source.index === destination.index) return; // Check if the subsection's order did not change; if so, do not send the request or update the state

      const reorderedSubsections = Array.from(section.subSections);
      const [movedSubsection] = reorderedSubsections.splice(source.index, 1);
      reorderedSubsections.splice(destination.index, 0, movedSubsection);

      const updatedSection = {
        ...section,
        subSections: reorderedSubsections,
      };

      const updatedSectionsList = [...sectionsList];
      updatedSectionsList[sectionIndex] = updatedSection;
      setSectionsList(updatedSectionsList);
      reorderFAQSubSections({
        sectionId,
        reorder: reorderedSubsections.map((sub, index) => ({
          id: sub.id,
          order: index + 1,
        })),
      });
    }
  };

  // Utility function to sort sections by sectionOrder
  const sortSections = (sections) => {
    return sections.sort((a, b) => a.sectionOrder - b.sectionOrder);
  };

  // Utility function to sort subsections within each section by subSectionOrder
  const sortSubsections = (sections) => {
    return sections.map((section) => {
      if (section.subSections && section.subSections.length > 0) {
        return {
          ...section,
          subSections: section.subSections.sort(
            (a, b) => a.subSectionOrder - b.subSectionOrder
          ),
        };
      }
      return section;
    });
  };

  useEffect(() => {
    if (data) {
      // Sort sections and subsections using utility functions
      const sortedSections = sortSections(data);
      const sortedSectionsWithSubsections = sortSubsections(sortedSections);

      setSectionsList(sortedSectionsWithSubsections);
    }
  }, [data]);

  return (
    <div ref={ref}>
      {isCardLoading ? (
        <div className={classes.loaderContainer}>
          <Loader className={classes.loader} positionNotFixed={true} />
        </div>
      ) : (
        <>
          <div>
            <Card className={classes.card}>
              {divWidth && (
                <LanguagesList
                  selectedLang={selectedLang}
                  setSelectedLang={setSelectedLang}
                  width={divWidth}
                  className={classes.langList}
                />
              )}
              {sectionsList && sectionsList.length > 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="sections" type="SECTION">
                    {(provided) => (
                      <div
                        className={classes.sectionList}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {sectionsList &&
                          sectionsList.map((section, index) => (
                            <Draggable
                              key={section.id}
                              draggableId={section.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={classes.sectionItem}
                                >
                                  <ExpandableMenu
                                    key={section.id}
                                    id={section.id}
                                    index={index}
                                    expandableClassName={classes.expandableMenu}
                                    title={section.title[selectedLang]}
                                    isDraggable={true}
                                    provided={provided}
                                    iconsList={[
                                      {
                                        id: "delete",
                                        imagePath: "icons/delete-purple-circle",
                                        activeImagePath:
                                          "icons/delete-purple-fill-circle",
                                        onClick: (e) =>
                                          removeSectionHandler(e, section),
                                      },
                                      {
                                        id: "edit",
                                        imagePath: "buttons/edit",
                                        activeImagePath: "buttons/edit-active",
                                        onClick: (e) =>
                                          editSectionHandler(e, section),
                                      },
                                      {
                                        id: "add",
                                        imagePath: "buttons/add",
                                        activeImagePath: "buttons/add-active",
                                        onClick: (e) =>
                                          addSectionHandler(e, section),
                                      },
                                    ]}
                                  >
                                    <Droppable
                                      droppableId={section.id.toString()}
                                      type="SUBSECTION"
                                    >
                                      {(provided) => (
                                        <ul
                                          className={classes.subsectionsList}
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {section.subSections &&
                                            section.subSections.map(
                                              (subsection, index) => (
                                                <Draggable
                                                  key={subsection.id}
                                                  draggableId={subsection.id.toString()}
                                                  index={index}
                                                >
                                                  {(provided) => (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      className={
                                                        classes.subsectionItem
                                                      }
                                                    >
                                                      <SubsectionDetailsCard
                                                        ref={provided.innerRef}
                                                        selectedLang={
                                                          selectedLang
                                                        }
                                                        subsection={subsection}
                                                        isDraggable={true}
                                                        provided={provided}
                                                        iconsList={[
                                                          {
                                                            id: "delete",
                                                            imagePath:
                                                              "icons/delete-purple-circle",
                                                            activeImagePath:
                                                              "icons/delete-purple-fill-circle",
                                                            onClick: (e) =>
                                                              removeSubSectionHandler(
                                                                e,
                                                                subsection,
                                                                section.id
                                                              ),
                                                          },
                                                          {
                                                            id: "edit",
                                                            imagePath:
                                                              "buttons/edit",
                                                            activeImagePath:
                                                              "buttons/edit-active",
                                                            onClick: (e) =>
                                                              editSubSectionHandler(
                                                                e,
                                                                section,
                                                                index
                                                              ),
                                                          },
                                                        ]}
                                                      />
                                                    </div>
                                                  )}
                                                </Draggable>
                                              )
                                            )}
                                          {provided.placeholder}
                                        </ul>
                                      )}
                                    </Droppable>
                                  </ExpandableMenu>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <NoItemsView
                  message={t("no_sections")}
                  className={classes.noItemsView}
                />
              )}
            </Card>
          </div>
          {isConfirmationModalOpen && (
            <ConfirmationModal
              isModalOpen={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              className={classes.confModal}
              text={t("delete_package")}
              description={
                actionType === ACTION_TYPE.SECTION
                  ? t("delete_FAQ_section_description", {
                      section_title: selectedSection.title["en"],
                    })
                  : t("delete_FAQ_subsection_description", {
                      subsection_title: selectedSubSection.title["en"],
                    })
              }
              impactDescription={t("item_removal_frontend_impact")}
              btnConfirmClassName={classes.btnConfirm}
              btnConfirmTitle={t("delete")}
              btnCancelTitle={t("cancel")}
              onConfirm={onDeleteHandler}
            >
              <p>{t("item_removal_frontend_impact")}</p>
            </ConfirmationModal>
          )}
        </>
      )}
    </div>
  );
}

export default FAQCard;
