import React, { useEffect, useRef, useState } from "react";

import classes from "./PageHeader.module.scss";
import ButtonIcon from "../ui/buttonIcon/ButtonIcon";
import Line from "../ui/line/Line";
import ExpandableInput from "../expandables/expandableInput/ExpandableInput";
import Button from "../ui/button/Button";
import { t } from "i18next";

/**
 * PageHeader Component
 * @param {Object} props - React props containing the following:
 * @param {string} pageTitle - Title to be displayed in the page header.
 * @param {Object[]} pageHeaderButtons - Array of objects representing buttons in the header.
 * @param {number} dropMenuWidth - Width of the dropdown menu.
 * @param {boolean} showExpandableInput - Flag to show the expandable input field.
 * @param {Function} onCloseExpandableInput - Function to handle closing of the expandable input.
 * @param {Function} onOpenExpandableInput - Function to handle opening of the expandable input.
 * @param {Function} onSearch - Function to handle the search action.
 * @param {boolean} isLoading - Flag indicating loading state.
 * @returns {JSX.Element} - Rendered React component.
 */
function PageHeader({
  pageTitle,
  pageHeaderButtons,
  dropMenuWidth,
  showExpandableInput,
  onCloseExpandableInput = () => {},
  onOpenExpandableInput,
  onSearch,
  isLoading,
  children,
  className,
}) {
  const [buttonId, setButtonId] = useState();
  const [expandableInputContent, setExpandableInputContent] = useState();
  const [showDropMenu, setShowDropMenu] = useState(false);
  const [activeAction, setActiveAction] = useState(() => {});

  const buttonRef = useRef();

  // Function to handle change in content of expandable input field
  const changeExpandableInputContentHandler = (e) => {
    setExpandableInputContent((prevState) => {
      return { ...prevState, value: e.target.value };
    });
  };

  // Function to handle button click actions
  const clickHandler = (e, buttonId, isExpandable, action) => {
    e.stopPropagation();
    if (buttonId === "add") {
      onCloseExpandableInput();
      setActiveAction(() => action);
      setShowDropMenu(true);
      return;
    }
    if (isExpandable) {
      onOpenExpandableInput();
      setButtonId(buttonId);
      const inputContent = action();
      setExpandableInputContent(inputContent);
      return;
    }
    action();
  };

  // Effect to handle actions when expandable input content or buttonId changes
  useEffect(() => {
    if (buttonId === "search") {
      onSearch(expandableInputContent?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandableInputContent, buttonId]);

  // Effect to position the dropdown menu based on the button's position
  useEffect(() => {
    if (showDropMenu) {
      const rect = buttonRef.current?.getBoundingClientRect();
      const bottom = rect?.bottom + 10;
      const left = rect?.right - dropMenuWidth;
      if (activeAction) {
        activeAction({ top: bottom, left });
      }
    }
    setShowDropMenu(false);
  }, [showDropMenu, activeAction, dropMenuWidth]);

  return (
    <header className={`${classes.header} ${className}`}>
      {children && pageTitle === "" ? children : <h2>{pageTitle}</h2>}
      <div className={classes.actions}>
        {pageHeaderButtons?.map((button) => (
          <React.Fragment key={button.id}>
            {/* Render ExpandableInput if applicable, else render ButtonIcon */}
            {showExpandableInput && button.id === buttonId ? (
              <ExpandableInput
                hasConfirmButton={button?.hasConfirmButton}
                input={expandableInputContent}
                onChangeInput={changeExpandableInputContentHandler}
                onClose={onCloseExpandableInput}
                className={classes.expInput}
              />
            ) : (
              // Render ButtonIcon if not hidden
              !button.hidden &&
              (button.id !== "add" ? (
                <>
                  {button.hasLeftBorder && <Line className={classes.line} />}
                  <ButtonIcon
                    imagePath={`buttons/${button.image}`}
                    activeImagePath={`buttons/${button.image}-active`}
                    className={`${classes.btn} ${
                      button.id === "refresh" &&
                      isLoading &&
                      classes.rotateImage
                    }`}
                    onClick={(e) =>
                      clickHandler(
                        e,
                        button.id,
                        button.expandable,
                        button.action
                      )
                    }
                  />
                </>
              ) : (
                <Button
                  className={classes.add}
                  title={"+ " + t("add")}
                  onClick={(e) =>
                    clickHandler(e, button.id, button.expandable, button.action)
                  }
                  ref={buttonRef}
                />
              ))
            )}
          </React.Fragment>
        ))}
      </div>
    </header>
  );
}

export default PageHeader;
