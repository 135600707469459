
import classes from "./FeedHistoryCard.module.scss";
import Card from "../../ui/card/Card";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";

import DateHelper from "../../../utils/DateHelper"
import { useState } from "react";



export default function FeedHistoryCard({ feedHistoryList }) {
    return (
        <Card className={classes.feedHistory}>
            <h3>Feed History</h3>
            <div className={classes.feedContainer}>
                {
                    feedHistoryList.map((feed, index) =>
                        <HistoryPill feed={feed} />
                    )
                }
            </div>
        </Card>
    )
}


function HistoryPill({ feed }) {
    const [isOpen, setIsOpen] = useState(false)
    const allAddedContent = [feed?.newTvChannels, feed?.newMovies, feed?.newSeries, feed?.newEpisodes].every((ele) => ele === 0)

    return (
        <div className={classes.eachContainerHistory} >
            <div className={classes.feedTitle}>
                <p>{feed.triggeredBy}</p>
                <p className={classes.datehistory}>{DateHelper.formatDate(feed?.dateTime, 'DD/MM/YYYY HH:mm')}</p>
                <ButtonIcon imagePath={'sidebar/arrow-bottom-light'} className={isOpen ? classes.arrowOpen : classes.arrowClose} onClick={() => setIsOpen(!isOpen)} />
            </div>
            {isOpen &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {!allAddedContent && <div className={classes.tagContainers}>
                        <span>New contents :</span>
                        {feed?.newTvChannels !== 0 && <p> Channels+{' ' + feed.newTvChannels}</p>}
                        {feed?.newMovies !== 0 && <p> Movies +{' ' + feed.newMovies}</p>}
                        {feed?.newSeries !== 0 && <p> Series +{' ' + feed.newSeries}</p>}
                        {feed?.newEpisodes !== 0 && <p> Episodes +{' ' + feed.newEpisodes}</p>}
                    </div>}
                    {(feed?.removedSeries !== 0 && feed?.removedTvChannels !== 0) && <div className={classes.tagContainersRemove}>
                        {<span  > Removed contents :</span>}
                        {feed?.removedSeries !== 0 && <p> Channels+{' ' + feed.removedSeries}</p>}
                        {feed?.removedTvChannels !== 0 && <p> Movies +{' ' + feed.removedTvChannels}</p>}
                    </div>}
                </div>}

        </div >
    )
}