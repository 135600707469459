import { t } from "i18next";

import classes from "./SearchSortView.module.scss";
import ExpandableSelectButton from "../../../expandables/expandableSelectButton/ExpandableSelectButton";
import SearchInput from "../../inputs/searchInput/SearchInput";
import PickerView from "../../../pickerViews/pickerView/PickerView";

function SearchSortView({
  hasSort,
  options,
  selectedSortType,
  onSelectSortType,
  searchInputValue,
  searchInputPlaceholder,
  onChangeSearchInput = () => {},
  onClearSearchIput,
  className,
  expButtonClassName,
  searchInputClassName,
  pickerViewImageName = "radio-checked",
}) {
  const selectSortTypeHandler = (items) => {
    onSelectSortType(items[0]);
  };
  return (
    <div className={`${classes.container} ${className}`}>
      <SearchInput
        className={`${classes.searchInput} ${searchInputClassName}`}
        placeholder={searchInputPlaceholder}
        value={searchInputValue}
        onChangeInput={onChangeSearchInput}
        onClear={onClearSearchIput}
      />
      {hasSort && (
        <ExpandableSelectButton
          className={`${classes.expBtn} ${expButtonClassName}`}
          expCardClassName={classes.expCard}
          title={t(selectedSortType?.id)}
        >
          <PickerView
            dataSource={options}
            pickerCellHeight={32}
            pickerCellImageHeight={18}
            pickerViewClassName={classes.pickerView}
            onSelectItems={selectSortTypeHandler}
            initialSelectedItems={[selectedSortType ? selectedSortType : ""]}
            imageName={pickerViewImageName}
            preventUnselection={true}
          />
        </ExpandableSelectButton>
      )}
    </div>
  );
}

export default SearchSortView;
