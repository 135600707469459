import classes from "./Circle.module.scss";

function Circle({ className, color }) {
  return (
    <div
      className={`${classes.circle} ${className}`}
      style={{ backgroundColor: color }}
    />
  );
}

export default Circle;
