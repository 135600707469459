import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { t } from "i18next";
import Cookies from "js-cookie";

import SigninLayout from "../../components/signinLayout/SigninLayout";
import authManager from "../../backend/managers/AuthManager";
import Loader from "../../components/ui/loader/Loader";
import { validateEmail } from "../../utils/Validators";

function Login() {
  const userType = "corporate";

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [isFirstLogin, setIsFirstLogin] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const checkIsFirstLoginHandler = async (email) => {
    setIsLoading(true);
    const result = await authManager.checkIsFirstLoginApi(email);
    if (result.status === 0) {
      if (result?.data?.istemp === 1) {
        setIsFirstLogin(true);
      } else {
        setIsFirstLogin(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    email && checkIsFirstLoginHandler(email);
  }, [email]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const validateForm = (credentials) => {
    const { email, password } = credentials;
    if (!email || !password) {
      return "Invalid inputs.";
    }
    return validateEmail(email);
  };

  const submitFormHandler = async (credentials) => {
    const result = await authManager.loginApi(credentials);
    const token = result?.token;
    if (token) {
      let isFirst;
      const checkResult = await authManager.checkIsFirstLoginApi(
        credentials?.email
      );
      if (checkResult.status === 0) {
        if (checkResult?.data?.istemp === 1) {
          isFirst = true;
        } else {
          isFirst = false;
        }
      }
      Cookies.set("temp_token", token, { expires: 1 });
      localStorage.setItem("gauth", checkResult?.data?.gauth);
      if (isFirst) {
        navigate("/reset-password");
      } else {
        navigate("/2FA");
      }
    }
    return result;
  };

  return !isLoading ? (
    <SigninLayout
      initialState={{ email: "", password: "" }}
      title={t("signTitle")}
      label={isFirstLogin ? t("labelFirstConnection") : ""}
      input1={{
        id: "email",
        label: t("login"),
        placeholder: t("placeholderEmail"),
        type: "text",
      }}
      input2={{
        id: "password",
        label: isFirstLogin ? t("labelTemporaryPassword") : t("labelPassword"),
        placeholder: t("placeholderPassword"),
        type: "password",
      }}
      forgotPassword={
        isFirstLogin ? (
          ""
        ) : userType === "corporate" ? (
          <Link to="/forgot-password">{t("forgotPassword")}</Link>
        ) : (
          t("forgotPasswordInfo")
        )
      }
      validateForm={validateForm}
      onSubmitForm={submitFormHandler}
    />
  ) : (
    <Loader />
  );
}

export default Login;
