import { useEffect, useRef, useState } from "react";

import classes from "./ExpandableSelectInput.module.scss";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Card from "../../ui/card/Card";

function ExpandableSelectInput({ label, placeholder, children, inputValue, onInputChange }) {
  const [image, setImage] = useState("icons/arrow-right");
  const [showExpandedCard, setShowExpandedCard] = useState(false);

  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowExpandedCard(false);
      setImage("icons/arrow-right");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={classes.expSelectInput}>
      <CustomInput
        row={true}
        label={label}
        placeholder={placeholder}
        hasIcon={true}
        imagePath={image}
        isImgSvg={true}
        className={classes.customInput}
        value={inputValue}
        onChange={onInputChange}
        onInputFocused={() => setImage("icons/arrow-down")}
        onInputBlurred={() => setImage("icons/arrow-right")}
        onInputClick={() => setShowExpandedCard(true)}
      />
      {showExpandedCard && (
        <div className={classes.grid}>
          <Card
            className={classes.card}
            onClick={() => setShowExpandedCard(false)}
          >
            {children}
          </Card>
        </div>
      )}
    </div>
  );
}

export default ExpandableSelectInput;
