import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import Cookies from "js-cookie";

import classes from "./SidebarNavigation.module.scss";
import "../../../styles/ul.module.scss"; // Assuming ul.module.scss contains global styles
import sidebarContents from "../../../config/sidebarContent.json"; // Assuming sidebar content is imported from a JSON file
import SidebarItem from "./SidebarItem";
import ProfilePopupCard from "../../profilePopupCard/ProfilePopupCard";
import MyAccountModal from "../../modals/myAccountModal/MyAccountModal";
import ConfirmationModal from "../../modals/confirmationModal/ConfirmationModal";
import authManager from "../../../backend/managers/AuthManager";
import { errorActions } from "../../../store/reducers/Error-slice";
// import authManager from "../../../backend/managers/AuthManager";

function SideBarNavigation() {
  // State variables to manage drawer visibility, contents, and effect class
  const [gridRowStart, setGridRowStart] = useState(1);
  const [contents, setContents] = useState([]);
  const [effectClass, setEffectClass] = useState(classes.inactive);
  const [showProfile, setShowProfile] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const permissions = userData?.permissions;
  const hasProfileManagement =
    permissions?.profile?.edit === true ? true : false;

  const names = userData?.name?.split(" ");
  const firstNameLetters = names?.length > 0 ? names[0]?.split("") : [],
    lastNameLetters = names?.length > 1 ? names[1]?.split("") : [];
  const userNameLetters = `${
    firstNameLetters?.length > 0 ? firstNameLetters[0]?.toUpperCase() : ""
  }${lastNameLetters?.length > 0 ? lastNameLetters[0]?.toUpperCase() : ""}`;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openAccountModalHandler = () => {
    setShowAccountModal(true);
    setShowProfile(false);
  };

  const logoutHandler = async () => {
    setIsLoading(true);
    const result = await authManager.logoutApi();
    if (result?.status === 0) {
      Cookies.remove("token");
      localStorage.clear();
      navigate("/login");
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsLoading(false);
  };

  // Function to handle showing the drawer menu
  const showDrawerHandler = (contents, gridRowStart) => {
    setGridRowStart(gridRowStart);
    if (contents) {
      setContents(contents);
      setEffectClass(classes.active);
    } else {
      setContents([]);
    }
  };

  // Function to handle hiding the drawer menu
  const hideDrawerHandler = () => {
    setEffectClass(classes.inactive);
  };

  return (
    <>
      {/* Sidebar navigation */}
      <nav className={classes.navbar}>
        {/* Link to navigate to statistics */}
        <Link to="/dashboard" className={classes.logoContainer}>
          <img
            alt="logo"
            src={require("../../../assets/img/starzplay-logo.png")}
            className={classes.logo}
          />
        </Link>
        <ul>
          {/* Mapping through sidebar contents and rendering SidebarItem */}
          {sidebarContents.map(
            (item, index) =>
              item.id !== "users" && (
                <SidebarItem
                  key={index}
                  item={item}
                  gridRowStart={index + 1}
                  onShowDrawer={showDrawerHandler}
                  onHideDrawer={hideDrawerHandler}
                />
              )
          )}
          {hasProfileManagement && (
            <SidebarItem
              item={sidebarContents.find((e) => e.id === "users")}
              gridRowStart={4}
              onShowDrawer={showDrawerHandler}
              onHideDrawer={hideDrawerHandler}
            />
          )}
        </ul>
        {/* Profile section */}
        <div
          className={classes.profileSection}
          onClick={() => setShowProfile(true)}
        >
          <div className={classes.userOval}>{userNameLetters}</div>
        </div>
      </nav>

      {/* Drawer menu */}
      <div
        className={`${classes.drawer} ${effectClass}`}
        onMouseEnter={() => setEffectClass(classes.active)}
        onMouseLeave={hideDrawerHandler}
      >
        <div style={{ gridRowStart: `${gridRowStart}` }}>
          {/* Rendering links inside the drawer menu */}
          {contents.map((c) => (
            <Link onClick={hideDrawerHandler} key={c.id} to={c.to}>
              {t(c.id)}
            </Link>
          ))}
        </div>
      </div>

      {/* Profile popup */}
      {showProfile && (
        <ProfilePopupCard
          onOpenAccountModal={openAccountModalHandler}
          onClose={() => setShowProfile(false)}
          data={userData}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          className={classes.confModal}
          isModalOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          text={t("logout")}
          description={t("logout_confirmation_message")}
          btnConfirmClassName={`${classes.btnConfirm}`}
          btnConfirmTitle={t("yes")}
          btnCancelTitle={t("cancel")}
          onConfirm={logoutHandler}
          isLoading={isLoading}
        />
      )}
      {/* Account modal */}
      {showAccountModal && (
        <MyAccountModal
          isModalOpen={showAccountModal}
          onClose={() => setShowAccountModal(false)}
          userData={userData}
        />
      )}
    </>
  );
}

export default SideBarNavigation;
