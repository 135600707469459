import { t } from "i18next";

import classes from "./NoItemsView.module.scss";

function NoItemsView({
  className,
  titleClassName,
  textClassName,
  containerClassName,
  text,
  showCreateText,
  onClick,
}) {
  return (
    <div className={`${classes.container} ${containerClassName}`}>
      <div className={`${classes.noItems} ${className}`}>
        <span className={`${classes.title} ${titleClassName}`}>
          {text || t("noItems")}
        </span>
        {showCreateText && (
          <div className={`${classes.textContainer} ${textClassName}`}>
            <p>{t("noItemsCreate")}</p>
            <p>
              <span>{t("noItemsTopRightCorner")}</span>
              <span className={classes.addLink} onClick={onClick}>
                {t("here")}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NoItemsView;
