import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import classes from "./MyAccountModal.module.scss";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import styles from "../../../styles/Button.module.scss";
import Line from "../../ui/line/Line";
import EditSection from "./EditSection";
import accountManager from "../../../backend/managers/AccountManager";
import { validateNewAndConfirmedPassword } from "../../../utils/Validators";

function MyAccountModal({ userData, isModalOpen, onClose }) {
  const isCorporate = true;
  const username = userData?.name || "";
  const email = userData?.email || "";
  const password = "********";

  const initialCredentials = {
    currentCredential: "",
    newCredential: "",
    confirmCredential: "",
  };

  const navigate = useNavigate();

  const [credentials, setCredentials] = useState(initialCredentials);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [error, setError] = useState();
  const [isEditingName, setIsEditingName] = useState(false);
  const [updatedName, setUpdatedName] = useState(username);

  const showEditPasswordHandler = () => {
    setShowEditPassword((prevState) => !prevState);
    setShowEditEmail(false);
    setCredentials(initialCredentials);
    setError();
  };

  const showEditEmailHandler = () => {
    setShowEditEmail((prevState) => !prevState);
    setShowEditPassword(false);
    setCredentials(initialCredentials);
    setError();
  };

  const changeInputHandler = (field, value) => {
    setCredentials((prevState) => {
      return { ...prevState, [field]: value };
    });
    setError(null);
  };

  const validatePasswordFormHandler = (credentials) => {
    const { currentCredential, newCredential, confirmCredential } = credentials;
    if (currentCredential && newCredential && confirmCredential)
      return validateNewAndConfirmedPassword(newCredential, confirmCredential);
    return t("error_inputs_required");
  };

  const validateEmailFormHandler = (credentials) => {
    const { newCredential, confirmCredential } = credentials;
    if (newCredential && confirmCredential) {
      if (newCredential !== confirmCredential)
        return t("error_emails_not_matched");
      return null;
    }
    return t("error_inputs_required");
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();
    const error = showEditEmail
      ? validateEmailFormHandler(credentials)
      : validatePasswordFormHandler(credentials);
    setError(error);

    if (error === null) {
      if (showEditEmail) credentials["currentCredential"] = email;
      const result = showEditEmail
        ? await accountManager.updateUserProfileEmailApi(credentials)
        : await accountManager.updateUserProfilePasswordApi(credentials);
      if (result.status === 0) {
        onClose();
        navigate("/dashboard");
      } else {
        setError(result?.message);
      }
    }
  };

  const closeHandler = () => {
    onClose();
    setShowEditPassword(false);
  };

  const saveEditNameHandler = async () => {
    if (username !== updatedName) {
      const result = await accountManager.updateUserProfileNameApi(updatedName);
      if (result.status === 0) {
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...userData, name: updatedName })
        );
      }
    }
    setIsEditingName(false);
  };

  return (
    <Modal
      className={classes.modal}
      isOpen={isModalOpen}
      onClose={closeHandler}
    >
      <h2>{t("myAccount")}</h2>
      <div className={classes.row}>
        <label>{t("name")}</label>
        <input
          value={updatedName}
          onChange={(e) => setUpdatedName(e.target.value)}
          disabled={!isEditingName}
        />
        <Button
          title={isEditingName ? t("btnSave") : t("btnEdit")}
          className={`${classes.btnEdit} ${styles.button}`}
          onClick={
            isEditingName ? saveEditNameHandler : () => setIsEditingName(true)
          }
        />
      </div>
      {isCorporate && (
        <div className={classes.row}>
          <label>{t("labelEmail")}</label>
          <input value={email} disabled />
          <Button
            title={t("btnEdit")}
            className={`${classes.btnEdit} ${styles.button}`}
            onClick={showEditEmailHandler}
          />
        </div>
      )}
      <div className={classes.row}>
        <label>{t("labelPassword")}</label>
        <input type="password" value={password} disabled />
        <Button
          title={t("btnEdit")}
          className={`${classes.btnEdit} ${styles.button}`}
          onClick={showEditPasswordHandler}
        />
      </div>
      <form onSubmit={submitFormHandler}>
        {showEditPassword && (
          <>
            <EditSection
              title={t("editMyPassword")}
              type="password"
              inputs={{
                current: {
                  id: "currentCredential",
                  label: t("labelCurrentPassword"),
                  value: credentials.currentCredential,
                  isValid: true,
                  span: isCorporate
                    ? // <Link>{t("forgotPassword")}?</Link>
                      t("forgotPassword") + "?"
                    : t("forgotPasswordAccount"),
                },
                new: {
                  id: "newCredential",
                  label: t("labelNewPassword"),
                  value: credentials.newCredential,
                  isValid: true,
                },
                confirm: {
                  id: "confirmCredential",
                  label: t("labelConfirmPassword"),
                  value: credentials.confirmCredential,
                  isValid: true,
                },
              }}
              onInputChange={changeInputHandler}
            />
            {error && <p className={classes.error}>{error}</p>}
          </>
        )}
        {showEditEmail && (
          <>
            <EditSection
              title={t("editMyEmail")}
              type="email"
              inputs={{
                current: {
                  id: "currentCredential",
                  label: t("labelCurrentEmail"),
                  value: credentials.currentCredential || email,
                  disabled: true,
                },
                new: {
                  id: "newCredential",
                  label: t("labelNewEmail"),
                  value: credentials.newCredential,
                  isValid: true,
                },
                confirm: {
                  id: "confirmCredential",
                  label: t("labelConfirmEmail"),
                  value: credentials.confirmCredential,
                  isValid: true,
                },
              }}
              onInputChange={changeInputHandler}
            />
            {error && <p className={classes.error}>{error}</p>}
          </>
        )}
        <Line />
        <div className={classes.btnActions}>
          <Button type="submit" title={t("btnSave")} />
        </div>
      </form>
    </Modal>
  );
}

export default MyAccountModal;
