import classes from "./CategoryItem.module.scss";

function CategoryItem({
  id,
  name,
  number,
  className,
  onClick,
  selectedCategoryId,
  isDragging,
  onDrop,
  onHoverCategory,
  dragHoveredCatId,
  icon,
}) {
  return (
    <div
      className={`${classes.item} ${className} ${
        selectedCategoryId === id && classes.selected
      } ${isDragging && id !== "display_all" && classes.dragging} ${
        isDragging &&
        id !== "display_all" &&
        dragHoveredCatId === id &&
        classes.draggingHover
      }`}
      onClick={onClick}
      // onDrop={id !== "display_all" ? onDrop : () => {}}
      // onDragOver={id !== "display_all" ? (e) => e.preventDefault() : () => {}}
      // onDragEnter={() => onHoverCategory(id)}
    >
      <div className={classes.container}>
        {icon && <img alt="icon" src={icon} className={classes.icon} />}
        <span className={classes.name}>{name}</span>
      </div>
      <span className={classes.number}>{number}</span>
    </div>
  );
}

export default CategoryItem;
