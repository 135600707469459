import { t } from "i18next";

import classes from "./ConfirmationModal.module.scss";
import Modal from "../../ui/modal/Modal";
import Button from "../../ui/button/Button";
import Loader from "../../ui/loader/Loader";

function ConfirmationModal({
  isModalOpen,
  onClose,
  onConfirm,
  onCancel,
  text,
  description,
  name,
  impactDescription,
  btnCancelTitle,
  btnConfirmTitle,
  className,
  btnConfirmClassName,
  btnCancelClassName,
  isLoading,
  children,
}) {
  const modalStyle = {
    opacity: isModalOpen ? 1 : 0,
    top: isModalOpen ? "50%" : "-5rem",
    visibility: isModalOpen ? "visible" : "hidden",
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      style={modalStyle}
      className={`${classes.modal} ${className}`}
      backdropClassName={classes.backdrop}
    >
      <span>{text}</span>
      <p>
        {description} {name && <span className={classes.name}>{name}</span>}
      </p>
      {children}
      <div className={classes.actionsContainer}>
        {!isLoading ? (
          <>
            <Button
              title={btnCancelTitle ? btnCancelTitle : t("cancel")}
              onClick={onCancel ? onCancel : onClose}
              className={`${classes.btnCancel} ${btnCancelClassName}`}
            />
            <Button
              title={btnConfirmTitle ? btnConfirmTitle : t("yes")}
              className={`${classes.btnConfirm} ${btnConfirmClassName}`}
              onClick={onConfirm}
            />
          </>
        ) : (
          <Loader positionNotFixed={true} className={classes.loader} />
        )}
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
