import { t } from "i18next";

import classes from "./PackageCard.module.scss";
import Card from "../../ui/card/Card";
// import ContentHelper from "../../../utils/ContentHelper";
import Button from "../../ui/button/Button";

function PackageCard({ packageContent, onClick }) {
  const data = [
    {
      label: "name",
      value: packageContent?.sku_name || packageContent?.skuName,
    },
    // {
    //   label: "added_the",
    //   value: "09-03-2000",
    // },
    {
      label: "channels",
      value: packageContent?.channelsCount,
    },
    {
      label: "VOD",
      value: packageContent?.vodCount,
    },
  ];

  return (
    <Card className={classes.card}>
      <div>
        {data.map((row) => (
          <div
            className={`${classes.row} ${
              row.label === "name" && classes.bold
            } ${row.hasSpace && classes.marginBottom}`}
            key={row.label}
          >
            <label>{t(row.label) + ":"}</label>
            <span>{row.value}</span>
          </div>
        ))}
      </div>
      <div className={classes.actionContainer}>
        <Button
          title={t("view_more")}
          className={classes.btn}
          onClick={onClick}
        />
      </div>
    </Card>
  );
}

export default PackageCard;
