import { useState } from "react";
import { t } from "i18next";

import classes from "./ExpandableItem.module.scss";

function ExpandableItem({ customer, selectedCustomerMessageStatus }) {
  let custReadPercentage = Math.round(
    (customer?.read?.length / customer?.all?.length) * 100
  );
  let custUnreadPercentage = 100 - custReadPercentage;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div key={customer?.id}>
      <div
        className={classes.customerRow}
        onClick={() => setIsExpanded((prevState) => !prevState)}
      >
        <span>{customer?.name}</span>
        <div className={classes.span}>
          {custUnreadPercentage > 0 && (
            <span className={classes.red}>{custUnreadPercentage}%</span>
          )}
          {custReadPercentage > 0 && (
            <span className={`${classes.green} ${classes.ml}`}>
              {custReadPercentage}%
            </span>
          )}
          <img
            alt={t("arrow")}
            src={require(`../../../assets/img/icons/arrow-${
              isExpanded ? "bottom" : "forward"
            }.png`)}
            className={classes.arrow}
          />
        </div>
      </div>
      {isExpanded &&
        customer[selectedCustomerMessageStatus]?.map((sub) => (
          <div key={sub?.id} className={classes.subscribers}>
            <span>{sub?.subscriber?.subscriberName}</span>
            <span
              className={`${
                sub.isRead === true ? classes.green : classes.red
              } ${classes.span}`}
            >
              {sub.isRead === true ? t("read") : t("unread")}
            </span>
          </div>
        ))}
    </div>
  );
}

export default ExpandableItem;
