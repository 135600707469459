import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./VOD.module.scss";
// import vodManager from "../../../../backend/managers/VODManager";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import useFetchData from "../../../../hooks/useFetchData";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
// import Logger from "../../../../utils/Logger";
import MovieModal from "../../../../components/modals/movieModal/MovieModal";
// import CreateThemeModal from "../../../../components/modals/createThemeModal/CreateThemeModal";
import Toast from "../../../../components/toast/Toast";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
// import ContentHelper from "../../../../utils/ContentHelper";
import Loader from "../../../../components/ui/loader/Loader";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import SortHelper from "../../../../utils/SortHelper";
import movieManager from "../../../../backend/managers/MovieManager";
// import PageHeader from "../../../../components/pageHeader/PageHeader";
// import ConfirmationModal from "../../../../components/modals/confirmationModal/ConfirmationModal";
// import MainHeader from "../../../../components/layout/mainHeader/MainHeader";
// import EditThemeModal from "../../../../components/modals/editThemeModal/EditThemeModal";
// import ContentSelectionModal from "../../../../components/modals/contentSelectionModal/ContentSelectionModal";
// import contentSelectionController from "../../../../controllers/ContentSelectionController";

function VOD() {
  const SORT_OPTIONS = SortHelper.getContentSortOptions();

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState();
  const [isMovieModalOpen, setIsMovieModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[0]);
  // const [isAddThemeModalOpen, setIsAddThemeModalOpen] = useState(false);
  // const [showExpandableInput, setShowExpandableInput] = useState(false);
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);
  // const [isDragging, setIsDragging] = useState(false);
  // const [hoveredCategoryId, setHoveredCategoryId] = useState();
  // const [isEditThemeModalOpen, setIsEditThemeModalOpen] = useState(false);
  // const [isAddMovieInThemeOpen, setIsAddMovieInThemeOpen] = useState(false);
  // const [contentSelectionThemeId, setContentSelectionThemeId] = useState();

  const moviesNb = (categories && categories[0]?.movies?.length) || 0;
  // const vodCats = vodManager.getCategories();

  const {
    data,
    isLoading: loading,
    // onRefresh: refreshCategories,
  } = useFetchData(movieManager.getMoviesCategoriesApi);

  // const { isLoading: loading } = useFetchData(vodManager.getVODApi);

  useEffect(() => {
    setCategories(data);
    setSelectedCategory((prevState) => {
      if (prevState) {
        prevState.movies = SortHelper.sortData(
          prevState?.movies,
          selectedSortType?.id
        );
        return prevState;
      }
      if (data?.length > 0) {
        data[0].movies = SortHelper.sortData(
          data[0]?.movies,
          selectedSortType?.id
        );
      }
      return data[0];
    });
    setIsLoading(loading);
    // data && data.length > 0 && setContentSelectionThemeId(data[1]?.id);
    // eslint-disable-next-line
  }, [data]);

  const openMovieModalHandler = () => {
    setIsMovieModalOpen(true);
  };

  // const openThemeModalHandler = () => {
  //   setIsAddThemeModalOpen(true);
  // };

  const closeMovieModalHandler = () => {
    setIsMovieModalOpen(false);
    setSelectedMovie();
    setIsEditing(false);
  };

  // const closeThemeModalHandler = () => {
  //   setIsAddThemeModalOpen(false);
  // };

  // const openExpandableInputHandler = () => {
  //   setShowExpandableInput(true);
  // };

  // const closeExpandableInputHandler = () => {
  //   setShowExpandableInput(false);
  //   setCategories(data);
  //   setSelectedCategory((prevState) =>
  //     vodManager.getLocalCategoryById(prevState?.id)
  //   );
  // };

  // const openSearchExpandableInput = () => {
  //   return { placeholder: t("search"), value: "" };
  // };

  // const searchHandler = (inputValue) => {
  //   setSearchInputValue(inputValue);
  // };

  useEffect(() => {
    if (!searchInputValue) {
      setCategories(data);
      setSelectedCategory((prevState) => {
        const cat = movieManager.getLocalCategoryById(prevState?.id);
        if (cat && cat?.movies)
          cat.movies = SortHelper.sortData(cat.movies, selectedSortType?.id);
        return cat;
      });
      return;
    }
    const timeoutId = setTimeout(() => {
      setCategories(movieManager.searchLocally(searchInputValue));
      setSelectedCategory((prevState) => {
        const updatedCategory = movieManager.getLocalSearchedCategoryById(
          prevState.id
        );
        updatedCategory.movies = SortHelper.sortData(
          updatedCategory.movies,
          selectedSortType?.id
        );
        return updatedCategory;
      });
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  // const openDeleteMovieHandler = (movie) => {
  //   setSelectedMovie(movie);
  //   setIsDeleteModalOpen(true);
  // };

  // const deleteMovieHandler = () => {
  //   if (selectedItems.length > 0) {
  //     selectedItems?.forEach((item) => {
  //       vodManager.deleteVodApi(item.id);
  //     });
  //   } else {
  //     vodManager.deleteVodApi(selectedMovie?.id);
  //   }
  //   setIsDeleteModalOpen(false);
  // };

  // const openAddMovieInThemeHandler = () => {
  //   setContentSelectionThemeId(vodCats.length > 0 && vodCats[0]?.id);
  //   setIsAddMovieInThemeOpen(true);
  // };

  // const buttons = [
  //   {
  //     id: "delete",
  //     image: "delete",
  //     hidden: true,
  //     action: openDeleteMovieHandler,
  //   },
  //   {
  //     id: "add-in",
  //     image: "add-in",
  //     action: openAddMovieInThemeHandler,
  //     hasLeftBorder: false,
  //   },
  //   {
  //     id: "search",
  //     image: "search",
  //     expandable: true,
  //     action: openSearchExpandableInput,
  //   },
  //   {
  //     id: "add",
  //     action: openMovieModalHandler,
  //   },
  // ];

  // const [pageHeaderButtons, setPageHeaderButtons] = useState(buttons);

  const openEditMovieModalHandler = async (movie) => {
    setSelectedMovie(movie);
    setIsMovieModalOpen(true);
    setIsEditing(true);
  };

  // const openDeleteCategoryHandler = () => {
  //   if (selectedCategory?.medias.length === 0) {
  //     setSelectedMovie();
  //     setIsDeleteModalOpen(true);
  //   } else {
  //     setShowToast(true);
  //     setMessageText(t("delete_non_empty_cat"));
  //   }
  // };

  // const deleteCategoryHandler = () => {
  //   vodManager.deleteCategoryApi(selectedCategory?.id);
  //   setIsDeleteModalOpen(false);
  // };

  // const openEditThemeModalHandler = () => {
  //   setIsEditThemeModalOpen(true);
  // };

  // const menuButtons = [
  //   {
  //     id: "modify",
  //     imagePath: "icons/modify",
  //     activeImagePath: "icons/modify-active",
  //     action: openEditThemeModalHandler,
  //   },
  //   {
  //     id: "delete",
  //     imagePath: "icons/delete",
  //     activeImagePath: "icons/delete-active",
  //     action: openDeleteCategoryHandler,
  //   },
  //   {
  //     id: "add",
  //     imagePath: "icons/add",
  //     activeImagePath: "icons/add-active",
  //     action: openThemeModalHandler,
  //   },
  // ];

  // const selectItemsHandler = (item) => {
  //   if (!selectedItems.includes(item)) {
  //     setSelectedItems((prevState) => [...prevState, item]);
  //   } else {
  //     setSelectedItems((prevState) =>
  //       prevState.filter((c) => c.id !== item.id)
  //     );
  //   }
  // };

  // const unselectAllHandler = () => {
  //   setSelectedItems([]);
  // };

  // const setIsDraggingHandler = (isDragging) => {
  //   setIsDragging(isDragging);
  // };

  // const dragHoverCategoryHandler = (catId) => {
  //   setHoveredCategoryId(catId);
  // };

  // const dropHandler = (e) => {
  //   const droppedItems = JSON.parse(e.dataTransfer.getData("selectedItems"));
  //   droppedItems.forEach((vod) => {
  //     vodManager.dropVodApi(vod.id, hoveredCategoryId);
  //   });
  //   refreshCategories();
  //   setSelectedItems([]);
  // };

  // useEffect(() => {
  //   if (selectedItems.length > 0) {
  //     const updatedButtons = [...buttons];
  //     updatedButtons[0].hidden = false;
  //     updatedButtons[1].hasLeftBorder = true;
  //     setPageHeaderButtons(updatedButtons);
  //   } else {
  //     setPageHeaderButtons(buttons);
  //   }
  //   // eslint-disable-next-line
  // }, [selectedItems]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategory((prevState) => {
        const sortedMovies = SortHelper.sortData(
          prevState?.movies || [],
          selectedSortType?.id
        );
        return { ...prevState, movies: sortedMovies };
      });
    }
    // eslint-disable-next-line
  }, [selectedSortType]);

  return (
    <div className={classes.page}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* <PageHeader
            pageTitle={t("movies") + " (" + moviesNb + ")"}
            pageHeaderButtons={pageHeaderButtons}
            showExpandableInput={showExpandableInput}
            onOpenExpandableInput={openExpandableInputHandler}
            onCloseExpandableInput={closeExpandableInputHandler}
            onSearch={searchHandler}
          /> */}
          <MenuCardLayout
            // buttons={menuButtons}
            pageTitle={t("movies") + " (" + moviesNb + ")"}
            displayAllNumber={
              categories && categories.length > 0 && categories[0].movies.length
            }
            isDisplayAllSelected={selectedCategory?.id === "display_all"}
            onSelectDisplayAll={() => {
              const selectedCat =
                categories && categories.length > 0 ? categories[0] : null;
              selectedCat.movies = SortHelper.sortData(
                selectedCat?.movies,
                selectedSortType?.id
              );
              setSelectedCategory(selectedCat);
            }}
          >
            <SearchSortView
              hasSort={true}
              options={SORT_OPTIONS}
              selectedSortType={selectedSortType}
              onSelectSortType={setSelectedSortType}
              searchInputValue={searchInputValue}
              searchInputPlaceholder={t("searchMovie")}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={(value) => setSearchInputValue(value)}
            />
            {categories.map((category) => {
              return (
                category.id !== "display_all" && (
                  <CategoryItem
                    key={category?.id}
                    id={category?.id}
                    name={
                      category?.id !== "display_all"
                        ? category?.name
                        : t(category?.id)
                    }
                    number={category?.movies?.length}
                    onClick={() => {
                      category.movies = SortHelper.sortData(
                        category?.movies,
                        selectedSortType?.id
                      );
                      setSelectedCategory(category);
                    }}
                    selectedCategoryId={selectedCategory?.id}
                    // isDragging={isDragging}
                    // onDrop={dropHandler}
                    // onHoverCategory={dragHoverCategoryHandler}
                    // dragHoveredCatId={hoveredCategoryId}
                  />
                )
              );
            })}
            {selectedCategory?.movies && selectedCategory?.movies.length > 0 ? (
              <ul className={classes.vodList}>
                {selectedCategory?.movies?.map((movie) => (
                  <ContentCard
                    className={classes.itemCard}
                    key={movie?.id}
                    content={movieManager.getLocalVodById(movie?.id)}
                    onEdit={() => openEditMovieModalHandler(movie)}
                    // onDelete={() =>
                    //   openDeleteMovieHandler(
                    //     vodManager.getLocalVodById(movie?.id)
                    //   )
                    // }
                    // selectedItems={selectedItems}
                    // onSelectItems={selectItemsHandler}
                    // onSetIsDragging={setIsDraggingHandler}
                    // setHoveredCategoryId={setHoveredCategoryId}
                    ghostName={t("VOD")}
                  />
                ))}
              </ul>
            ) : (
              !isLoading && <NoItemsView onClick={openMovieModalHandler} />
            )}
          </MenuCardLayout>
          {isMovieModalOpen && (
            <MovieModal
              isModalOpen={isMovieModalOpen}
              onClose={closeMovieModalHandler}
              isEditing={isEditing}
              movie={isEditing && selectedMovie}
              disabled={true}
            />
          )}
          {showToast && (
            <Toast
              isDisplayed={showToast}
              type="warning"
              messageText={messageText}
              onDisplay={(isDisplayed) => setShowToast(isDisplayed)}
            />
          )}
          {/* {isAddMovieInThemeOpen && (
            <ContentSelectionModal
              isModalOpen={isAddMovieInThemeOpen}
              onClose={() => setIsAddMovieInThemeOpen(false)}
              modalTitle={t("add_movies_in")}
              mediaSelectionTitle={t("selectTheme")}
              availableCardPickerViewTitle={t("availableMovies")}
              selectedCardPickerViewTitle={t("selectedMovies")}
              mediaSelectionDataSource={vodCats}
              selectedMediaId={contentSelectionThemeId}
              onChangeMediaSelection={(id) => setContentSelectionThemeId(id)}
              availableContentDataSource={vodManager.getVods()}
              initialSelectedItems={contentSelectionController.getInitialSelectedItemsVods(
                contentSelectionThemeId
              )}
              fieldSort={"id"}
            />
          )} */}
        </>
      )}
    </div>
  );
}

export default VOD;
