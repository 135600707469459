import InputSign from "../../ui/inputs/inputSign/InputSign";
import classes from "./EditSection.module.scss";

function EditSection({ title, type, inputs, onInputChange, className }) {
  return (
    <div className={`${classes.editSection} ${className}`}>
      <h4>{title}</h4>
      <div className={classes.inputContainer}>
        <InputSign
          row
          classNameContainer={classes.inputContainer}
          type={type}
          isValid={inputs?.current?.isValid}
          id={inputs?.current?.id}
          label={inputs?.current?.label}
          placeholder={inputs?.current?.label}
          value={inputs?.current?.value}
          onChange={onInputChange}
          span={inputs?.current?.span}
          disabled={inputs?.current?.disabled}
        />
        <InputSign
          row
          classNameContainer={classes.inputContainer}
          type={type}
          isValid={inputs?.new?.isValid}
          id={inputs?.new?.id}
          label={inputs?.new?.label}
          placeholder={inputs?.new?.label}
          value={inputs?.new?.value}
          onChange={onInputChange}
          span={inputs?.new?.span}
        />
        <InputSign
          row
          classNameContainer={classes.inputContainer}
          type={type}
          isValid={inputs?.confirm?.isValid}
          id={inputs?.confirm?.id}
          label={inputs?.confirm?.label}
          placeholder={inputs?.confirm?.label}
          value={inputs?.confirm?.value}
          onChange={onInputChange}
          span={inputs?.confirm?.span}
        />
      </div>
    </div>
  );
}

export default EditSection;
