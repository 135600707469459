import { DASHBOARD_API, MAP_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

class DashboardManager {
  getDashboardDataApi = async () => {
    try {
      const response = await loaderCenter.get(DASHBOARD_API);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err.message;
    }
  };

  getVerticalsByCountryApi = async (countryCode) => {
    try {
      const response = await loaderCenter.get(
        MAP_API.GET_VERTICALS_BY_COUNTRY(countryCode)
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };
}

const dashboardManager = new DashboardManager();

export default dashboardManager;
