import { MESSAGE_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";
import Message from "../../models/Message";
import MessageType from "../../models/MessageType";
import MessageTypes from "../../config/MessageTypes.json";
import SortHelper from "../../utils/SortHelper";
import MESSAGE_TYPES from "../../config/MessageTypes.json";

let typedMessages = [];
let originalTypedMessages = [];
let typedMessagesMap = {};
let allMessagesMap = {};
let orderedTypedMessages = [];
// needed for pickerView
let allMessages = [];
let selectedCustomers = [],
  selectedRecipients = [];

function getTypedObject(type) {
  let pinned = [];
  let unpinned = [];
  if (!typedMessagesMap[type]) {
    typedMessagesMap[type] = {
      pinnedMessages: pinned,
      unpinnedMessages: unpinned,
    };
    typedMessagesMap[type].type = type;
    typedMessages.push(new MessageType(typedMessagesMap[type]));
  } else {
    pinned = typedMessagesMap[type].pinnedMessages;
    unpinned = typedMessagesMap[type].unpinnedMessages;
  }

  return { pinnedMessages: pinned, unpinnedMessages: unpinned };
}

const keyOrder = [
  "display_all",
  "type_message",
  "type_alert_info",
  "type_alert_urgent",
  "type_alert_warn",
  "pinned",
  "draft",
];

class MessageManager {
  prepareMessages = (dataSource) => {
    typedMessages = [];
    allMessages = [];
    typedMessagesMap = {};
    allMessagesMap = {};
    orderedTypedMessages = [];
    allMessages = [];

    const displayAllObject = getTypedObject("display_all");
    const pinnedObject = getTypedObject("pinned");
    const draftObject = getTypedObject("draft");

    dataSource?.forEach((m) => {
      if (m) {
        const message = new Message(m);
        let type = message.type;
        const object = getTypedObject(type);
        if (!allMessagesMap[message.id]) {
          allMessagesMap[message.id] = message;
          if (message.pin) {
            object.pinnedMessages.push(message);
            displayAllObject.pinnedMessages.push(message);
            pinnedObject.pinnedMessages.push(message);
          } else {
            object.unpinnedMessages.push(message);
            displayAllObject.unpinnedMessages.push(message);
          }
          // if (message.messageStatuses.length === 0) {
          //   message.pin
          //     ? draftObject.pinnedMessages.push(message)
          //     : draftObject.unpinnedMessages.push(message);
          // }
        }
      }
    });

    allMessages.push(
      ...displayAllObject.pinnedMessages,
      ...displayAllObject.unpinnedMessages
    );

    // to add types not found in typedMessagesMap into typedMessages & typedMessagesMap
    keyOrder.forEach((type) => {
      if (!typedMessagesMap[type]) {
        const object = {
          type: type,
          pinnedMessages: [],
          unpinnedMessages: [],
        };
        typedMessages.push(new MessageType(object));
        typedMessagesMap[type] = object;
      }
    });

    // to sort typedMessages depending on keyOrder array
    orderedTypedMessages = typedMessages.sort((a, b) => {
      const aIndex = keyOrder.indexOf(a.data.type);
      const bIndex = keyOrder.indexOf(b.data.type);

      if (aIndex === -1) return 1; // If type not found in keyOrder, move it to the end
      if (bIndex === -1) return -1; // If type not found in keyOrder, move it to the end

      return aIndex - bIndex;
    });
  };

  getMessagesApi = async (signal) => {
    try {
      const response = await loaderCenter.get(MESSAGE_API.MESSAGES, signal);

      const responseData = await response.json();
      const messages = responseData.data;
      // originalMessages = messages;

      const allowedTypes = JSON.parse(localStorage.getItem("userData"))
        ?.permissions?.messages?.types;
      const allowedMessages = messages.filter((message) =>
        allowedTypes?.includes(message?.type)
      );

      this.prepareMessages(
        allowedTypes?.length > 0 ? allowedMessages : messages
      );

      originalTypedMessages = orderedTypedMessages;

      // array of MessageType objects
      return {
        data: orderedTypedMessages,
        status: responseData.status,
      };
    } catch (err) {
      throw err;
    }
  };

  getMessageByIdApi = async (messageId) => {
    try {
      const response = await loaderCenter.get(MESSAGE_API.MESSAGE(messageId));

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  createMessageApi = async (messageData) => {
    try {
      const response = await loaderCenter.post(MESSAGE_API.CREATE_MESSAGE, {
        ...messageData,
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateMessageApi = async (messageData) => {
    try {
      const response = await loaderCenter.post(MESSAGE_API.UPDATE_MESSAGE, {
        ...messageData,
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteMessageApi = async (messageId) => {
    try {
      const response = await loaderCenter.post(MESSAGE_API.DELETE_MESSAGE, {
        id: messageId,
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getAllMessages() {
    return allMessages;
  }

  getMessageInfoByType(type) {
    return MessageTypes.find((m) => m.id === type);
  }

  // editPinMessageApi = async (messageId, pin) => {
  //   await loaderCenter.post(CUSTOMER_API.EDIT_MESSAGE(messageId), {
  //     id: messageId,
  //     pin: pin,
  //   });
  // };

  transferRecipients(source, selectedItems, target, sourceKey, targetKey) {
    // Convert target to a map for efficient updating
    const targetMap = new Map(target.map((item) => [item.id, item]));

    // Convert selected items to a map for efficient removal
    const selectedItemMap = new Map(
      selectedItems.map((item) => [item.id, item])
    );

    // Update target
    selectedItems.forEach((selectedItem) => {
      if (targetMap.has(selectedItem.id)) {
        const existingItem = targetMap.get(selectedItem.id);
        const existingSubscriberIds = new Set(
          existingItem[targetKey].map((sub) => sub.id)
        );

        const updatedSubscribers = [
          ...existingItem[targetKey],
          ...selectedItem[targetKey].filter(
            (sub) => !existingSubscriberIds.has(sub.id)
          ),
        ];

        targetMap.set(selectedItem.id, {
          ...existingItem,
          [targetKey]: updatedSubscribers,
        });
      } else {
        targetMap.set(selectedItem.id, selectedItem);
      }
    });

    // Remove selected items' subscribers from source
    const updatedSource = source
      .map((item) => {
        if (selectedItemMap.has(item.id)) {
          const selectedItem = selectedItemMap.get(item.id);
          const selectedSubscriberIds = new Set(
            selectedItem[targetKey].map((sub) => sub.id)
          );

          const filteredSubscribers = item[sourceKey].filter(
            (sub) => !selectedSubscriberIds.has(sub.id)
          );

          return { ...item, [sourceKey]: filteredSubscribers };
        }
        return item;
      })
      .filter(
        (item) => item[sourceKey].length > 0 || !selectedItemMap.has(item.id)
      );

    // Convert targetMap back to an array
    const updatedTarget = SortHelper.arrangeData(
      Array.from(targetMap.values()),
      false,
      "customerName"
    );

    return { updatedSource, updatedTarget };
  }

  setSelectedCustomers(items) {
    selectedCustomers = [...items];
  }

  getSelectedCustomers() {
    return selectedCustomers;
  }

  setSelectedRecipients(items) {
    selectedRecipients = [...items];
  }

  getSelectedRecipients() {
    return selectedRecipients;
  }

  getAllowedMessageTypes(allowedTypes) {
    return MESSAGE_TYPES.filter((type) => allowedTypes.includes(type?.id));
  }
}

const messageManager = new MessageManager();

export default messageManager;
