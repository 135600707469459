import { useRef, useState } from "react";
import { t } from "i18next";

import classes from "./VerticalRowGeneralModal.module.scss";
import Modal from "../../ui/modal/Modal";
import ContentModel from "../../../models/ContentModel";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import LanguagesList from "../../languagesList/LanguagesList";
import Loader from "../../ui/loader/Loader";
import useElementWidth from "../../../hooks/useElementWidth";

function VerticalRowGeneralModal({
  isModalOpen,
  onClose,
  isEditing,
  content,
  onSubmit,
  isModalLoading,
  rankFieldName,
  type,
  children,
  isDefaultSelected,
}) {
  const contentModal = new ContentModel(content);
  const data = contentModal.data;
  const initialTitles = data?.title || { en: "", ar: "" };
  // const langs = Object.keys(initialTitles);
  const langs = ["en", "ar"];
  const isDefaultInitialValue = data?.isDefault;

  const [titles, setTitles] = useState(initialTitles);
  const [rank, setRank] = useState(
    isEditing && rankFieldName ? data[rankFieldName] ?? "" : ""
  );
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const submitHandler = (e) => {
    e.preventDefault();
    if (titles["en"] && (rank || rank === 0)) {
      if (isEditing) {
        if (
          JSON.stringify(initialTitles) !== JSON.stringify(titles) ||
          rank !== data[rankFieldName] ||
          isDefaultInitialValue !== isDefaultSelected
        ) {
          onSubmit(titles, rank, isDefaultSelected);
        }
        return;
      }
      onSubmit(titles, rank, isDefaultSelected);
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} className={classes.modal}>
      <h2>
        {t(isEditing ? "modifyTitle" : "create", {
          type: type,
          title: initialTitles["en"] || "",
        })}
      </h2>
      <form onSubmit={submitHandler}>
        <div className={classes.container}>
          <div className={classes.langContainer}>
            <div ref={ref} className={classes.langs}>
              {divWidth && (
                <LanguagesList
                  dataSource={langs}
                  selectedLang={selectedLanguage}
                  setSelectedLang={setSelectedLanguage}
                  width={divWidth}
                />
              )}
            </div>
          </div>
          <CustomInput
            label={t("title")}
            row={true}
            className={classes.customInput}
            value={titles[selectedLanguage] || ""}
            onChange={(e) =>
              setTitles({ ...titles, [selectedLanguage]: e.target.value })
            }
            placeholder={t("title")}
          />
          <CustomInput
            label={t("rank")}
            row={true}
            className={classes.customInput}
            value={rank}
            onChange={(e) => setRank(+e.target.value)}
            type={"number"}
            placeholder={t("rank")}
          />
          {children}
        </div>
        <Line className={classes.line} />
        <div className={classes.actionContainer}>
          {!isModalLoading ? (
            <Button title={t("btnSave")} type={"submit"} />
          ) : (
            <Loader positionNotFixed={true} className={classes.loader} />
          )}
        </div>
      </form>
    </Modal>
  );
}

export default VerticalRowGeneralModal;
