import { useState } from "react";
import { t } from "i18next";

import classes from "./EditPageRowsRankingModal.module.scss";
import Modal from "../../ui/modal/Modal";
import ContentHelper from "../../../utils/ContentHelper";
import Button from "../../ui/button/Button";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import Loader from "../../ui/loader/Loader";

function EditPageRowsRankingModal({
  isModalOpen,
  onClose,
  pageData,
  onSaveRowsRanking,
  isEditRankLoading,
}) {
  const initialRows = pageData?.content;
  const [rows, setRows] = useState(initialRows || []);
  const [selectedRow, setSelectedRow] = useState(
    initialRows && initialRows?.length > 0 && initialRows[0]
  );

  const moveToUpHandler = async (rank) => {
    const updatedRows = [...rows];
    const index = updatedRows?.findIndex((e) => e?.id === selectedRow?.id);
    const updatedSelectedRow = {
      ...selectedRow,
      rowOrder: rank - 1 >= 0 ? rank - 1 : 0,
    };
    updatedRows[index] = updatedSelectedRow;
    if (index > 0) {
      updatedRows[index - 1] = {
        ...updatedRows[index - 1],
        rowOrder: +updatedRows[index - 1]?.rowOrder + 1,
      };
    }
    setRows(updatedRows);
  };

  const moveToDownHandler = async (rank) => {
    const updatedRows = [...rows];
    const index = updatedRows?.findIndex((e) => e?.id === selectedRow?.id);
    const length = rows?.length;
    if (index < length - 1) {
      const updatedSelectedRow = {
        ...selectedRow,
        rowOrder: rank + 1 < length ? rank + 1 : length,
      };
      updatedRows[index] = updatedSelectedRow;
      updatedRows[index + 1] = {
        ...updatedRows[index + 1],
        rowOrder: +updatedRows[index + 1]?.rowOrder - 1,
      };
    }
    setRows(updatedRows);
  };

  const saveHandler = () => {
    if (JSON.stringify(pageData?.content) !== JSON.stringify(rows)) {
      onSaveRowsRanking(
        rows.map((row) => {
          return { id: row?.id, order: row?.rowOrder };
        })
      );
    }
  };

  return (
    <Modal className={classes.modal} isOpen={isModalOpen} onClose={onClose}>
      <h2>{t("edit_page_rows_ranking", { pageName: t(pageData?.id) })}</h2>
      <ul className={classes.rowsList}>
        {rows
          .sort((a, b) => +a?.rowOrder - +b?.rowOrder)
          ?.map((row) => {
            const isSelected = selectedRow?.id === row?.id;
            return (
              <div
                key={row?.id}
                onClick={() => setSelectedRow(row)}
                className={`${classes.item} ${
                  isSelected && classes.selectedRow
                }`}
              >
                <div>
                  <span>{row?.rowOrder + ". "}</span>
                  <span>{ContentHelper.getNameByLang(row)}</span>
                </div>
                {isSelected && (
                  <div className={classes.actionsContainer}>
                    <ButtonIcon
                      imagePath="icons/arrow-up"
                      onClick={() => moveToUpHandler(+row?.rowOrder)}
                    />
                    <ButtonIcon
                      imagePath="icons/arrow-up"
                      className={classes?.arrowBottom}
                      onClick={() => moveToDownHandler(+row?.rowOrder)}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </ul>
      <div className={classes.actionContainer}>
        {!isEditRankLoading ? (
          <Button title={t("btnSave")} onClick={saveHandler} />
        ) : (
          <Loader positionNotFixed={true} className={classes.loader} />
        )}
      </div>
    </Modal>
  );
}

export default EditPageRowsRankingModal;
