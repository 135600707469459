import { useEffect, useState } from "react";
import { t } from "i18next";

import HelpCard from "../HelpCard";

function TermsAndConditionsCard({ data, onSave, isLoading }) {
  const [details, setDetails] = useState({ en: "", ar: "" });

  useEffect(() => {
    setDetails(data?.details || { en: "", ar: "" });
  }, [data]);

  return (
    <HelpCard
      text={t("fill_in_terms")}
      details={details}
      onSave={onSave}
      isLoading={isLoading}
    />
  );
}

export default TermsAndConditionsCard;
