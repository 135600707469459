import { useState } from "react";
import { t } from "i18next";

import classes from "./LanguagesList.module.scss";

function LanguagesList({
  dataSource = ["en", "ar"],
  className,
  selectedLang,
  setSelectedLang = () => {},
  width,
  isDisabled,
}) {
  const itemWidth = 73.6; // 3.6rem width + 1rem margin = 4.6rem * 16px
  const itemsToShow = Math.floor(width / itemWidth);
  const totalItems = dataSource?.length;
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollLeftHandler = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsToShow, 0));
  };

  const scrollRightHandler = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + itemsToShow, totalItems - itemsToShow)
    );
  };

  return (
    <div
      className={`${classes.langContainer} ${className} ${
        isDisabled && classes.disabled
      }`}
      style={{ width: width }}
    >
      <div
        id="list-container"
        className={`${classes.list} ${
          currentIndex > 0 && classes.paddingLeft
        } ${itemsToShow + currentIndex < totalItems && classes.paddingRight}`}
      >
        {dataSource
          .slice(currentIndex, currentIndex + itemsToShow)
          ?.map((lang) => (
            <span
              key={lang}
              className={
                selectedLang === lang && !isDisabled ? classes.active : ""
              }
              onClick={() => !isDisabled && setSelectedLang(lang)}
            >
              {t(lang)}
            </span>
          ))}
      </div>
      {currentIndex > 0 && (
        <div
          className={`${classes.imgContainer} ${classes.left}`}
          onClick={scrollLeftHandler}
        >
          <img
            alt={t("arrow")}
            src={require("../../assets/img/icons/arrow-forward.png")}
          />
        </div>
      )}
      {itemsToShow + currentIndex < totalItems && (
        <div
          className={`${classes.imgContainer} ${classes.right}`}
          onClick={scrollRightHandler}
        >
          <img
            alt={t("arrow")}
            src={require("../../assets/img/icons/arrow-forward.png")}
          />
        </div>
      )}
    </div>
  );
}

export default LanguagesList;
