import { useEffect, useState } from "react";
import { t } from "i18next";
import { useDispatch } from "react-redux";

import classes from "./TargetAudienceModal.module.scss";
import Button from "../../ui/button/Button";
import Modal from "../../ui/modal/Modal";
import SearchableGroupedPickerView from "./SearchableGroupedPickerView";
import customerManager from "../../../backend/managers/CustomerManager";
import Loader from "../../ui/loader/Loader";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import messageManager from "../../../backend/managers/MessageManager";
import SortHelper from "../../../utils/SortHelper";
import search from "../../../utils/Search";
import { recipientsActions } from "../../../store/reducers/Recipients-slice";

function TargetAudienceModal({
  isModalOpen,
  onClose,
  onSaveRecipients,
  initialRecipients,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [initialCustomers, setInitialCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [initialRecipientValues, setInitialRecipientValues] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [isSelectAllCustomersChecked, setIsSelectAllCustomersChecked] =
    useState(false);

  const ORIGINAL_CUSTOMERS =
    customerManager.getOriginalCustomersWithSubscription();

  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomers = async () => {
      setIsLoading(true);
      const result = await customerManager.getCustomersWithSubscriptions();
      if (result?.status === 0) {
        const initialCustomers = removeInitialCustomers(
          SortHelper.arrangeData(result?.data, false, "customerName")
        );
        setCustomers(initialCustomers);
        setInitialCustomers(initialCustomers);
      }
      setIsLoading(false);
    };

    if (isModalOpen) {
      ORIGINAL_CUSTOMERS.length > 0
        ? setCustomers(
            removeInitialCustomers(
              SortHelper.arrangeData(ORIGINAL_CUSTOMERS, false, "customerName")
            )
          )
        : getCustomers();
    }
    setRecipients(initialRecipients);
    setInitialRecipientValues(initialRecipients);
  }, [isModalOpen]);

  const removeInitialCustomers = (dataSource) => {
    const initialRecipientIds = new Set(
      initialRecipients.map((item) => item.id)
    );
    const updatedCustomers = dataSource
      ?.map((customer) => {
        if (initialRecipientIds.has(customer?.id)) {
          const initialRecipient = initialRecipients?.find(
            (r) => r?.id === customer?.id
          );
          const initialSubscriberIds = new Set(
            initialRecipient?.subscriber?.map((s) => s?.id)
          );
          const filteredSubscribers = customer?.subscriber?.filter(
            (sub) => !initialSubscriberIds.has(sub.id)
          );
          return { ...customer, subscriber: filteredSubscribers };
        }
        return customer;
      })
      .filter(
        (customer) =>
          customer?.subscriber?.length > 0 ||
          !initialRecipientIds.has(customer?.id)
      );
    return updatedCustomers;
  };

  const selectCustomersHandler = (items) => {
    setSelectedCustomers(SortHelper.arrangeData(items, false, "customerName"));
    messageManager.setSelectedCustomers(items);
  };

  const selectRecipientsHandler = (items) => {
    setSelectedRecipients(SortHelper.arrangeData(items, false, "customerName"));
    messageManager.setSelectedRecipients(items);
  };

  const addToRecipientsHandler = () => {
    const result = messageManager.transferRecipients(
      customers,
      selectedCustomers,
      recipients,
      "subscriber",
      "subscriber"
    );
    setCustomers(result.updatedSource);
    setRecipients(result.updatedTarget);
    setInitialRecipientValues(result.updatedTarget);
    setInitialCustomers(result.updatedSource);
    setSelectedCustomers([]);
    setSelectedRecipients([]);
    dispatch(recipientsActions.setIsEmpty(true));
  };

  const removeFromRecipientsHandler = () => {
    const result = messageManager.transferRecipients(
      recipients,
      selectedRecipients,
      customers,
      "subscriber",
      "subscriber"
    );
    setRecipients(result.updatedSource);
    setInitialRecipientValues(result.updatedSource);
    setInitialCustomers(result.updatedTarget);
    setCustomers(result.updatedTarget);
    setSelectedRecipients([]);
    setSelectedCustomers([]);
    dispatch(recipientsActions.setIsEmpty(true));
  };

  const searchCustomerHandler = (inputValue) => {
    setCustomers(
      SortHelper.arrangeData(
        search(initialCustomers, inputValue, ["customerName"]),
        false,
        "customerName"
      )
    );
    setSelectedCustomers(messageManager.getSelectedCustomers());
  };

  const searchRecipientHandler = (inputValue) => {
    setRecipients(
      SortHelper.arrangeData(
        search(initialRecipientValues, inputValue, ["customerName"]),
        false,
        "customerName"
      )
    );
    setSelectedCustomers(messageManager.getSelectedRecipients());
  };

  const searchSubscriberHandler = (dataSource, inputValue) => {
    return search(dataSource, inputValue, ["subscriberName"]);
  };

  // const selectAllCustomersHandler = (selectAll) => {
  //   setSelectedCustomers(selectAll ? customers : []);
  //   // dispatch(recipientsActions.setSelectedItems(customers));
  // };

  // useEffect(() => {
  //   // console.log(customers, selectedCustomers);
  //   if (
  //     JSON.stringify(selectedCustomers) === JSON.stringify(customers) &&
  //     selectedCustomers?.length > 0 &&
  //     customers?.length > 0
  //   ) {
  //     setIsSelectAllCustomersChecked(true);
  //   } else {
  //     setIsSelectAllCustomersChecked(false);
  //   }
  //   // eslint-disable-next-line
  // }, [selectedCustomers]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      className={classes.modal}
      backdropClassName={classes.backdrop}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.moveActionsContainer}>
            <ButtonIcon
              imagePath={"buttons/access"}
              activeImagePath={"buttons/access-active"}
              onClick={addToRecipientsHandler}
            />
            <ButtonIcon
              imagePath={"buttons/access-backward"}
              activeImagePath={"buttons/access-backward-active"}
              onClick={removeFromRecipientsHandler}
            />
          </div>
          <div className={classes.sectionsContainer}>
            <SearchableGroupedPickerView
              title={t("allCustomers")}
              inputPlaceholder={t("search_for_customer")}
              dataSource={customers}
              initialSelectedItems={selectedCustomers}
              onSelectItems={selectCustomersHandler}
              onSearchCustomer={searchCustomerHandler}
              // isSelectAllChecked={isSelectAllCustomersChecked}
              // setIsSelectAllChecked={setIsSelectAllCustomersChecked}
              // onSelectAll={selectAllCustomersHandler}
              onSearchSubscriber={searchSubscriberHandler}
            />
            <SearchableGroupedPickerView
              title={t("recipients")}
              inputPlaceholder={t("search_for_recipient")}
              dataSource={recipients}
              onSelectItems={selectRecipientsHandler}
              onSearchCustomer={searchRecipientHandler}
              onSearchSubscriber={searchSubscriberHandler}
              initialSelectedItems={selectedRecipients}
            />
          </div>
          <div className={classes.actionContainer}>
            <Button
              title={t("btnSave")}
              onClick={() => {
                onSaveRecipients(recipients);
                onClose();
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
}

export default TargetAudienceModal;
