import Card from "../../ui/card/Card";
import classes from "./DashboardCard.module.scss";

function DashboardCard({ imageName, children, onClick, hasPointerCursor }) {
  return (
    <Card
      className={classes.dashboardCard}
      onClick={onClick}
      style={{ cursor: hasPointerCursor ? "pointer" : "default" }}
    >
      {imageName && (
        <img
          src={require(`../../../assets/img/dashboard/${imageName}.png`)}
          alt="icon"
        />
      )}
      {children}
    </Card>
  );
}

export default DashboardCard;
