import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./NewContents.module.scss";
import Loader from "../../../../components/ui/loader/Loader";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import useFetchData from "../../../../hooks/useFetchData";
import contentManager from "../../../../backend/managers/ContentManager";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import ButtonIcon from "../../../../components/ui/buttonIcon/ButtonIcon";
import LinkContentsToVerticalsAndPackagesModal from "../../../../components/modals/linkContentsToVerticalsAndPackagesModal/LinkContentsToVericalsAndPackagesModal";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import { errorActions } from "../../../../store/reducers/Error-slice";
import Toast from "../../../../components/toast/Toast";
import FeedHistoryCard from "../../../../components/contentCards/feedHistoryCard/FeedHistoryCard";

function NewContents() {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedVods, setSelectedVods] = useState([]);
  const [openLinkNotAssignedVodsModal, setOpenLinkNotAssignedVodsModal] =
    useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [toastNotification, setToastNotification] = useState({});
  const [feedHistoryList, setFeedHistoryList] = useState([])
  const dispatch = useDispatch();




  const {
    data,
    isLoading: loading,
    onRefresh,
  } = useFetchData(contentManager.getNewContentNotAssignedApi);

  const {
    data: feedList,
    loading: isLoadingFeed,
    onRefresh: onRefreshFeed,
  } = useFetchData(contentManager.getFeedHistory);

  useEffect(() => {
    setCategories(data);
    setFeedHistoryList(feedList)
    setIsLoading(loading);
    setSelectedCategory((prevState) => {
      if (prevState) {
        return prevState;
      } else return Object.keys(data)[0];
    });
    setSearchInputValue("");
    // eslint-disable-next-line
  }, [data]);

  const selectVodsHandler = (item) => {
    if (!selectedVods.includes(item)) {
      setSelectedVods((prevState) => [...prevState, item]);
    } else {
      setSelectedVods((prevState) => prevState.filter((c) => c.id !== item.id));
    }
  };

  const assignContentsHandler = async (verticalIds, planIds, addOnIds) => {
    setIsModalLoading(true);
    const object = {
      contentIds: selectedVods.map((vod) => vod?.id),
      planIds,
      addOnIds,
      verticalIds,
    };
    const result = await contentManager.assignNewContentApi(object);
    if (result?.status === 0) {
      setOpenLinkNotAssignedVodsModal(false);
      onRefresh();
      setSelectedVods([]);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsModalLoading(false);
  };

  const refreshHandler = async () => {
    setIsLoading(true);
    const result = await contentManager.refreshFeedContents();
    if (result) {
      onRefresh();
      setToastNotification({
        messageText: t("content_refreshed_successfully"),
        type: "info-green",
        isDisplayed: true,
      });
      onRefreshFeed()
    } else {
      dispatch(errorActions.setErrorMessage("Failed to refresh content"));
    }
    // setIsLoading(loading);
  }

  useEffect(() => {
    if (!searchInputValue) {
      setCategories(data);
      setSelectedCategory((prevState) => {
        if (prevState) {
          return prevState;
        } else return Object.keys(data)[0];
      });
      return;
    }
    const timeoutId = setTimeout(() => {
      const result = contentManager.searchLocally(searchInputValue);
      setCategories(result);
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <MenuCardLayout pageTitle={t("newContents")} noDisplayAll={true}>
        <div className={classes.row}>
          <SearchSortView
            searchInputValue={searchInputValue}
            searchInputPlaceholder={t("searchContent")}
            onClearSearchIput={() => setSearchInputValue("")}
            onChangeSearchInput={(value) => setSearchInputValue(value)}
          />
          <div className={classes.row}>
            <ButtonIcon
              imagePath={"buttons/refresh"}
              activeImagePath={"buttons/refresh-active"}
              className={classes.btn}
              onClick={() => {
                refreshHandler();
              }
              }
            />
            <ButtonIcon
              imagePath={"buttons/add"}
              activeImagePath={"buttons/add-active"}
              className={classes.btn}
              onClick={() =>
                selectedVods?.length > 0
                  ? setOpenLinkNotAssignedVodsModal(true)
                  : setToastNotification({
                    isDisplayed: true,
                    type: "warning",
                    messageText: t("no_selected_contents")
                  })
              }
            />
          </div>
        </div>
        <div>
          {Object.keys(categories)?.map((key) => (
            <CategoryItem
              key={key}
              id={key}
              name={t(key)}
              number={categories[key]?.length}
              onClick={() => {
                setSelectedCategory(key);
              }}
              selectedCategoryId={selectedCategory}
            />
          ))}
        </div>
        {selectedCategory && categories[selectedCategory]?.length > 0 ? (
          <ul className={classes.list}>
            {categories[selectedCategory]?.map((vod) => (
              <ContentCard
                key={vod?.id}
                className={classes.itemCard}
                content={vod}
                selectable={true}
                selectedItems={selectedVods}
                onSelectItems={selectVodsHandler}
              />
            ))}
          </ul>
        ) : (
          !isLoading && <NoItemsView />
        )}
        <FeedHistoryCard feedHistoryList={feedHistoryList} />
      </MenuCardLayout>
      {openLinkNotAssignedVodsModal && (
        <LinkContentsToVerticalsAndPackagesModal
          isModalOpen={openLinkNotAssignedVodsModal}
          onClose={() => setOpenLinkNotAssignedVodsModal(false)}
          onSubmit={assignContentsHandler}
          isModalLoading={isModalLoading}
          title={t("link_not_assigned_contents")}
          type={"newContent"}
        />
      )}
      {toastNotification && toastNotification?.isDisplayed && (
        <Toast
          isDisplayed={toastNotification?.isDisplayed}
          type={toastNotification?.type}
          messageText={toastNotification?.messageText}
          onDisplay={(isDisplayed) =>
            setToastNotification((prevState) => ({
              ...prevState,
              isDisplayed: isDisplayed,
            }))
          }
        />
      )}
    </>
  );
}

export default NewContents;
