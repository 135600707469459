import { useState, useEffect, useRef } from "react";
import { t } from "i18next";
import classes from "./PreferredContentModal.module.scss";

import Modal from "../../ui/modal/Modal";
import LanguagesList from "../../languagesList/LanguagesList";
import Button from "../../ui/button/Button";
import Line from "../../ui/line/Line";
import PickerView from "../../pickerViews/pickerView/PickerView";

import useElementWidth from "../../../hooks/useElementWidth";
import CustomInput from "../../ui/inputs/customInput/CustomInput";

function PreferredContentModal({
  title,
  isModalOpen,
  onClose,
  onSubmit,
  editData,
  preferredContentList,
}) {
  const [selectedLang, setSelectedLang] = useState("en");
  const initialNameValues = { en: "", ar: "" };
  const [nameValue, setNameValue] = useState(initialNameValues);
  const [numberValue, setNumberValue] = useState(null);
  const [isDefaultSelected, setIsDefaultSelected] = useState(
    editData?.isDefault || false
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setNumberValue(value ? Number(value) : null);
  };
  const handleNameChange = (e) => {
    const updatedNameValue = {
      ...nameValue,
      [selectedLang]: e.target.value,
    };
    setNameValue(updatedNameValue);
  };

  const selectItemsHandler = (items) => {
    setIsDefaultSelected(!isDefaultSelected);
  };

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const closeHandler = () => {
    onClose();
  };

  const submitHandler = () => {
    let rank = numberValue;
    if (!rank) {
      rank = preferredContentList[preferredContentList.length - 1].rank + 1;
    }
    const data = {
      rank: rank,
      values: nameValue,
      isDefault: isDefaultSelected
    };

    if (editData) {
      data.elementId = editData.elementId;
    }
    onSubmit(data);
    setNameValue("");
    setNumberValue(null);
    onClose();
  };

  useEffect(() => {
    if (editData) {
      setNameValue(editData.values || "");
      setNumberValue(editData.rank !== undefined ? editData.rank : null);
    }
  }, [editData]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeHandler}
      className={classes.modal}
    >
      <h2>{title}</h2>

      <div className={classes.langContainer} ref={ref}>
        {divWidth && (
          <LanguagesList
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            width={divWidth - 48}
          />
        )}
      </div>

      <div className={classes.inputsContainer}>
        <CustomInput
          label={t("name")}
          row={true}
          className={classes.customInput}
          placeholder={t("type")}
          value={nameValue[selectedLang] || ""}
          onChange={handleNameChange}
        />
        <CustomInput
          label={t("rank")}
          type={"number"}
          row={true}
          showArrows={true}
          className={classes.customInput}
          placeholder={t("number")}
          value={numberValue || numberValue === 0 ? numberValue : ""}
          onChange={handleInputChange}
          span={t("auto_rank_last_if_no_number")}
        />
        <div className={classes.pickerViewContainer}>
          <PickerView
            dataSource={[{ id: "default" }]}
            initialSelectedItems={isDefaultSelected ? [{ id: "default" }] : []}
            onSelectItems={selectItemsHandler}
            pickerCellHeight={20}
            pickerCellImageHeight={20}
            allowMultipleSelections={true}
            pickerViewClassName={classes.pickerView}
            pickerCellClassName={classes.pickerCell}
            isDisabled={editData?.isDefault}
          />
        </div>
      </div>

      <Line />

      <div className={classes.btnActions}>
        <Button
          title={t("btnCancel")}
          className={classes.btnCancel}
          onClick={closeHandler}
        />
        <Button
          type="submit"
          title={t("btnSave")}
          onClick={submitHandler}
          isDisabled={
            !nameValue.en || nameValue.en.trim() === "" ? true : false
          }
        />
      </div>
    </Modal>
  );
}

export default PreferredContentModal;
