import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./LinkContentsModal.module.scss";
import Modal from "../../ui/modal/Modal";
import SearchSortView from "../../ui/searchSort/searchSortView/SearchSortView";
import ContentHelper from "../../../utils/ContentHelper";
import PickerView from "../../pickerViews/pickerView/PickerView";
import Loader from "../../ui/loader/Loader";
import Button from "../../ui/button/Button";
import Line from "../../ui/line/Line";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";

function LinkContentsModal({
  isModalOpen,
  onClose,
  isModalLoading,
  onSubmit,
  title,
  typeData,
  api,
  getOriginalDataSource,
  initialContents,
  isInVerticals,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [contents, setContents] = useState();
  const [selectedItems, setSelectedItems] = useState(initialContents);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  let initialContentIds = new Set(
    initialContents?.map((content) => content.id)
  );

  const originalDataSource = getOriginalDataSource();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await api();
      if (result?.status === 0) {
        setContents(result?.data);
      }
      setIsLoading(false);
    };
    
    originalDataSource?.length > 0
      ? setContents(originalDataSource)
      : fetchData();
    // eslint-disable-next-line
  }, []);

  const changeSearchInputHandler = (value) => {
    setSearchInputValue(value);
  };

  useEffect(() => {
    if (!searchInputValue) {
      setContents(originalDataSource);
      return;
    }
    const timeoutId = setTimeout(() => {
      if (searchInputValue.trim()) {
        setContents(
          originalDataSource?.filter((content) =>
            ContentHelper.getNameByLang(content)
              ?.toLowerCase()
              .includes(searchInputValue.toLowerCase())
          )
        );
      }
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const selectItemshandler = (items) => {
    setSelectedItems(items);
  };

  const submitHandler = () => {
    setIsConfirmationModalOpen(true);
  };

  const onConfirmHandler = () => {
    setIsConfirmationModalOpen(false);
    const contentIdsTobeAdded = selectedItems
      ?.filter((content) => !initialContentIds?.has(content.id))
      .map((content) => content?.id);

    const contentIdsTobeRemoved = initialContents
      ?.filter(
        (content) =>
          !selectedItems.some(
            (selectedContent) => selectedContent.id === content.id
          )
      )
      .map((content) => content?.id);

    if (isInVerticals) {
      // get all selected items only in verticals 
      const selectedContentIdsTobeAdded = selectedItems.map((content) => content?.id);
      onSubmit(selectedContentIdsTobeAdded, contentIdsTobeAdded, contentIdsTobeRemoved);
    } else {
      onSubmit(contentIdsTobeAdded, contentIdsTobeRemoved, selectedItems);
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} className={classes.modal}>
      <h2>{title}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.container}>
            <SearchSortView
              searchInputValue={searchInputValue}
              onChangeSearchInput={changeSearchInputHandler}
              onClearSearchIput={() => setSearchInputValue("")}
            />
            {contents?.length > 0 ? (
              <PickerView
                dataSource={contents}
                grid={true}
                initialSelectedItems={initialContents}
                allowMultipleSelections={true}
                imageName="checkbox-checked"
                onSelectItems={selectItemshandler}
                pickerCellHeight={35}
                pickerCellImageHeight={20}
                pickerCellClassName={classes.pickerCell}
                pickerViewClassName={classes.pickerView}
              />
            ) : (
              <p>{t("no_contents_to_add")}</p>
            )}
          </div>
          <Line className={classes.line} />
          <div className={classes.actionContainer}>
            <Button
              title={t("cancel")}
              onClick={onClose}
              className={`${classes.btnCancel}`}
            />
            {!isModalLoading ? (
              <Button title={t("btnSave")} onClick={submitHandler} />
            ) : (
              <Loader positionNotFixed={true} className={classes.loader} />
            )}
          </div>
          {isConfirmationModalOpen && (
            <ConfirmationModal
              isModalOpen={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              className={classes.confModal}
              text={t("confirm_VODs_addition_to", {
                type: typeData.pickerName,
              })}
              description={t("confirm_content_selection", {
                selection_number: selectedItems.length,
                type: typeData.pickerName,
              })}
              btnConfirmClassName={classes.btnConfirm}
              btnConfirmTitle={t("confirm")}
              btnCancelTitle={t("cancel")}
              onConfirm={onConfirmHandler}
            ></ConfirmationModal>
          )}
        </>
      )}
    </Modal>
  );
}

export default LinkContentsModal;
