import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./LinkChannelWithEPGModal.module.scss";
import Modal from "../../ui/modal/Modal";
// import SearchInput from "../../ui/inputs/searchInput/SearchInput";
import PickerView from "../../pickerViews/pickerView/PickerView";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import channelManager from "../../../backend/managers/ChannelManager";
// import ButtonTitleIcon from "../../ui/buttonTitleIcon/ButtonTitleIcon";
// import ExpandableSelectButton from "../../expandables/expandableSelectButton/ExpandableSelectButton";
import SearchSortView from "../../ui/searchSort/searchSortView/SearchSortView";
import Loader from "../../ui/loader/Loader";
import search from "../../../utils/Search";
import { errorActions } from "../../../store/reducers/Error-slice";

function LinkChannelWithEPGModal({
  isModalOpen,
  onClose,
  isModalLoading,
  onSubmit,
  channelId,
  channelName,
}) {
  const [listChannel, setListChannel] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedLang, setSelectedLang] = useState();
  // const [isDesc, setIsDesc] = useState(false);

  const dispatch = useDispatch();

  const EPG_CHANNELS = channelManager.getEpgChannels();
  const selectedEpgId = channelId
    ? channelManager.getEpgByChannelId(channelId)?.id
    : null;

  const selectChannelHandler = (items) => {
    setSelectedChannels(items);
  };

  // const selectLangHandler = (items) => {
  //   setSelectedLang(items[0]);
  // };

  // const toggleSortingHandler = () => {
  //   setIsDesc((prevState) => !prevState);
  // setListChannel((prevState) =>
  //   channelManager.arrangeData(prevState, !isDesc, "channeldisplayname")
  // );
  // };

  const changeSearchInputHandler = (value) => {
    setSearchInputValue(value);
  };

  const setListChannelByLangHandler = () => {
    EPG_CHANNELS.length && setListChannel(search(EPG_CHANNELS, searchInputValue, ["name"]));
    // setListChannel(
    //   channelManager.getSortedListChannelByLang(
    //     searchInputValue,
    //     selectedLang,
    //     isDesc
    //   )
    // );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setListChannelByLangHandler();
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  // useEffect(() => {
  //   setListChannelByLangHandler();
  //   // eslint-disable-next-line
  // }, [selectedLang]);

  useEffect(() => {
    const fetchEpgChannelListHandler = async () => {
      setIsLoading(true);
      const result = await channelManager.getEpgChannelsApi();
      if (result?.status === 0) {
        setListChannel(result?.data);
      } else {
        dispatch(errorActions.setErrorMessage(result?.message || result));
      }
      setIsLoading(false);
    };
    if (isModalOpen) {
      EPG_CHANNELS.length > 0
        ? setListChannel(EPG_CHANNELS)
        : fetchEpgChannelListHandler();
    }
    setSelectedChannels();
    setSearchInputValue("");
    // setIsDesc(false);
    // setSelectedLang();

    // eslint-disable-next-line
  }, [isModalOpen]);

  // const options = channelManager
  //   .getLanguagesOfChannelList()
  //   .filter((l) => l !== "all")
  //   .map((lang) => {
  //     return { id: lang, lang: lang };
  //   });

  const submitHandler = () => {
    if (!channelId) {
      //link channel epg
      selectedChannels?.length > 0 && onSubmit(selectedChannels[0]?.id);
    } else {
      //change channel epg link
      selectedChannels &&
        selectedChannels[0]?.id !== selectedEpgId &&
        onSubmit(selectedChannels[0]?.id);
    }
  };

  return (
    <Modal
      className={classes.modal}
      backdropClassName={classes.backdrop}
      isOpen={isModalOpen}
      onClose={onClose}
      top="1rem"
    >
      <h2>{t("link_channel_with_epg", { channel: channelName })}</h2>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <Loader className={classes.loader} positionNotFixed={true} />
        </div>
      ) : (
        <>
          <div className={classes.row}>
            <SearchSortView
              searchInputPlaceholder={t("search_channel")}
              searchInputValue={searchInputValue}
              onChangeSearchInput={changeSearchInputHandler}
              onClearSearchIput={() => setSearchInputValue("")}
            />
            {/* <SearchInput
          placeholder={t("search_channel")}
          value={searchInputValue}
          onChangeInput={changeSearchInputHandler}
          onClear={() => setSearchInputValue("")}
        /> 
        <ButtonTitleIcon
          className={classes.buttonTitleIcon}
          onClick={toggleSortingHandler}
          title={t("name")}
          imagePath={`icons/arrow-${!isDesc ? "up" : "down"}`}
        />
        <ExpandableSelectButton
          title={t("languages")}
          className={classes.buttonTitleIcon}
          expCardClassName={classes.expCard}
        >
          <PickerView
            dataSource={options}
            pickerCellHeight={30}
            pickerCellImageHeight={20}
            pickerCellClassName={classes.option}
            onSelectItems={selectLangHandler}
            initialSelectedItems={[selectedLang ? selectedLang : ""]}
          />
        </ExpandableSelectButton> */}
          </div>
          <PickerView
            pickerViewClassName={classes.pickerView}
            pickerCellClassName={classes.pickerCell}
            dataSource={listChannel}
            pickerCellHeight={40}
            pickerCellImageHeight={20}
            allowMultipleSelections={false}
            onSelectItems={selectChannelHandler}
            unchangedInitialSelectedItems={
              selectedEpgId ? [{ id: selectedEpgId }] : []
            }
          />
          <Line />
          <div className={classes.actionContainer}>
            {!isModalLoading ? (
              <Button title={t("btnSave")} onClick={submitHandler} />
            ) : (
              <Loader positionNotFixed={true} className={classes.loader} />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}

export default LinkChannelWithEPGModal;
