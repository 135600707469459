import { t } from "i18next";

export const validateEmail = (email) => {
  if (!email) {
    return t("error_email_required");
  }

  // Regular expression for validating email addresses
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the regular expression
  if (!re.test(email)) {
    return t("error_invalid_email");
  }

  return null;
};

export const validatePassword = (
  password,
  pattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/
) => {
  const re = new RegExp(pattern);
  if (!re.test(password)) {
    return false;
  }
  return null;
};

export const validateNewAndConfirmedPassword = (
  newPassword,
  confirmedPassword
) => {
  if (!newPassword || !confirmedPassword) {
    return t("error_invalid_inputs");
  }
  if (validatePassword(newPassword) === false) {
    return t("error_invalid_password");
  }
  if (newPassword !== confirmedPassword) {
    return t("error_passwords_not_matched");
  }

  return null;
};
