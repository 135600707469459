/**
 * Search function to filter data in a data source based on specific fields and input value.
 * @param {Array} dataSource - Array of objects representing the data source to be searched.
 * @param {string} inputValue - Input value to search within the data source.
 * @param {Array} fields - Array of strings representing the fields to search within the data source.
 * @returns {Array} - Filtered array containing objects matching the search criteria or the entire data source if inputValue is empty.
 */
export default function search(dataSource, inputValue, fields) {
  // If inputValue is empty or null, return the entire data source
  if (!inputValue) {
    return dataSource; // Display all terminals when inputValue is null, undefined, or an empty string
  } else {
    // Filter the dataSource based on inputValue and specified fields
    const matchCells = dataSource.filter((cell) => {
      // Convert input value and cell values to lowercase for case-insensitive search
      const lowercaseInput = inputValue.toLowerCase().trim();
      return fields.some((field) => {
        const cellValue = String(cell[field]).toLowerCase();
        // Check if cell value includes the lowercase input value
        return cellValue.includes(lowercaseInput);
      });
    });

    // Return matched cells if found, otherwise return an empty array
    return matchCells.length > 0 ? matchCells : [];
  }
}
