import { useRef } from "react";
import { Editor, RichUtils } from "draft-js";

import classes from "./TextEditor.module.scss";
import Line from "../line/Line";
import ButtonIcon from "../buttonIcon/ButtonIcon";

function TextEditor({ editorState, setEditorState, placeholder }) {
  const ref = useRef();

  const keyCommandHandler = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onBoldClickHandler = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClickHandler = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const onUnderlineClickHandler = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onOrderedListClickHandler = () => {
    setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
  };

  const onUnorderedListClickHandler = () => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
  };

  const onEditorChangeHandler = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <div className={classes.textEditor}>
      <div className={classes.toolbar}>
        <ButtonIcon
          className={classes.btnBold}
          imagePath={"icons/bold"}
          isSvg={true}
          onClick={onBoldClickHandler}
        />
        <ButtonIcon
          className={classes.btn}
          imagePath={"icons/italic"}
          isSvg={true}
          onClick={onItalicClickHandler}
        />
        <ButtonIcon
          className={classes.btn}
          imagePath={"icons/underlined"}
          isSvg={true}
          onClick={onUnderlineClickHandler}
        />
        <div className={classes.verticalLine} />
        <ButtonIcon
          className={classes.btn}
          imagePath={"icons/unordered-list"}
          isSvg={true}
          onClick={onUnorderedListClickHandler}
        />
        <ButtonIcon
          className={classes.btn}
          imagePath={"icons/ordered-list"}
          isSvg={true}
          onClick={onOrderedListClickHandler}
        />
      </div>
      <Line />
      <div className={classes.editor} onClick={() => ref.current?.focus()}>
        <Editor
          editorState={editorState}
          handleKeyCommand={keyCommandHandler}
          onChange={onEditorChangeHandler}
          placeholder={placeholder}
          ref={ref}
        />
      </div>
    </div>
  );
}

export default TextEditor;
