import { useState, useMemo, useRef } from "react";
import { t } from "i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import classes from "./FAQModal.module.scss";
import Modal from "../../ui/modal/Modal";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import ButtonTitleIcon from "../../ui/buttonTitleIcon/ButtonTitleIcon";
import LanguagesList from "../../languagesList/LanguagesList";
import ExpandableMenu from "../../expandables/expandableMenu/ExpandableMenu";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import TextEditor from "../../ui/textEditor/TextEditor";

import useElementWidth from "../../../hooks/useElementWidth";

function FAQModal({
  isModalOpen,
  onClose,
  onSubmit,
  title,
  faqLength,
  editData,
  expandedIndex,
  addingNewFAQSubsection,
}) {
  const [selectedLang, setSelectedLang] = useState("en");

  const generateUniqueId = () => {
    return `id-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  const initialSectionTitle = useMemo(() => {
    if (editData && editData.title) {
      return {
        en: editData.title.en || "",
        ar: editData.title.ar || "",
      };
    }
    return { en: "", ar: "" };
  }, [editData]);

  const initialSectionLabel = useMemo(() => {
    if (editData && editData.title && expandedIndex) {
      return `${t("section")}: ${initialSectionTitle[selectedLang]}`;
    }
    return t("section");
  }, [editData, selectedLang, t]);

  const [sectionTitle, setSectionTitle] = useState(initialSectionTitle);
  const [sectionLabel, setSectionLabel] = useState(initialSectionLabel);

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const modalRef = useRef();

  const [subsectionsList, setSubsectionsList] = useState(() => {
    if (editData) {
      const clonedEditData = JSON.parse(JSON.stringify(editData)); // deep copy editData
      const initializedSubsections = clonedEditData.subSections.map(
        (subsection, index) => {
          const details = {};
          Object.keys(subsection.details).forEach((lang) => {
            const detailContent =
              typeof subsection.details[lang] === "string"
                ? subsection.details[lang]
                : "";
            const blocksFromHtml = htmlToDraft(detailContent);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            details[lang] = EditorState.createWithContent(contentState);
          });

          return {
            id: subsection.id,
            title: subsection.title,
            details: details,
            label:
              index !== expandedIndex
                ? {
                    en: subsection.title.en || "",
                    ar: subsection.title.ar || "",
                  }
                : { en: "", ar: "" },
            order: subsection.subSectionOrder,
          };
        }
      );
      if (addingNewFAQSubsection)
        initializedSubsections.push({
          id: generateUniqueId(),
          title: { en: "", ar: "" },
          details: {
            en: EditorState.createEmpty(),
            ar: EditorState.createEmpty(),
          },
          label: { en: "", ar: "" },
          order: initializedSubsections.length + 1,
        });
      return initializedSubsections;
    } else {
      return [
        {
          id: generateUniqueId(),
          title: { en: "", ar: "" },
          details: {
            en: EditorState.createEmpty(),
            ar: EditorState.createEmpty(),
          },
          label: { en: "", ar: "" },
          order: 1,
        },
      ];
    }
  });

  const closeHandler = () => {
    onClose();
  };

  const submitHandler = () => {
    let orderCounter = 1;
    let subSections = []; // store newly added subsections - add new section
    let newSubSections = []; // store newly added subsections - edit section
    let updatedSubSections = []; // store updated sections - edit section
    let deletedSubSections = []; // store deleted sections - edit section

    // Create a set of existing subsection IDs for quick lookup

    const existingSubsectionIds = new Set(
      editData ? editData.subSections.map((sub) => sub.id) : []
    );

    for (let subsection of subsectionsList) {
      // Extract details and convert to HTML
      let details = {};
      let hasContent = false;
      Object.keys(subsection.details).forEach((lang) => {
        const values = subsection.details;
        const contentState = values[lang].getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const htmlContent = draftToHtml(rawContent);
        details = { ...details, [lang]: htmlContent };

        if (
          lang === "en" &&
          rawContent.blocks.some((block) => block.text.trim() !== "")
        ) {
          hasContent = true;
        }
      });

      if (subsection.title["en"].trim() !== "" && hasContent) {
        const subsectionData = {
          title: subsection.title,
          details: details,
          order: orderCounter,
        };

        if (editData) {
          if (existingSubsectionIds.has(subsection.id)) {
            // If subsection ID exists in editData, it's an update
            updatedSubSections.push({
              subSectionId: subsection.id,
              ...subsectionData,
            });
          } else {
            // Otherwise, it's a new subsection
            newSubSections.push(subsectionData);
          }
        } else {
          subSections.push(subsectionData);
        }

        orderCounter++;
      }
    }

    // Determine deleted subsections
    if (editData) {
      const currentSubsectionIds = new Set(
        subsectionsList.map((sub) => sub.id)
      );
      deletedSubSections = editData.subSections
        .filter((sub) => !currentSubsectionIds.has(sub.id))
        .map((sub) => sub.id);
    }

    const request = editData
      ? {
          id: editData.id,
          title: sectionTitle,
          newSubSections: newSubSections,
          updatedSubSections: updatedSubSections,
          deletedSubSections: deletedSubSections,
        }
      : {
          title: sectionTitle,
          subSections: subSections,
          order: faqLength + 1,
        };

    onSubmit(request, !editData ? true : false);
    onClose();
  };

  const addNewSubsectionHandler = () => {
    setSubsectionsList((prevSubsections) => {
      const newOrder = prevSubsections.length + 1;
      return [
        ...prevSubsections,
        {
          id: generateUniqueId(),
          title: { en: "", ar: "" },
          details: {
            en: EditorState.createEmpty(),
            ar: EditorState.createEmpty(),
          },
          label: { en: "", ar: "" },
          order: newOrder,
        },
      ];
    });
  };

  const removeSubsectionHandler = (e, id) => {
    e.stopPropagation();
    setSubsectionsList((prevSubsections) => {
      const updatedSubsections = prevSubsections
        .filter((subsection) => subsection.id !== id)
        .map((subsection, index) => ({
          ...subsection,
          order: index + 1, // Reorder subsections
        }));
      return updatedSubsections;
    });
  };

  const toggleSectionHandler = (expanded) => {
    const title = sectionTitle[selectedLang]
      ? `: ${sectionTitle[selectedLang]}`
      : "";
    setSectionLabel(expanded ? t("section") : `${t("section")} ${title}`);
  };

  const toggleSubsectionHandler = (expanded, index) => {
    setSubsectionsList((prevSubsections) => {
      const newSubsections = [...prevSubsections];
      newSubsections[index] = {
        ...newSubsections[index],
        label: {
          ...newSubsections[index].label,
          [selectedLang]: expanded
            ? ""
            : newSubsections[index].title[selectedLang] || "",
        },
      };
      return newSubsections;
    });
  };

  const handleSectionTitleChange = (e) => {
    setSectionTitle((prevTitles) => ({
      ...prevTitles,
      [selectedLang]: e.target.value,
    }));
  };

  const handleSubsectionTitleChange = (index, content) => {
    setSubsectionsList((prevSubsections) => {
      const newSubsections = [...prevSubsections];
      newSubsections[index] = {
        ...newSubsections[index],
        title: {
          ...newSubsections[index].title,
          [selectedLang]: content,
        },
      };
      return newSubsections;
    });
  };

  const handleEditorStateChange = (index, newState) => {
    setSubsectionsList((prevSubsections) => {
      const newSubsections = [...prevSubsections];
      newSubsections[index] = {
        ...newSubsections[index],
        details: {
          ...newSubsections[index].details,
          [selectedLang]: newState,
        },
      };
      return newSubsections;
    });
  };

  const handleDragEnd = (result) => {
    const { source, destination, type } = result;
    if (!destination) return;
    const reorderedSubsections = Array.from(subsectionsList);
    const [movedSubsection] = reorderedSubsections.splice(source.index, 1);
    reorderedSubsections.splice(destination.index, 0, movedSubsection);

    // Update order based on new position
    const updatedSubsections = reorderedSubsections.map(
      (subsection, index) => ({
        ...subsection,
        order: index + 1,
      })
    );

    setSubsectionsList(updatedSubsections);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeHandler}
      className={classes.modal}
      top={"0.5rem"}
    >
      <h2>{title}</h2>

      <div className={classes.langContainer}>
        {divWidth && (
          <LanguagesList
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            width={divWidth - 48}
          />
        )}
      </div>

      <div className={classes.wrapper} ref={ref}>
        <div className={classes.sectionContainer}>
          <ExpandableMenu
            key={"section"}
            title={sectionLabel}
            expandableClassName={classes.expandableMenu}
            toggleHandler={toggleSectionHandler}
            initialExpandedValue={expandedIndex ? false : true}
          >
            <div className={classes.inputWrapper}>
              <CustomInput
                className={classes.customInput}
                placeholder={t("enter_section_name")}
                value={sectionTitle[selectedLang]}
                onChange={handleSectionTitleChange}
              />
            </div>
          </ExpandableMenu>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="subsections">
            {(provided) => (
              <div
                className={classes.subsectionsContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {subsectionsList &&
                  subsectionsList.length > 0 &&
                  subsectionsList.map((subsection, index) => (
                    <Draggable
                      key={subsection.id}
                      draggableId={subsection.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const offset =
                          modalRef.current?.getBoundingClientRect().top ?? 0;
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              left: "auto !important",
                              top: snapshot.isDragging
                                ? provided.draggableProps.style?.top - offset
                                : "auto !important",
                            }}
                            className={classes.draggable}
                          >
                            <ExpandableMenu
                              key={subsection.id}
                              index={index}
                              title={`${t("subsection_key", {
                                key: index + 1,
                              })}${
                                subsection.label[selectedLang] &&
                                subsection.label[selectedLang] !== ""
                                  ? ": " + subsection.label[selectedLang]
                                  : ""
                              }`}
                              expandableClassName={classes.expandableMenu}
                              toggleHandler={toggleSubsectionHandler}
                              provided={provided}
                              initialExpandedValue={
                                editData ? index === expandedIndex : index === 0
                              }
                              isDraggable={true}
                              iconsList={[
                                {
                                  id: "delete",
                                  imagePath: "icons/delete-purple-circle",
                                  activeImagePath:
                                    "icons/delete-purple-fill-circle",
                                  onClick: (e) =>
                                    removeSubsectionHandler(e, subsection.id),
                                },
                              ]}
                            >
                              <div className={classes.inputWrapper}>
                                <CustomInput
                                  className={classes.customInput}
                                  placeholder={t("enter_section_name")}
                                  value={subsection.title[selectedLang]}
                                  onChange={(e) =>
                                    handleSubsectionTitleChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                                <TextEditor
                                  editorState={subsection.details[selectedLang]}
                                  setEditorState={(newState) =>
                                    handleEditorStateChange(index, newState)
                                  }
                                />
                              </div>
                            </ExpandableMenu>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className={classes.btnContainer}>
        <ButtonTitleIcon
          title={t("add_new_subsection")}
          imagePath="icons/add-purple"
          className={classes.addSubsectionBtn}
          onClick={addNewSubsectionHandler}
          position={"right"}
        />
      </div>

      <Line />

      <div className={classes.btnActions}>
        <Button
          title={t("btnCancel")}
          className={classes.btnCancel}
          onClick={closeHandler}
        />
        <Button
          type="submit"
          title={t("btnSave")}
          isDisabled={sectionTitle && sectionTitle.en ? false : true}
          onClick={submitHandler}
        />
      </div>
    </Modal>
  );
}

export default FAQModal;
