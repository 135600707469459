import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import classes from "./ErrorPage.module.scss";
import Button from "../../components/ui/button/Button";

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      <h1>404</h1>
      <h2>{t("page_not_found")}</h2>
      <p>{t("page_not_found_desccription")}</p>
      <Button
        title={t("back")}
        className={classes.btn}
        onClick={() => navigate(-1)}
      />
    </div>
  );
}

export default ErrorPage;
