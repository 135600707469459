import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./Packages.module.scss";
import PageHeaderSearchSort from "../../../../components/ui/searchSort/pageHeaderSearchSort/PageHeaderSearchSort";
import PackageCard from "../../../../components/contentCards/packageCard/PackageCard";
import useFetchData from "../../../../hooks/useFetchData";
import packageManager from "../../../../backend/managers/PackageManager";
import Loader from "../../../../components/ui/loader/Loader";
import PackageContents from "./PackageContents";

function Packages() {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [selectedPackage, setSelectedPackage] = useState();

  const { data, isLoading: loading } = useFetchData(
    packageManager.getPackagesApi
  );

  useEffect(() => {
    setPackages(data);
    setIsLoading(loading);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!searchInputValue) {
      setPackages(data);
      return;
    }
    const timeoutId = setTimeout(() => {
      setPackages(packageManager.searchLocally(searchInputValue));
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const onBackHandler = () => {
    setSelectedPackage(null);
    setSearchInputValue("");
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PageHeaderSearchSort
            pageTitle={t("offer")}
            secondPageTitle={
              selectedPackage
                ? selectedPackage?.sku_name || selectedPackage?.skuName
                : null
            }
            onBack={onBackHandler}
            searchInputValue={searchInputValue}
            searchInputPlaceholder={t("searchPackage")}
            onClearSearchIput={() => setSearchInputValue("")}
            onChangeSearchInput={(value) => setSearchInputValue(value)}
          />
          {!selectedPackage ? (
            <>
              {packages.map((section) => (
                <section key={section?.id} className={classes.section}>
                  {section?.id !== "offers" && (
                    <h2 className={classes.sectionTitle}>{t(section?.id)}</h2>
                  )}
                  <ul className={classes.list}>
                    {section?.content?.map((c) => (
                      <PackageCard
                        key={c.planId}
                        packageContent={c}
                        onClick={() => {
                          setSelectedPackage(c);
                          setSearchInputValue("");
                        }}
                      />
                    ))}
                  </ul>
                </section>
              ))}
            </>
          ) : (
            <PackageContents
              selectedPackage={selectedPackage}
              searchInputValue={searchInputValue}
            />
          )}
        </>
      )}
    </>
  );
}

export default Packages;
