const SORT_OPTIONS = [
  { id: "a_to_z" },
  { id: "z_to_a" },
  { id: "least_subscriptions" },
  { id: "most_subscriptions" },
];

class SortHelper {
  static getSortOptions() {
    return SORT_OPTIONS;
  }

  static getContentSortOptions() {
    return SORT_OPTIONS.slice(0, 2);
  }

  static arrangeData(datasource, isDesc, field, secondField) {
    return datasource?.sort((a, b) => {
      const e1 = secondField ? a[field][secondField] : a[field];
      const e2 = secondField ? b[field][secondField] : b[field];
      return isDesc ? (e1 > e2 ? -1 : 1) : e1 < e2 ? -1 : 1;
    });
  }

  static sortData(dataSource, selectedSortTypeId, nameField) {
    let isDesc = false,
      field = nameField ? nameField : "pickerName";
    switch (selectedSortTypeId) {
      case "a_to_z":
        isDesc = false;
        field = nameField ? nameField : "pickerName";
        break;
      case "z_to_a":
        isDesc = true;
        field = nameField ? nameField : "pickerName";
        break;
      case "least_subscriptions":
        isDesc = false;
        field = "totalSubscriptions";
        break;
      case "most_subscriptions":
        isDesc = true;
        field = "totalSubscriptions";
        break;
      // case "higher_rank":
      //   isDesc = true;
      //   field = "totalSubscriptions";
      //   break;
      default:
        isDesc = false;
        field = nameField ? nameField : "pickerName";
        break;
    }
    return this.arrangeData(dataSource, isDesc, field);
  }
}

export default SortHelper;
