import { useState, useEffect } from "react";
import { t } from "i18next";
import { useDispatch } from "react-redux";

import classes from "./Preferences.module.scss";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import Loader from "../../../../components/ui/loader/Loader";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import AddButton from "../../../../components/ui/addButton/AddButton";
import PreferredContent from "../../../../components/preferences/preferredContent/PreferredContent";
import StoreLocation from "../../../../components/preferences/storeLocation/StoreLocation";
import PreferredContentModal from "../../../../components/modals/preferredContentModal/PreferredContentModal";
import StoreLocationModal from "../../../../components/modals/storeLocationModal/StoreLocationModal";
import preferredContentManager from "../../../../backend/managers/preferences/PreferredContenManager";
import storeLocationManager from "../../../../backend/managers/preferences/StoreLocationManager";
import Toast from "../../../../components/toast/Toast";
import ContentHelper from "../../../../utils/ContentHelper";
import { errorActions } from "../../../../store/reducers/Error-slice";

const PREFERENCES = [{ id: "preferredContents" }, { id: "store_location" }];

function Preferences() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(PREFERENCES[0]);
  const [searchInputValue, setSearchInputValue] = useState("");

  const [isAddStoreModalOpen, setIsAddStoreModalOpen] = useState(false);
  const [isPreferredContentModalOpen, setIsPreferredContentModalOpen] =
    useState(false);

  const [preferredContentList, setPreferredContentList] = useState([]);
  const [editPreferredContentData, setEditPreferredContentData] =
    useState(null);

  const [storeLocations, setStoreLocations] = useState([]);
  const [editStoreLocationData, setEditStoreLocationData] = useState();

  const [isCardLoading, setIsCardLoading] = useState(false);

  const [toastNotification, setToastNotification] = useState({});

  const dispatch = useDispatch();
  const originalPreferredContentList =
    preferredContentManager.getOriginalPreferredContents(); // get original preferred content list

  const originalStoreLocations =
    storeLocationManager.getOriginalStoreLocations(); // get original store loacations list

  const openAddModal = () => {
    switch (selectedCategory?.id) {
      case "preferredContents":
        setEditPreferredContentData(null);
        setIsPreferredContentModalOpen(true);
        break;
      case "store_location":
        setIsAddStoreModalOpen(true);
        break;
      default:
        setIsPreferredContentModalOpen(true);
        break;
    }
  };

  /********************** FETCH DATA FUNCTIONS  **********************/

  const callApi = async (api, data, setState) => {
    setIsCardLoading(true);
    const result = await api(data);
    if (result?.status === 0) {
      if (setState) {
        setState(result.data);
      }
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsCardLoading(false);
  };

  const callApiShowingToast = async (api, data, notificationObj) => {
    setIsCardLoading(true);
    const result = await api(data);
    if (result?.status === 0) {
      if (selectedCategory?.id === "store_location") {
        getStoreLocations();
      } else if (selectedCategory?.id === "preferredContents") {
        getPreferredContents();
      }
      setToastNotification(notificationObj);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  /********************** STORE LOCATIONS **********************/
  const getStoreLocations = () => {
    callApi(storeLocationManager.getStoreLocationsApi, {}, setStoreLocations);
  };

  const openEditEmirateModalHandler = (data, type, townEmirate) => {
    setIsAddStoreModalOpen(true);
    setEditStoreLocationData({ type, data, townEmirate });
  };

  const editEmirateHandler = async (data, type) => {
    const countWithFakeId = data.areas?.filter((item) => item.fakeId).length;
    const toastMessageText =
      type && type === "town"
        ? t("toast_update_content_success")
        : ContentHelper.getNameByLang(data) +
          ": " +
          countWithFakeId +
          " town/s have been created.";
    const api =
      type && type === "town"
        ? storeLocationManager.updateAreaApi
        : storeLocationManager.updateEmirateApi;
    setIsAddStoreModalOpen(false);
    setEditStoreLocationData();
    callApiShowingToast(api, data, {
      messageText: toastMessageText,
      type: "info-green",
      isDisplayed: true,
    });
  };

  const deleteStoreLocationHandler = (selectedItem) => {
    const api =
      selectedItem?.type === "emirate"
        ? storeLocationManager.deleteEmirateApi
        : storeLocationManager.deleteAreaApi;

    callApiShowingToast(api, selectedItem?.data?.id, {
      messageText:
        selectedItem?.type === "emirate"
          ? t("toast_delete_emirate_success", {
              emirate: ContentHelper.getNameByLang(selectedItem?.data),
              length: selectedItem?.data?.areas?.length,
            })
          : t("toast_delete_town_success", {
              town: ContentHelper.getNameByLang(selectedItem?.data),
            }),
      type: "warning",
      isDisplayed: true,
      undo: () => restoreLocationHandler(selectedItem),
    });
  };

  const restoreLocationHandler = (selectedItem) => {
    const api =
      selectedItem?.type === "emirate"
        ? storeLocationManager.restoreEmirateApi
        : storeLocationManager.restoreAreaApi;

    callApiShowingToast(api, selectedItem?.data?.id);
  };

  /********************** PREFERRED CONTENT **********************/
  const getPreferredContents = () => {
    callApi(preferredContentManager.getPreferredContentsApi, {}, (data) => {
      // Sort the data by rank before setting the state
      const sortedData = data.sort((a, b) => a.rank - b.rank);
      setPreferredContentList(sortedData);
    });
  };

  const createPreferredContentHandler = (data) => {
    callApiShowingToast(
      preferredContentManager.createPreferredContentsApi,
      data,
      {
        messageText: t("toast_create_preferred_content_success"),
        type: "info-green",
        isDisplayed: true,
      }
    );
  };

  const editPreferredContentHandler = (item) => {
    setEditPreferredContentData(item);
    setIsPreferredContentModalOpen(true);
  };

  const updatePreferredContentHandler = (data) => {
    callApiShowingToast(
      preferredContentManager.updatePreferredContentsApi,
      data,
      {
        messageText: t("toast_update_preferred_content_success"),
        type: "info-green",
        isDisplayed: true,
        id: data.elementId,
      }
    );
  };

  const deletePreferredContentHandler = (id) => {
    callApiShowingToast(
      preferredContentManager.deletePreferredContentsApi,
      id,
      {
        messageText: t("toast_delete_preferred_content_success"),
        type: "warning",
        isDisplayed: true,
        undo: () => restorePreferredContentHandler(id),
      }
    );
  };

  const restorePreferredContentHandler = (id) => {
    callApiShowingToast(
      preferredContentManager.restorePreferredContentsApi,
      id
    );
  };

  const reorderPreferredContentHandler = async (contentList) => {
    const result = await preferredContentManager.reorderPreferredContentsApi(
      contentList
    );
    if (result?.status === 0) {
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  /********************** USEEFFECTS **********************/
  useEffect(() => {
    setSearchInputValue("");
    async function fetchData() {
      if (selectedCategory?.id === "preferredContents") {
        getPreferredContents();
      } else if (selectedCategory?.id === "store_location") {
        getStoreLocations();
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory?.id === "preferredContents") {
      if (searchInputValue.trim() === "") {
        setPreferredContentList(originalPreferredContentList);
      } else {
        const filteredList = preferredContentManager.searchManager(
          originalPreferredContentList,
          searchInputValue,
          "values",
          "en"
        );
        setPreferredContentList(filteredList);
      }
    }
    if (selectedCategory?.id === "store_location") {
      if (searchInputValue.trim() === "") {
        setStoreLocations(originalStoreLocations);
      } else {
        const filteredList = preferredContentManager.searchManager(
          originalStoreLocations,
          searchInputValue,
          "name",
          "en"
        );
        setStoreLocations(filteredList);
      }
    }
  }, [searchInputValue]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <MenuCardLayout pageTitle={t("preferences")} noDisplayAll={true}>
        <></>
        {PREFERENCES.map((category) => {
          return (
            <CategoryItem
              key={category?.id}
              id={category?.id}
              name={t(category?.id)}
              selectedCategoryId={selectedCategory?.id}
              onClick={() => setSelectedCategory(category)}
            />
          );
        })}
        <>
          <div className={classes.headerContainer}>
            <SearchSortView
              searchInputValue={searchInputValue}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={(value) => setSearchInputValue(value)}
            />
            <div className={classes.actionsContainer}>
              <AddButton
                onClick={() => openAddModal()}
                isDisabled={
                  selectedCategory?.id === "store_location" &&
                  originalStoreLocations.length === 0? true:false
                }
              />
            </div>
          </div>
          {selectedCategory?.id === "preferredContents" ? (
            <PreferredContent
              dataSource={preferredContentList}
              setPreferredContentList={setPreferredContentList}
              isCardLoading={isCardLoading}
              setIsCardLoading={setIsCardLoading}
              searchInputValue={searchInputValue}
              setIsPreferredContentModalOpen={setIsPreferredContentModalOpen}
              handleDeleteContent={deletePreferredContentHandler}
              handleEditContent={editPreferredContentHandler}
              handleReorderContents={reorderPreferredContentHandler}
            />
          ) : (
            <StoreLocation
              dataSource={storeLocations}
              isCardLoading={isCardLoading}
              setIsCardLoading={setIsCardLoading}
              onOpenEditModal={openEditEmirateModalHandler}
              searchInputValue={searchInputValue}
              onDeleteStoreLocation={deleteStoreLocationHandler}
            />
          )}
        </>
      </MenuCardLayout>
      {isAddStoreModalOpen && (
        <StoreLocationModal
          isModalOpen={isAddStoreModalOpen}
          onClose={() => {
            setIsAddStoreModalOpen(false);
            setEditStoreLocationData();
          }}
          dataSource={storeLocations}
          onSave={editEmirateHandler}
          itemData={editStoreLocationData}
        />
      )}
      {isPreferredContentModalOpen && (
        <PreferredContentModal
          title={
            editPreferredContentData
              ? t("edit_content_type")
              : t("create_new_content_type")
          }
          isModalOpen={isPreferredContentModalOpen}
          onClose={() => setIsPreferredContentModalOpen(false)}
          editData={editPreferredContentData}
          preferredContentList={preferredContentList}
          onSubmit={(data) => {
            if (data?.elementId) {
              updatePreferredContentHandler(data);
            } else {
              createPreferredContentHandler(data);
            }
          }}
        />
      )}

      {toastNotification && toastNotification?.isDisplayed && (
        <Toast
          messageText={toastNotification?.messageText}
          type={toastNotification?.type}
          isDisplayed={toastNotification?.isDisplayed}
          onDisplay={(isDisplayed) =>
            setToastNotification((prevState) => ({
              ...prevState,
              isDisplayed: isDisplayed,
            }))
          }
          onUndo={toastNotification.undo && toastNotification.undo}
        />
      )}
    </>
  );
}

export default Preferences;
