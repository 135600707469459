import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import SigninLayout from "../../components/signinLayout/SigninLayout";
import authManager from "../../backend/managers/AuthManager";
import { validateNewAndConfirmedPassword } from "../../utils/Validators";

function ResetPassword() {
  const navigate = useNavigate();

  const validateForm = (credentials) => {
    const { newPassword, confirmedPassword } = credentials;
    return validateNewAndConfirmedPassword(newPassword, confirmedPassword);
  };

  const submitFormHandler = async (credentials) => {
    const result = await authManager.resetPasswordApi({
      password: credentials?.newPassword,
      passwordConfirmation: credentials?.confirmedPassword,
    });
    if (result.status === 0) {
      navigate("/2FA");
    }
    return result;
  };

  return (
    <SigninLayout
      initialState={{ newPassword: "", confirmedPassword: "" }}
      title={t("signTitle")}
      input1={{
        id: "newPassword",
        label: t("labelNewPassword"),
        placeholder: t("labelPassword"),
        type: "password",
        span: t("spanPasswordConstraints"),
      }}
      input2={{
        id: "confirmedPassword",
        label: t("labelConfirmPassword"),
        placeholder: t("labelPassword"),
        type: "password",
      }}
      validateForm={validateForm}
      onSubmitForm={submitFormHandler}
    />
  );
}

export default ResetPassword;
