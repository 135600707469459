import { Chart } from "react-google-charts";

const BarChart = ({ data, title, xAxisTitle, yAxisTitle, colors }) => {
  const options = {
    title: title,
    chartArea: { width: "60%" },
    hAxis: {
      title: xAxisTitle,
      minValue: 0,
    },
    vAxis: {
      title: yAxisTitle,
    },
    legend: { position: "none" },
    colors: colors || ["#1fd6b9"], // Default color if not provided
  };

  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default BarChart;
