import { useState } from "react";
import classes from "./InputSign.module.scss";
import CustomInput from "../customInput/CustomInput";

/**
 * InputSign Component
 * @param {Object} props - React props containing the following:
 * @param {string} label - Label for the input.
 * @param {string} placeholder - Placeholder text for the input.
 * @param {boolean} row - Flag for displaying input in a row.
 * @param {string} type - Type of input (e.g., text, password).
 * @param {Function} onChange - Function to handle input changes.
 * @param {string} id - Unique identifier for the input.
 * @param {string} value - Current value of the input.
 * @param {boolean} isValid - Flag indicating if the input value is valid.
 * @param {string} span - Span value for the input (optional).
 * @param {string} className - Additional CSS classes for styling (optional).
 * @returns {JSX.Element} - Rendered React component for an input field with an eye icon for showing/hiding password.
 */
function InputSign({
  label,
  placeholder,
  row,
  type,
  onChange,
  id,
  value,
  isValid,
  span,
  className,
  inputClassName,
  disabled,
  onKeyDown
}) {
  const [showPassword, setShowPassword] = useState(false);

  // Apply 'invalid' class if isValid is false
  const invalidClassName = !isValid ? classes.invalid : "";

  // Function to toggle showing/hiding password
  const togglePasswordHandler = (e) => {
    e.preventDefault();
    setShowPassword((prevState) => !prevState);
  };

  return (
    <CustomInput
      className={`${className} ${invalidClassName}`}
      btnClassName={classes.icon}
      inputClassName={`${inputClassName} ${invalidClassName}`}
      type={showPassword ? "text" : type}
      id={id}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(id, e.target.value)}
      // Add eye icon if input type is 'password'
      hasIcon={type === "password" && true}
      // Toggle eye icon based on showPassword state
      imagePath={
        type === "password" && showPassword
          ? "icons/eye-hide"
          : "icons/eye-show"
      }
      onClick={togglePasswordHandler} // Handle click on eye icon
      row={row}
      span={span}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  );
}

export default InputSign;
