import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./Users.module.scss";
import PageHeader from "../../../components/pageHeader/PageHeader";
import Logger from "../../../utils/Logger";
import UserCard from "../../../components/contentCards/userCard/UserCard";
import AccountModal from "../../../components/modals/accountModal/AccountModal";
import Toast from "../../../components/toast/Toast";
import useFetchData from "../../../hooks/useFetchData";
import accountManager from "../../../backend/managers/AccountManager";
import Loader from "../../../components/ui/loader/Loader";

function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showExpandableInput, setShowExpandableInput] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);

  const usersNb = users.length;
  const readOnly = false;

  const {
    data,
    isLoading: loading,
    onRefresh,
  } = useFetchData(accountManager.getUsersApi);

  useEffect(() => {
    setUsers(data);
    setIsLoading(loading);
    // eslint-disable-next-line
  }, [data]);

  const openExpandableInputHandler = () => {
    setShowExpandableInput(true);
  };

  const closeExpandableInputHandler = () => {
    setShowExpandableInput(false);
    setUsers(data);
  };

  const openSearchExpandableInput = () => {
    return { placeholder: t("search"), value: "" };
  };

  const searchHandler = (inputValue) => {
    setSearchInputValue(inputValue);
  };

  useEffect(() => {
    if (!searchInputValue) {
      setUsers(data);
      return;
    }
    const searchData = async () => {
      try {
        if (searchInputValue.trim()) {
          setUsers(accountManager.searchUsers(searchInputValue));
        }
      } catch (error) {
        Logger.error("search users", error);
      }
    };
    const timeoutId = setTimeout(() => {
      searchData();
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const openAccountModalHandler = (isEditing, account) => {
    setIsAccountModalOpen(true);
    setIsEditing(isEditing);
    setSelectedAccount(account);
  };

  const pageHeaderButtons = [
    {
      id: "search",
      image: "search",
      expandable: true,
      action: openSearchExpandableInput,
    },
    {
      id: "add",
      action: () => openAccountModalHandler(false, null),
      hidden: readOnly ? true : false,
    },
  ];

  const resetPasswordHandler = async () => {
    setIsModalLoading(true);
    const result = await accountManager.resetAccountPasswordApi(
      selectedAccount?.id
    );
    setIsAccountModalOpen(false);
    if (result.status === 0) {
      setToastText(
        t("toast_reset_message_success", { name: selectedAccount?.name })
      );
    } else {
      setToastText(
        t("toast_reset_message_failed", {
          name: selectedAccount?.name,
        })
      );
    }
    setIsModalLoading(false);
    setShowToast(true);
  };

  const deleteAccountHandler = async () => {
    setIsModalLoading(true);
    const result = await accountManager.deleteAccountApi(selectedAccount?.id);
    setIsAccountModalOpen(false);
    if (result.status === 0) {
      setToastText(
        t("toast_delete_account_message_success", {
          name: selectedAccount?.name,
        })
      );
      onRefresh();
    } else {
      setToastText(
        t("toast_delete_account_message_failed", {
          name: selectedAccount?.name,
        })
      );
    }
    setIsModalLoading(false);
    setShowToast(true);
  };

  const ownEmail = JSON.parse(localStorage.getItem("userData"))?.email;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PageHeader
            pageTitle={t("back_office_users") + " (" + usersNb + ")"}
            pageHeaderButtons={pageHeaderButtons}
            showExpandableInput={showExpandableInput}
            onOpenExpandableInput={openExpandableInputHandler}
            onCloseExpandableInput={closeExpandableInputHandler}
            onSearch={searchHandler}
          />
          <ul className={classes.list}>
            {users.map((user) => (
              <UserCard
                key={user.id}
                userData={user}
                readOnly={readOnly}
                onClick={() => {
                  openAccountModalHandler(true, user);
                }}
                isOwnAccount={user?.email === ownEmail}
              />
            ))}
          </ul>
          {isAccountModalOpen && (
            <AccountModal
              isModalOpen={isAccountModalOpen}
              onClose={() => setIsAccountModalOpen(false)}
              isEditing={isEditing}
              accountData={selectedAccount}
              onResetPassword={resetPasswordHandler}
              onDeleteAccount={deleteAccountHandler}
              isOwnAccount={selectedAccount?.email === ownEmail}
              onRefreshAccounts={onRefresh}
              isModalLoading={isModalLoading}
            />
          )}
          {showToast && (
            <Toast
              isDisplayed={showToast}
              type={toastText.includes("failed") ? "warning" : "info"}
              messageText={toastText}
              onDisplay={(isDisplayed) => setShowToast(isDisplayed)}
            />
          )}
        </>
      )}
    </>
  );
}

export default Users;
