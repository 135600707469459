import { useState, useEffect, useRef } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { t } from "i18next";

import classes from "./HelpCard.module.scss";
import Card from "../ui/card/Card";
import Line from "../ui/line/Line";
import LanguagesList from "../languagesList/LanguagesList";
import Button from "../ui/button/Button";
import TextEditor from "../ui/textEditor/TextEditor";
import draftToHtml from "draftjs-to-html";
import Loader from "../ui/loader/Loader";
import htmlToDraft from "html-to-draftjs";

function HelpCard({ text, children, onSave, isLoading, details }) {
  const [selectedLang, setSelectedLang] = useState("en");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [values, setValues] = useState({
    en: EditorState.createEmpty(),
    ar: EditorState.createEmpty(),
  });

  const ref = useRef();

  useEffect(() => {
    setEditorState(values[selectedLang]);
  }, [selectedLang]);

  // Effect to update the values state whenever the editorState changes
  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      [selectedLang]: editorState,
    }));
  }, [editorState]);

  useEffect(() => {
    if (details) {
      const newValues = {};
      Object.keys(details).forEach((lang) => {
        const contentBlock = htmlToDraft(details[lang]);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
            contentBlock.entityMap
          );
          const newEditorState = EditorState.createWithContent(contentState);
          newValues[lang] = newEditorState;
        } else {
          newValues[lang] = EditorState.createEmpty();
        }
      });
      setValues(newValues);
      setEditorState(newValues[selectedLang]);
    }
  }, [details]);

  const saveHandler = () => {
    let data;
    Object.keys(values).forEach((lang) => {
      const contentState = values[lang].getCurrentContent();
      const rawContent = convertToRaw(contentState);
      const htmlContent = draftToHtml(rawContent);
      data = { ...data, [lang]: htmlContent };
    });
    onSave(data);
  };

  return (
    <Card className={classes.card}>
      <p>{text}</p>
      <Line />
      <div className={classes.infoContainer}>
        {ref.current?.clientWidth && (
          <LanguagesList
            width={ref.current?.clientWidth - 41.6} //padding of info container
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            className={classes.langList}
          />
        )}
        {children}
        <TextEditor editorState={editorState} setEditorState={setEditorState} />
      </div>
      <Line />
      <div className={classes.actionContainer} ref={ref}>
        {isLoading ? (
          <Loader positionNotFixed={true} className={classes.loader} />
        ) : (
          <Button title={t("btnSave")} onClick={saveHandler} />
        )}
      </div>
    </Card>
  );
}

export default HelpCard;
