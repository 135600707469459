import { CUSTOMER_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

const OPTIONS = [
  { id: "all" },
  { id: "in_pending", name: "pending_sub" },
  { id: "linked", name: "linked_sub" },
  { id: "online", name: "online" },
  { id: "offline", name: "offline" },
];

const HISTORY_OPTIONS = [
  { id: "all" },
  { id: "base" },
  { id: "addOn" },
  { id: "ppv" },
];

const keyOrder = [
  "macAddress",
  "subscriberName",
  "ipAddress",
  "email",
  "offer",
  "refnumber"
];

let ORIGINAL_SUB = [],
  header_sub_data = {
    total: 0,
    active: 0,
    basic: 0,
    advanced: 0,
    premium: 0,
  };

class SubscriptionManager {
  getSubscriptionsApi = async (customerId,selectedFilter,rowsPerPage,currentPage,searchInputValue) => {
    try {
      const response = await loaderCenter.get(
        CUSTOMER_API.SUBSCRIPTIONS(customerId,selectedFilter,rowsPerPage,currentPage,searchInputValue)
      );

      const responseData = await response.json();
      ORIGINAL_SUB = responseData?.data?.subscribersList;
      header_sub_data = {
        total: responseData?.data?.total || 0,
        active: responseData?.data?.active || 0,
        basic: responseData?.data?.basic || 0,
        advanced: responseData?.data?.advanced || 0,
        premium: responseData?.data?.premium || 0,
      };

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getOriginalSubscriptions() {
    return ORIGINAL_SUB;
  }

  getHeaderSubscriptionData() {
    return header_sub_data;
  }

  searchLocally(inputValue, dataSource = null) {
    const subs = JSON.parse(
      JSON.stringify(dataSource ? dataSource : ORIGINAL_SUB)
    );
    return subs?.filter(
      (sub) =>
        sub?.subscriberName
          ?.toLowerCase()
          ?.includes(inputValue?.toLowerCase()) ||
        sub?.macAddress?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        sub?.ipAddress?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        sub?.email?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        sub?.loginName?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        sub?.refnumber?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  }

  searchHistoryLocally(inputValue, dataSource = []) {
    const history = JSON.parse(JSON.stringify(dataSource));
    return history?.filter(
      (h) =>
        h?.action?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        h?.plan?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  }

  getSubscriptionDetailsApi = async (subId) => {
    try {
      const response = await loaderCenter.get(CUSTOMER_API.SUBSCRIPTION(subId));

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  resendSubscriptionPasswordApi = async (subId) => {
    try {
      const response = await loaderCenter.post(
        CUSTOMER_API.SUBSCRIPTION_RESEND_PASSWORD,
        {
          id: subId,
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  unlinkSubscriptionApi = async (subId) => {
    try {
      const response = await loaderCenter.post(
        CUSTOMER_API.SUBSCRIPTION_UNLINK,
        {
          id: subId,
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  prepareData(data) {
    const array = [];
    keyOrder.forEach((key) => {
      if (data?.hasOwnProperty(key)) {
        array.push({
          label: key,
          value: data[key],
          bold: key === "macAddress",
        });
      }
    });
    return array;
  }

  getOptions() {
    return OPTIONS;
  }

  getHistoryOptions() {
    return HISTORY_OPTIONS;
  }

  getFilteredData = (selectedFilterId) => {
    let filteredData;
    if (selectedFilterId === "all") {
      filteredData = ORIGINAL_SUB;
    } else if (selectedFilterId === "in_pending") {
      filteredData = ORIGINAL_SUB.filter(
        (sub) => sub?.macAddress === null
      );
    } else if (selectedFilterId === "linked") {
      filteredData = ORIGINAL_SUB.filter(
        (sub) => sub?.macAddress !== null
      );
    }
    else if (selectedFilterId === "online") {      
      filteredData = ORIGINAL_SUB.filter(
        (sub) => sub?.isActive === true && sub?.macAddress !== null
      );
    }
    else if (selectedFilterId === "offline") {
      filteredData = ORIGINAL_SUB.filter(
        (sub) => sub?.isActive === false && sub?.macAddress !== null
      );
    }
    return filteredData;
  };
}

const subscriptionManager = new SubscriptionManager();

export default subscriptionManager;
