import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./Dashboard.module.scss";
import DashboardCard from "../../../components/contentCards/dashboardCard/DashboardCard";
import dashboardManager from "../../../backend/managers/DashboardManager";
import useFetchData from "../../../hooks/useFetchData";
import Loader from "../../../components/ui/loader/Loader";
import ExpandableMenu from "../../../components/expandables/expandableMenu/ExpandableMenu";
import Map from "../../../components/map/Map";
import { mainHeaderActions } from "../../../store/reducers/MainHeader-slice";
import { errorActions } from "../../../store/reducers/Error-slice";
import DashboardBarChart from "../../../components/dashboardChart/DashboardBarChart";

// Sample data for monthly and daily
// const monthlyData = [
//   ["Month", "Active Users"],
//   ["January", 1500],
//   ["February", 1700],
//   ["March", 1400],
//   ["April", 1900],
//   ["May", 2100],
//   ["June", 2000],
//   ["July", 2300],
//   ["August", 2500],
//   ["September", 2200],
//   ["October", 2400],
//   ["November", 2600],
//   ["December", 2700],
// ];

// const weeklyData = [
//   ["Week", "Active Users"],
//   ["Week 1", 350],
//   ["Week 2", 400],
//   ["Week 3", 450],
//   ["Week 4", 500],
//   ["Week 5", 430],
//   ["Week 6", 460],
//   ["Week 7", 480],
// ];

// const dailyData = [
//   ["Day", "Active Users"],
//   ["2024-08-01", 200],
//   ["2024-08-02", 180],
//   ["2024-08-03", 210],
//   ["2024-08-04", 190],
//   ["2024-08-05", 220],
//   ["2024-08-06", 230],
//   ["2024-08-07", 250],
//   // Add more daily data here
// ];

function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [verticals, setVerticals] = useState([]);

  const {
    data: homeData,
    isLoading: loading,
    error,
  } = useFetchData(dashboardManager.getDashboardDataApi);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const monthlyActiveUsersData = [
    ["Month", "Active Users"],
    ...(dashboardData?.active_users?.monthly || []),
  ];
  const dailyActiveUsersData = [
    ["Day", "Active Users"],
    ...(dashboardData?.active_users?.daily || []),
  ];

  useEffect(() => {
    setDashboardData(homeData);
    setIsLoading(loading);
    if (homeData && homeData["customers"]) {
      localStorage.setItem("mainData", JSON.stringify(homeData.customers));
      dispatch(mainHeaderActions.setMainData(homeData.customers));
    }
    setVerticals(homeData?.verticals);
    // eslint-disable-next-line
  }, [homeData]);

  const changeVerticalsByCountryHandler = async (countryCode) => {
    const result = await dashboardManager.getVerticalsByCountryApi(countryCode);
    if (result.status === 0) {
      setVerticals(
        result?.data?.find((item) => item?.id === "verticals")?.content
      );
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className={classes.center}>
          <img
            alt="no-content"
            src={require("../../../assets/img/no-content.png")}
          />
          <span>{t("noContent")}</span>
        </div>
      ) : (
        <>
          <ExpandableMenu
            title={t("map")}
            initialExpandedValue={true}
            expandableClassName={classes.exp}
            menuContainerClassName={classes.expMenuContainer}
          >
            <Map
              dataSource={dashboardData?.map}
              verticals={verticals}
              originalVerticals={dashboardData?.verticals}
              onChangeVerticalsByCountry={changeVerticalsByCountryHandler}
            />
          </ExpandableMenu>
          {Object.keys(dashboardData)?.map(
            (sectionId, index) =>
              (sectionId === "back_office" ||
                sectionId === "customers" ||
                sectionId === "devices") && (
                <ExpandableMenu
                  key={index}
                  title={t(sectionId)}
                  containerClassName={classes.section}
                  expandableClassName={classes.exp}
                  initialExpandedValue={true}
                >
                  <ul className={classes.list}>
                    {dashboardData[sectionId]?.map((c) => (
                      <DashboardCard
                        imageName={c?.image}
                        key={c?.id}
                        onClick={
                          c?.id === "customers"
                            ? () => navigate("/customers")
                            : c?.id === "users"
                            ? () => navigate("/users")
                            : () => {}
                        }
                        hasPointerCursor={
                          c?.id === "customers" || c?.id === "users"
                        }
                      >
                        <span>{c?.content}</span>
                      </DashboardCard>
                    ))}
                  </ul>
                </ExpandableMenu>
              )
          )}
          <ExpandableMenu
            title={t("user_activity")}
            initialExpandedValue={true}
            menuContainerClassName={classes.expMenuContainer}
          >
            <DashboardBarChart
              dataSource={{
                monthlyData: monthlyActiveUsersData,
                dailyData: dailyActiveUsersData,
              }}
              options={["monthly", "daily"]}
              colors={["#c9a8ff"]}
            />
          </ExpandableMenu>
          {/* <ExpandableMenu
            title={t("total_hours_consumed")}
            initialExpandedValue={true}
            menuContainerClassName={classes.expMenuContainer}
          >
            <DashboardBarChart
              dataSource={{ monthlyData, weeklyData, dailyData }}
              options={["monthly", "weekly", "daily"]}
              colors={["#573390"]}
            />
          </ExpandableMenu> */}
        </>
      )}
    </>
  );
}

export default Dashboard;
