import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import classes from "./ExpandableInput.module.scss";
import styles from "../../../styles/CardLinearBorder.module.scss";

/**
 * ExpandableInput Component
 * @param {Object} props - React props containing the following:
 * @param {Object} input - Input object containing value, placeholder, and disabled state.
 * @param {Function} onChangeInput - Function to handle input change events.
 * @param {Function} onConfirm - Function to confirm input changes (optional).
 * @param {Function} onClose - Function to close the expandable input.
 * @param {string} className - Additional CSS classes for styling (optional).
 * @param {boolean} hasConfirmButton - Flag indicating the presence of a confirm button (optional).
 * @returns {JSX.Element} - Rendered React component for an expandable input.
 */
function ExpandableInput({
  input,
  onChangeInput,
  onConfirm,
  onClose,
  className,
  hasConfirmButton,
}) {
  return (
    <div
      className={`${styles.cardLinearBorder} ${classes.expandableInput} ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      {/* Input field */}
      <input
        autoFocus
        value={input.value}
        placeholder={input.placeholder}
        onChange={onChangeInput}
        disabled={input.disabled}
      />
      {/* Action buttons */}
      <div className={classes.actionsContainer}>
        {/* Confirm button */}
        {hasConfirmButton && (
          <ButtonIcon
            imagePath="icons/confirm"
            activeImagePath="icons/confirm-active"
            className={classes.btnConfirm}
            onClick={onConfirm}
          />
        )}
        {/* Cancel button */}
        <ButtonIcon
          imagePath="icons/cancel"
          activeImagePath="icons/cancel-active"
          className={classes.btnCancel}
          onClick={onClose}
        />
      </div>
    </div>
  );
}

export default ExpandableInput;
