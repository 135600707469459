import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  castData: {
    actors: [],
    directors: [],
    isActorsExpanded: false,
    isDirectorsExpanded: false,
  },
};

const castSlice = createSlice({
  name: "cast",
  initialState,
  reducers: {
    setCastData(state, action) {
      state.castData = action.payload;
    },
    setActorsExpanded(state, action) {
      state.castData.isActorsExpanded = action.payload;
    },
    setDirectorsExpanded(state, action) {
      state.castData.isDirectorsExpanded = action.payload;
    },
    removeActor(state, action) {
      state.castData.actors.splice(action.payload, 1);
    },
    removeDirector(state, action) {
      state.castData.directors.splice(action.payload, 1);
    },
  },
});

export const castActions = castSlice.actions;

export default castSlice;
