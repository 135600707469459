import { useState } from "react";

import classes from "./ExpandableSelectButton.module.scss";
import Card from "../../ui/card/Card";
import ButtonTitleIcon from "../../ui/buttonTitleIcon/ButtonTitleIcon";

function ExpandableSelectButton({
  title,
  className,
  children,
  expCardClassName,
  backdropClassName,
}) {
  const [isClicked, setIsClicked] = useState(false);

  const toggleHandler = () => {
    setIsClicked((prevState) => !prevState);
  };

  return (
    <ButtonTitleIcon
      className={`${classes.btn} ${className}`}
      onClick={toggleHandler}
      title={title}
      imagePath={`icons/arrow-${!isClicked ? "forward" : "bottom"}`}
      position={"left"}
    >
      {isClicked && (
        <>
          <div
            className={`${classes.expCardBackdrop} ${backdropClassName}`}
            onClick={(e) => {
              e.stopPropagation();
              setIsClicked(false);
            }}
          />
          <Card
            className={`${classes.expCard} ${expCardClassName}`}
            onClick={(e) => {
              e.stopPropagation();
              setIsClicked(false);
            }}
          >
            {children}
          </Card>
        </>
      )}
    </ButtonTitleIcon>
  );
}

export default ExpandableSelectButton;
