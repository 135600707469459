import { t } from "i18next";

import classes from "./UserCard.module.scss";
import Card from "../../ui/card/Card";
import Button from "../../ui/button/Button";

function UserCard({ userData, readOnly, onClick, isOwnAccount }) {
  const name = userData?.name;
  const role = userData?.role;

  return (
    <Card className={classes.userCard}>
      {isOwnAccount && (
        <img
          alt={t("star")}
          src={require("../../../assets/img/icons/star.png")}
          className={classes.star}
        />
      )}
      <div className={`${classes.row} ${classes.bold}`}>
        <label>{t("name") + ":"}</label>
        <span>{name}</span>
      </div>
      <div className={classes.row}>
        <label>{t("role") + ":"}</label>
        <span>{role}</span>
      </div>
      {!readOnly && (
        <div className={classes.actionContainer}>
          <Button title={t("edit")} className={classes.btn} onClick={onClick} />
        </div>
      )}
    </Card>
  );
}

export default UserCard;
