import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import classes from "./Customers.module.scss";
import CustomerTableRow from "../../../components/table/tableRow/CustomerTableRow";
import SearchSortView from "../../../components/ui/searchSort/searchSortView/SearchSortView";
import ButtonIcon from "../../../components/ui/buttonIcon/ButtonIcon";
import SortHelper from "../../../utils/SortHelper";
import CustomerCard from "../../../components/contentCards/customerCard/CustomerCard";
import NoItemsView from "../../../components/ui/noItemsView/NoItemsView";
import customerManager from "../../../backend/managers/CustomerManager";
import Loader from "../../../components/ui/loader/Loader";
import useFetchDataPagination from "../../../hooks/useFetchDataPagination";
import CustomPaginationFooter from "../../../components/pagination/CustomPaginationFooter";

function Customers() {
  const SORT_OPTIONS = SortHelper.getSortOptions();
  const tableHeaders = customerManager.getTableHeaders();

  const [customers, setCustomers] = useState([]);
  const [customersTotalResults, setCustomersTotalResults] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState();
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[0]);
  const [isDesc, setIsDesc] = useState(false);
  const [displayGrid, setDisplayGrid] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const navigate = useNavigate();

  const { data, isLoading: loading, totalResutls } = useFetchDataPagination(
    customerManager.getCustomersApi,
    rowsPerPage,
    currentPage,
    searchInputValue,
    selectedSortType.id
  );

  useEffect(() => {    
    setCustomers(data);
    setCustomersTotalResults(totalResutls);
    setIsLoading(loading);
  }, [data]);

  const arrangeTableCustomersHandler = (thId) => {
    setIsDesc((prevState) => !prevState);
    setCustomers((prevState) =>
      SortHelper.arrangeData(prevState, !isDesc, thId)
    );
  };

  useEffect(() => {
    setIsLoading(true);     
  }, [selectedSortType]);

  const createRowsPerPageOptions = (dataLength) => {
    const options = [];
    const maxRows = Math.max(8, dataLength); 
    for (let i = 8; i < maxRows; i += 8) {
      options.push(i);
    }
    if (!options.includes(dataLength)) {
      options.push(dataLength);
    }
    return options;
  };
  
  const rowsPerPageOptions = createRowsPerPageOptions(customersTotalResults);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); 
  };

  return (
    <>
      <h2>{t("customers")}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.sortingContainer}>
            <SearchSortView
              hasSort={displayGrid}
              className={classes.searchSortView}
              expButtonClassName={classes.expButton}
              searchInputClassName={classes.searchInput}
              options={SORT_OPTIONS}
              selectedSortType={selectedSortType}
              onSelectSortType={setSelectedSortType}
              searchInputValue={searchInputValue}
              searchInputPlaceholder={t("searchCustomer")}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={(value) => setSearchInputValue(value)}
            />
            <div className={classes.actionsContainer}>
              <ButtonIcon
                imagePath={`icons/card-grid${displayGrid ? "-active" : ""}`}
                className={`${classes.btnGrid} ${
                  displayGrid && classes.btnActive
                }`}
                onClick={() => {
                  setDisplayGrid(true);
                  setSearchInputValue("");
                }}
                isSvg={true}
              />
              <ButtonIcon
                imagePath={`icons/tabs${!displayGrid ? "-active" : ""}`}
                className={!displayGrid ? classes.btnActive : ""}
                onClick={() => {
                  setDisplayGrid(false);
                  setSearchInputValue("");
                }}
                isSvg={true}
              />
            </div>
          </div>
          {customers?.length > 0 ? (
            !displayGrid ? (
              <div
                className={`${classes.scrollableContainer} ${classes.tableStyle}`}
              >
                <table className={classes.table}>
                  <thead>
                    <tr>
                      {tableHeaders.map((th) => (
                        <th key={th.id}>
                          <div
                            className={classes.thContainer}
                            onClick={() => arrangeTableCustomersHandler(th?.id)}
                          >
                            <span>{t(th.id)}</span>
                            <img
                              src={require("../../../assets/img/icons/arrows.png")}
                              alt={t("arrows")}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, index) => (
                      <CustomerTableRow
                        key={customer.id}
                        index={index}
                        customer={customer}
                        onClick={() => navigate(`/customers/${customer?.id}`)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <ul className={classes.cardList}>
                {customers.map((customer) => (
                  <CustomerCard
                    key={customer?.id}
                    customer={customer}
                    onClick={() => navigate(`/customers/${customer?.id}`)}
                  />
                ))}
              </ul>
            )
          ) : (
            <NoItemsView className={classes.noItems} text={t("noItemsRow")} />
          )}

          {customersTotalResults > 8 && 
            <div style={{marginTop: 15}}>
              <CustomPaginationFooter
                totalItems={customersTotalResults}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                setPage={handlePageChange}
                setRowsPerPage={handleRowsPerPageChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </div>
          }
        </>
      )}
    </>
  );
}

export default Customers;
