import { t } from "i18next";
import classes from "./ButtonTitleIcon.module.scss";

function ButtonTitleIcon({
  title,
  imagePath,
  onClick,
  className,
  children,
  position,
  isImgSvg,
  isDisabled,
}) {
  return (
    <div
      className={`${classes.btn} ${className}  ${classes[position]} ${
        isDisabled && classes.disabled
      }`}
      onClick={!isDisabled ? onClick : () => {}}
    >
      <img
        alt={t("icon")}
        src={require(`../../../assets/img/${imagePath}.${
          isImgSvg ? "svg" : "png"
        }`)}
        className={classes.img}
      />
      <span>{title}</span>
      {children}
    </div>
  );
}

export default ButtonTitleIcon;
