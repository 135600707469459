import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import Cookies from "js-cookie";

import classes from "./TwoFactorAuthentication.module.scss";
import Card from "../../components/ui/card/Card";
import CustomInput from "../../components/ui/inputs/customInput/CustomInput";
import Button from "../../components/ui/button/Button";
import authManager from "../../backend/managers/AuthManager";
import Loader from "../../components/ui/loader/Loader";
import accountManager from "../../backend/managers/AccountManager";
import { errorActions } from "../../store/reducers/Error-slice";

function TwoFactorAuthentication() {
  const [code, setCode] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [qr, setQr] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gauth = localStorage.getItem("gauth");

  const changeInputHandler = (e) => {
    const value = e.target.value;
    if (value.length <= 6) {
      setCode(value);
    }
  };

  useEffect(() => {
    if (code?.length === 6) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [code]);

  const loadQrHandler = async () => {
    setIsLoading(true);
    const result = await authManager.getQrApi();
    if (result?.status === 0) {
      setQr(result?.data);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsLoading(false);
  };

  const submitCodeHandler = async () => {
    setIsButtonLoading(true);
    const result = await authManager.validateCodeApi(code);
    if (result?.status === 0) {
      Cookies.set("token", result?.token, { expires: 30 });
      Cookies.remove("temp_token");
      navigate("/dashboard");
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsButtonLoading(false);
  };

  const onEnterClickedHandler = (e) => {
    if (e.key === "Enter") {
      submitCodeHandler(e);
    }
  };

  return (
    <div className={classes.page}>
      <Card className={classes.card}>
        <h2>{t("twoFA")}</h2>
        <p>{t("enterVerificationCode")}</p>
        <CustomInput
          placeholder={t("verificationCode")}
          type={"number"}
          value={code}
          onChange={changeInputHandler}
          onKeyDown={onEnterClickedHandler}
          autoFocus={true}
        />
        {gauth === "false" && (
          <span className={classes.span} onClick={loadQrHandler}>
            {t("scanQR")}
          </span>
        )}
        {(isLoading || qr) && (
          <div className={classes.loaderContainer}>
            {isLoading ? (
              <Loader positionNotFixed={true} className={classes.loader} />
            ) : (
              qr && <img alt={t("qr")} src={qr} className={classes.qr} />
            )}
          </div>
        )}
        <div className={classes?.actionContainer}>
          {isButtonLoading ? (
            <Loader className={classes.loader} positionNotFixed={true} />
          ) : (
            <Button
              title={t("submit")}
              isDisabled={isDisabled}
              className={classes.btn}
              onClick={submitCodeHandler}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default TwoFactorAuthentication;
