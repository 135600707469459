import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import classes from "./Cast.module.scss";
import Button from "../ui/button/Button";
import AddActorModal from "../modals/addActorModal/AddActorModal";
import ExpandableList from "../expandables/expandableList/ExpandableList";
import ExpandableSelectButton from "../expandables/expandableSelectButton/ExpandableSelectButton";
import { castActions } from "../../store/reducers/Cast-slice";

const rolesMenu = [{ id: "all" }, { id: "actors" }, { id: "directors" }];
// const membersMenu = [
//   { name: "Robert Downey Jr.", role: "Actor" },
//   { name: "Josh Brolin,Elizabeth Olsen", role: "Director" },
//   { name: "Josh Brolin,Elizabeth Olsen dssd laj", role: "Director" },
//   { name: "Tom Hawk", role: "Director" },
// ];

function Cast({ showMembers, setShowMembers, disabled }) {
  const [selectedRole, setSelectedRole] = useState("all");
  const [inputValue, setInputValue] = useState("");
  const [members, setMembers] = useState([]);
  const [isAddActorOpen, setIsAddActorOpen] = useState(false);

  const cast = useSelector((state) => state.cast);

  const dispatch = useDispatch();

  const onFocusInputHandler = (e) => {
    e.stopPropagation();
    setShowMembers(true);
  };

  const onInputChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const selectMemberHandler = (member) => {
    // if (member.role === "Actor") {
    //   setSelectedActors((prevState) => {
    //     return [...prevState, member];
    //   });
    // } else if (member.role === "Director") {
    //   setSelectedDirectors((prevState) => {
    //     return [...prevState, member];
    //   });
    // }
    // setShowMembers(false);
  };

  const onOpenAddInDatabaseHandler = () => {
    setIsAddActorOpen(true);
    setShowMembers(false);
  };

  const removeActorHandler = (itemIndex) => {
    dispatch(castActions.removeActor(itemIndex));
  };

  const removeDirectorHandler = (itemIndex) => {
    dispatch(castActions.removeDirector(itemIndex));
  };

  useEffect(() => {
    // setMembers(
    //   membersMenu.filter((m) => m.name.toLocaleLowerCase().includes(inputValue))
    // );
  }, [inputValue]);

  return (
    <>
      {!disabled && (
        <div
          className={classes.cast}
          style={{ borderColor: showMembers ? "var(--primary-400)" : "" }}
        >
          <ExpandableSelectButton
            title={t(selectedRole)}
            className={classes.selectRole}
            expCardClassName={classes.rolesMenu}
            backdropClassName={classes.rolesMenuBackdrop}
          >
            {rolesMenu.map((role) => (
              <span key={role.id} onClick={() => setSelectedRole(role.id)}>
                {t(role.id)}
              </span>
            ))}
          </ExpandableSelectButton>
          <input
            placeholder={t("addMember")}
            onChange={onInputChangeHandler}
            value={inputValue}
            onClick={(e) => e.stopPropagation()}
            onFocus={onFocusInputHandler}
          />
        </div>
      )}
      {!disabled && showMembers && (
        <div className={classes.membersMenu}>
          {members && members.length > 0 ? (
            members?.map((member) => (
              <div
                key={member?.name}
                className={classes.row}
                onClick={() => selectMemberHandler(member)}
              >
                <span>{t(member.name)}</span>
                <span className={classes.role}>{t(member?.type)}</span>
              </div>
            ))
          ) : (
            <div className={classes.noResult}>
              <img
                alt="no-result"
                src={require("../../assets/img/no-file.png")}
              />
              <span>{t("no_result_found")}</span>
              <Button
                title={t("add_in_database")}
                onClick={onOpenAddInDatabaseHandler}
              />
            </div>
          )}
        </div>
      )}
      {isAddActorOpen && (
        <AddActorModal
          isModalOpen={isAddActorOpen}
          onClose={() => setIsAddActorOpen(false)}
        />
      )}
      <ExpandableList
        title={t("actors")}
        datasource={cast.castData?.actors}
        isExpanded={cast?.castData?.isActorsExpanded}
        setIsExpanded={(value) => castActions.setActorsExpanded(value)}
        onRemove={removeActorHandler}
        disabled={disabled}
        className={classes.noMarginTop}
      />
      <ExpandableList
        title={t("directors")}
        datasource={cast.castData?.directors}
        isExpanded={cast?.castData?.isDirectorsExpanded}
        setIsExpanded={(value) => castActions.setDirectorsExpanded(value)}
        onRemove={removeDirectorHandler}
        disabled={disabled}
      />
    </>
  );
}

export default Cast;
