import { useEffect, useState } from "react";

import classes from "./GroupedPickerView.module.scss";
import ExpandablePickerView from "../../pickerViews/ExpandablePickerView/ExpandablePickerView";
import SortHelper from "../../../utils/SortHelper";

function GroupedPickerView({
  dataSource = [],
  onSelectItems,
  isDisabled,
  onSearchSubscriber,
  initialSelectedItems,
}) {
  const [selectedItems, setSelectedItems] = useState([]);

  const selectIemsHandler = (items, cell) => {
    setSelectedItems((prevState) => {
      let copy = prevState;
      if (items?.length > 0) {
        const updatedCell = {
          ...cell,
          subscriber: SortHelper.arrangeData(items, false, "subscriberName"),
        };
        const index = copy.findIndex((item) => item?.id === cell?.id);
        if (index !== -1) {
          copy = copy.filter((item) => item?.id !== cell?.id);
        }
        return [...copy, updatedCell];
      }
      copy = copy.filter((item) => item?.id !== cell?.id);
      return copy;
    });
  };

  useEffect(() => {
    onSelectItems(selectedItems);
  }, [selectedItems]);

  return dataSource.map((cell) => {
    const subscribers = cell?.subscriber;
    return (
      subscribers?.length > 0 && (
        <ExpandablePickerView
          key={cell?.id}
          title={cell?.customerName}
          dataSource={subscribers}
          initialSelectedItems={
            initialSelectedItems
              ? initialSelectedItems?.find((item) => item?.id === cell?.id)
                  ?.subscriber
              : selectedItems?.find((item) => item?.id === cell?.id)?.subscriber
          }
          hasSearch={true}
          className={classes.expandable}
          pickerViewClassName={classes.expPickerView}
          onSelectItems={(items) => selectIemsHandler(items, cell)}
          isDisabled={isDisabled}
          onSearch={onSearchSubscriber}
          hasArrowIcon={true}
        />
      )
    );
  });
}

export default GroupedPickerView;
