import { useEffect } from "react";
import classes from "./Toast.module.scss";
import Button from "../ui/button/Button";
import { t } from "i18next";

function Toast({
  messageText,
  type,
  isDisplayed,
  onDisplay,
  children,
  onUndo,
}) {
  let image, color;

  switch (type) {
    case "info":
      image = "message-info-blue";
      color = "var(--secondary-600)";
      break;
    case "info-green":
      image = "message-info-green";
      color = "var(--green)";
      break;
    case "warning":
      image = "message-warning-red";
      color = "var(--warning)";
      break;
    default:
      image = "message-info-blue";
      color = "var(--secondary-600)";
      break;
  }

  useEffect(() => {
    let id;
    if (!children) {
      id = setTimeout(() => {
        onDisplay(false);
      }, 5000);
    }

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line
  }, [type, isDisplayed, messageText]);

  return (
    <>
      {isDisplayed && (
        <div className={classes.toast} style={{ borderLeftColor: color }}>
          <img
            src={require(`../../assets/img/message/${image}.png`)}
            alt="icon"
          />
          <span style={{ color: color }}>{messageText}</span>
          {onUndo && (
            <Button
              title={t("undo")}
              className={classes.btnUndo}
              onClick={() => {
                onUndo();
                onDisplay(false);
              }}
            />
          )}
          {children}
        </div>
      )}
    </>
  );
}

export default Toast;
