import TableRow from "./TableRow";

import deviceIcon from "../../../assets/img/icons/device.svg";
import allDeviceIcon from "../../../assets/img/icons/all-devices.svg";
import fileIcon from "../../../assets/img/icons/file.svg";
import folderIcon from "../../../assets/img/icons/folder.svg";

function CustomerTableRow({ customer, index, onClick }) {
  const logo = customer?.logo;
  const company = customer?.customerName;
  const subscription = customer?.totalSubscriptions;
  const devices = customer?.activeDevices;
  const offers = customer?.offers;
  const options = customer?.options;
  const accountId = customer?.customerAccountId;

  const dataSource = [
    { id: "company", name: company, icon: logo, isLogo: true, isObfuscated: true},
    {
      id: "accountId",
      name: accountId,
      noIcon: true,
    },
    {
      id: "subscription",
      name: subscription,
      icon: allDeviceIcon,
    },
    {
      id: "devices",
      name: devices,
      icon: deviceIcon,
    },
    {
      id: "offers",
      name: Array.isArray(offers) ? null : offers,
      icon: fileIcon,
      content: Array.isArray(offers) ? offers : null,
      maxWidth: "10rem",
      isHidden: offers ? false : true,
    },
    {
      id: "option",
      icon: folderIcon,
      content: options,
      maxWidth: "10rem",
      isHidden: options ? false : true,
    },
  ];

  return <TableRow index={index} dataSource={dataSource} onClick={onClick} />;
}

export default CustomerTableRow;
