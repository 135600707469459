import { t } from "i18next";

// Class to handle message types
class MessageType {
  // Constructor initializes the MessageType instance with data {type, pinnedMessages, unpinnedMessages}
  constructor(data) {
    this.data = data; // Assigns provided data to the instance
  }

  // Method to retrieve all messages (pinned and unpinned) from the MessageType instance
  getMessages() {
    if (!this.data.pinnedMessages && !this.data.unpinnedMessages) {
      return [];
    } else if (!this.data.pinnedMessages) {
      return this.data.unpinnedMessages;
    } else if (!this.data.unpinnedMessages) {
      return this.data.pinnedMessages;
    }
    return [...this.data.pinnedMessages, ...this.data.unpinnedMessages];
  }

  // Method to get the number of messages (pinned and unpinned) from the MessageType instance
  getValues() {
    return this.getMessages()?.length || 0;
  }

  // Method to get the name of the message type based on the 'type' property using i18next translation
  getName() {
    return t(this.data?.type); // Uses i18next's translation for the 'type' property
  }
}

export default MessageType;
