import ReactDOM from "react-dom";

import classes from "./Modal.module.scss";
import backdropStyle from "../../../styles/Backdrop.module.scss";
import ButtonIcon from "../buttonIcon/ButtonIcon";
import Card from "../card/Card";
import { useEffect, useState } from "react";

// Backdrop component for the modal
function Backdrop({ onClick, isOpen, className }) {
  return (
    <div
      style={{ visibility: isOpen ? "visible" : "hidden" }}
      className={`${backdropStyle.backdrop} ${classes.backdrop} ${className}`}
      onClick={onClick}
    />
  );
}

// ModalOverlay component containing the actual modal content
function ModalOverlay({ children, className, style, isOpen, onClose, top }) {
  // const modalStyle = style
  //   ? style
  //   : {
  //       transform: isOpen ? "translateY(0)" : "translateY(-100%)",
  //       opacity: isOpen ? 1 : 0,
  //       top: isOpen ? (top ? top : "5rem") : "-5rem",
  //     };

  const [modalStyle, setModalStyle] = useState();

  useEffect(() => {
    setModalStyle(
      style
        ? style
        : {
            transform: isOpen ? "translateY(0)" : "translateY(-100%)",
            opacity: isOpen ? 1 : 0,
            top: isOpen ? (top ? top : "5rem") : "-5rem",
          }
    );
    // eslint-disable-next-line
  }, [isOpen]);

  // useEffect(() => {
  //   isOpen
  //     ? document.getElementById("modal").classList.add(classes.animated)
  //     : document.getElementById("modal").classList.remove(classes.animated);
  // }, [isOpen]);

  return (
    <div className={classes.modalOverlay}>
      {/* Modal card containing children and close button */}
      <Card
        className={`${classes.modal} ${className}`}
        style={{ ...modalStyle, maxHeight: window.innerHeight - 20 }}
        isOpen={isOpen}
      >
        <ButtonIcon
          className={classes.btnClose}
          onClick={onClose}
          imagePath="icons/exit"
        />
        {children}
      </Card>
    </div>
  );
}

// Main Modal component that renders ModalOverlay and Backdrop using portals
function Modal({
  children,
  className,
  isOpen,
  onClose,
  style,
  top,
  backdropClassName,
}) {
  return (
    <>
      {/* Portal for rendering ModalOverlay */}
      {ReactDOM.createPortal(
        <ModalOverlay
          className={className}
          style={style}
          isOpen={isOpen}
          onClose={onClose}
          top={top}
        >
          {children}
        </ModalOverlay>,
        document.getElementById("overlays") // Portal target element
      )}

      {/* Portal for rendering Backdrop */}
      {ReactDOM.createPortal(
        <Backdrop
          isOpen={isOpen}
          onClick={onClose}
          className={backdropClassName}
        />,
        document.getElementById("overlays") // Portal target element
      )}
    </>
  );
}

export default Modal;
