import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./SigninLayout.module.scss";
import Footer from "../layout/footer/Footer";
import Button from "../ui/button/Button";
import InputSign from "../ui/inputs/inputSign/InputSign";
import Card from "../ui/card/Card";
import Loader from "../ui/loader/Loader";

// SigninLayout component representing a sign-in page layout
function SigninLayout({
  className,
  initialState,
  title,
  label,
  input1,
  input2,
  validateForm = () => {},
  onSubmitForm = () => {},
  forgotPassword,
  btnTitle,
  signSectionClassName,
}) {
  // State to manage form credentials and error state
  const [credentials, setCredentials] = useState(initialState || {});
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle input field changes
  const changeInputHandler = (field, value) => {
    setCredentials({ ...credentials, [field]: value.trim() });
    setError(null);
  };

  // Function to handle form submission
  const submitHandler = async (e) => {
    e.preventDefault();
    const error = validateForm(credentials);
    setError(error);

    if (!error) {
      try {
        setIsLoading(true);
        const result = await onSubmitForm(credentials);
        const status = result?.status;
        const message = result?.message;
        if (status === 2 && message === "Wrong credentials") {
          setError(t("errorLogin"));
        } else if (status === 2) {
          setError(t(message));
        } else {
          setError(t("sww"));
        }
      } catch (err) {
        setError(err?.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setCredentials(initialState);
  }, [initialState]);

  const onEnterClickedHandler = (e) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };

  return (
    <section className={`${classes.page} ${className}`}>
      <Card className={classes.card}>
        {/* Fixed content with illustration */}
        <div className={classes.fixed}>
          <p>{t("signFixedTitle")}</p>
          <img src={require("../../assets/img/illu-2.png")} alt="illu-2.png" />
        </div>
        {/* Sign-in form section */}
        <div className={`${classes.sign} ${signSectionClassName}`}>
          <h2 style={{ marginBottom: label ? "1.5rem" : "4rem" }}>{title}</h2>
          {label && <p>{label}</p>}
          <form onSubmit={submitHandler}>
            {/* Input fields for username and password */}
            {input1 && (
              <InputSign
                // First input field
                id={input1.id}
                className={`${classes.input} ${classes.marginBottom}`}
                inputClassName={classes.customInput}
                label={input1.label}
                placeholder={input1.placeholder}
                value={Object.values(credentials)[0] || ""}
                type={input1.type}
                onChange={changeInputHandler}
                isValid={!error}
                span={input1.span}
              />
            )}
            {input2 && (
              <InputSign
                // Second input field
                id={input2.id}
                className={classes.input}
                inputClassName={classes.customInput}
                label={input2.label}
                placeholder={input2.placeholder}
                value={Object.values(credentials)[1] || ""}
                type={input2.type}
                onChange={changeInputHandler}
                isValid={!error}
                span={input2.span}
                onKeyDown={onEnterClickedHandler}
              />
            )}
            {/* Display error message if there is an error */}
            {error && (
              <div className={classes.warning}>
                <img
                  alt="warning"
                  src={require("../../assets/img/icons/warning.png")}
                />
                <span>{error}</span>
              </div>
            )}
            {/* Display forgot password link if provided */}
            {forgotPassword && (
              <span className={classes.forgotPassword}>{forgotPassword}</span>
            )}
            {/* Button for submitting the form */}
            {(input1 || input2) && (
              <div className={classes.actionContainer}>
                {!isLoading ? (
                  <Button
                    type="submit"
                    title={btnTitle ? btnTitle : t("login")}
                    className={classes.btnLogin}
                  />
                ) : (
                  <Loader positionNotFixed={true} className={classes.loader} />
                )}
              </div>
            )}
          </form>
        </div>
      </Card>
      {/* Footer component */}
      <Footer />
    </section>
  );
}

export default SigninLayout;
