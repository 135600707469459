import { useState, useRef } from "react";
import { t } from "i18next";

import classes from "./ExpandableMenu.module.scss";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import PickerView from "../../pickerViews/pickerView/PickerView";
import Button from "../../ui/button/Button";
import dragIcon from "../../../assets/img/icons/drag.png";

/**
 * ExpandableMenu Component
 * @param {Object} props - React props containing the following:
 * @param {string} title - Title of the expandable menu.
 * @param {Array} dataSource - Data source for the PickerView component (optional).
 * @param {boolean} isDisabled - Flag indicating if the menu is disabled.
 * @param {boolean} isExpanded - Flag indicating if the menu is expanded.
 * @param {Function} onToggleExpand - Function to toggle the menu expansion.
 * @param {boolean} hasButton - Flag indicating the presence of a button.
 * @param {Function} onClickButton - Function to handle button click events.
 * @param {string} className - Additional CSS classes for styling (optional).
 * @param {string} pickerViewClassName - Additional CSS classes for PickerView styling (optional).
 * @param {boolean} hasPickerView - Flag indicating the presence of PickerView.
 * @param {ReactNode} children - Nested elements within the menu.
 * @returns {JSX.Element} - Rendered React component for an expandable menu.
 */

function ExpandableMenu({
  index,
  title,
  dataSource,
  isDisabled,
  isExpanded,
  initialExpandedValue,
  onToggleExpand,
  hasButton,
  onClickButton,
  containerClassName,
  expandableClassName,
  pickerViewClassName,
  menuContainerClassName,
  hasPickerView,
  children,
  disableExpanded,
  onSelectExpItems = () => {},
  initialSelectedItems,
  toggleHandler,
  isDraggable,
  iconsList,
  provided,
}) {
  const [expanded, setExpanded] = useState(initialExpandedValue || false);
  const [showIcons, setShowIcons] = useState(false);

  const handleMouseOver = () => {
    if (isDraggable || (iconsList && iconsList.length > 0)) {
      setShowIcons(true);
    }
  };

  const handleMouseOut = () => {
    if (isDraggable || (iconsList && iconsList.length > 0)) {
      setShowIcons(false);
    }
  };

  const toggleExpandHandler = () => {
    if (!disableExpanded) {
      setExpanded((prevState) => {
        const newState = !prevState;
        if (toggleHandler) {
          setTimeout(() => toggleHandler(newState, index), 0);
        }
        return newState;
      });
    }
  };

  const exp = isExpanded ? isExpanded : expanded;

  return (
    <div
      className={`${classes.container} ${containerClassName}`}
    >
      {/* Main section with title and expand/collapse icon */}
      <div
        className={`${classes.expMenu} ${expandableClassName} ${
          isDraggable && showIcons ? classes.dragMenu : ""
        }`}
        onClick={onToggleExpand ? onToggleExpand : toggleExpandHandler}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className={classes.wrapper}>
          {showIcons && isDraggable && (
            <div className={`${classes.dragBtnWrapper} ${classes.disable}`}>
              <img
                src={dragIcon}
                alt={"dragIcon"}
                className={classes.dragIcon}
              />
            </div>
          )}
          <span>{title}</span>
        </div>

        <div className={classes.actionBtnsContainer}>
          {showIcons &&
            iconsList &&
            iconsList.map((icon) => (
              <ButtonIcon
                key={icon.id}
                className={classes.actionBtn}
                imagePath={icon.imagePath}
                activeImagePath={icon.activeImagePath}
                onClick={icon.onClick}
              />
            ))}
          <ButtonIcon
            className={classes.btn}
            imagePath={exp ? "icons/arrow-bottom" : "icons/arrow-forward"}
          />
        </div>
      </div>

      {/* Expanded section */}
      {exp && (
        <>
          {/* Render PickerView or children based on the condition */}
          <div className={`${classes.menuContainer} ${menuContainerClassName}`}>
            {hasPickerView ? (
              <PickerView
                initialSelectedItems={initialSelectedItems}
                dataSource={dataSource}
                allowMultipleSelections={true}
                imageName="checkbox-checked"
                onSelectItems={onSelectExpItems}
                isDisabled={isDisabled}
                pickerViewClassName={`${classes.menu} ${pickerViewClassName}`}
                pickerCellClassName={classes.pickerCell}
                pickerCellHeight={19}
                pickerCellImageHeight={18}
              />
            ) : (
              children
            )}
          </div>

          {/* Render button if specified */}
          {hasButton && (
            <div className={classes.btnContainer}>
              <Button
                className={classes.modifyBtn}
                title={t("modifyProvGroup")}
                onClick={onClickButton}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ExpandableMenu;
