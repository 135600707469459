import { useState, useEffect } from "react";

const useElementWidth = (ref) => {
  const [width, setWidth] = useState();

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      const handleResize = () => {
        setWidth(currentRef?.clientWidth);
      };

      handleResize();

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(currentRef);

      return () => {
        if (currentRef) {
          resizeObserver.unobserve(currentRef);
        }
      };
    }
  }, [ref]);

  return width;
};

export default useElementWidth;
