import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";

import classes from "./AddMessageModal.module.scss";
import Modal from "../../ui/modal/Modal";
import ExpandableMenu from "../../expandables/expandableMenu/ExpandableMenu";
import Button from "../../ui/button/Button";
import InputImage from "../../ui/inputs/inputImage/InputImage";
import Line from "../../ui/line/Line";
import LanguagesList from "../../languagesList/LanguagesList";
// import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import TargetAudienceModal from "../targetAudienceModal/TargetAudienceModal";
import Loader from "../../ui/loader/Loader";
import useElementWidth from "../../../hooks/useElementWidth";

function AddMessageModal({
  isModalOpen,
  onClose,
  content,
  onCreateMessage,
  isAddMessageLoading,
  allowedMessageTypes,
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedMessageType, setSelectedMessageType] = useState(
    content ? content?.type : null
  );
  const [maxLength, setMaxLength] = useState();
  const [isPinned, setIsPinned] = useState(false);
  const [toValues, setToValues] = useState();
  const [objectValues, setObjectValues] = useState({});
  const [bodyValues, setBodyValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  // const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddRecipientsModalOpen, setIsAddRecipientsModalOpen] =
    useState(false);
  const [recipients, setRecipients] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const toggleExpandHandler = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const selectMessageTypeHandler = (type) => {
    //if this item is selected or i select another item => unselect this item
    setSelectedMessageType(null);
    setMaxLength();
    //select the new item
    if (selectedMessageType?.id !== type?.id) {
      setSelectedMessageType(type);
      const max = type?.maxLength;
      setMaxLength(max);
      if (bodyValues[selectedLanguage]?.length > max) {
        let copy = bodyValues;
        Object.keys(bodyValues).forEach(
          (lang) => (copy[lang] = bodyValues[lang]?.slice(0, max))
        );
        setBodyValues(copy);
      }
    }
  };

  const closeModalhandler = () => {
    // if (bodyValues) {
    //   openConfirmationModalHandler();
    // } else {
    //   setActiveIndex(0);
    //   onClose();
    // }
    setActiveIndex(0);
    onClose();
  };

  // const closeHandler = () => {
  //   onClose();
  //   setActiveIndex(0);
  //   closeConfirmationModalHandler();
  // };

  // const confirmHandler = () => {
  //   //save as a draft
  //   closeHandler();
  // };

  // const cancelHandler = () => {
  //   closeHandler();
  // };

  // const openConfirmationModalHandler = () => {
  //   setIsConfirmationModalOpen(true);
  // };

  // const closeConfirmationModalHandler = () => {
  //   setIsConfirmationModalOpen(false);
  // };

  const openAddRecipientsModalHandler = () => {
    setIsAddRecipientsModalOpen(true);
  };

  const closeAddRecipientsModalHandler = () => {
    setIsAddRecipientsModalOpen(false);
  };

  // const changeObjectInputHandler = (e) => {
  //   const value = e.target.value;
  //   if (!value || value === t("object") + ": ") {
  //     setObjectValues("");
  //   } else {
  //     setObjectValues(
  //       value?.startsWith(t("object") + ": ")
  //         ? value
  //         : `${t("object")}: ${value}`
  //     );
  //   }
  // };

  const getRecipientsName = (data) => {
    const recNames = [];
    data?.forEach((r) => {
      r.subscriber?.forEach((s) => {
        recNames.push(s.subscriberName);
      });
    });
    return recNames;
  };

  const setToValuesHandler = (dataSource) => {
    const recNames = getRecipientsName(dataSource);
    let toValue;
    if (recNames.length > 4) {
      toValue = `${recNames?.slice(0, 4)} + ${recNames?.length - 4} ${t(
        "recipients"
      )}`;
    } else {
      toValue = recNames;
    }
    setToValues(t("to") + `${dataSource?.length > 0 ? ": " + toValue : "..."}`);
  };

  useEffect(() => {
    if (
      selectedMessageType &&
      objectValues?.en &&
      bodyValues?.en &&
      recipients.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [bodyValues, objectValues, selectedMessageType, recipients]);

  useEffect(() => {
    if (content) {
      const customersData = content?.customers;
      const initialRecipients = Object.keys(customersData).map((customerId) => {
        return {
          id: +customerId,
          customerName: customersData[customerId]?.name,
          subscriber: customersData[customerId]?.messages?.map(
            (subscription) => {
              return {
                id: subscription.subscriber?.id,
                subscriberName: subscription.subscriber?.subscriberName,
              };
            }
          ),
        };
      });
      setToValuesHandler(initialRecipients);
      setRecipients(initialRecipients);
      setIsPinned(content?.pin);
      setActiveIndex(1);
      setSelectedMessageType(content?.type);
      setObjectValues(content?.title);
      setBodyValues(content?.content);
    } else {
      setSelectedMessageType(null);
      setIsPinned(false);
      setToValues(t("to") + "...");
      setObjectValues({ en: "", fr: "", ar: "" });
      setBodyValues({ en: "", fr: "", ar: "" });
      setRecipients([]);
    }
  }, [content, isModalOpen]);

  const objectChangeHandler = (e) => {
    setObjectValues((prevState) => {
      return { ...prevState, [selectedLanguage]: e.target.value };
    });
  };

  const bodyChangeHandler = (e) => {
    if (selectedMessageType) {
      if (maxLength === 275) {
        const messageWithoutLineBreaks = e.target.value.replace(
          /(\r\n|\n|\r)/gm,
          ""
        ); // Remove line breaks
        setBodyValues((prevState) => {
          return { ...prevState, [selectedLanguage]: messageWithoutLineBreaks };
        });
      } else {
        setBodyValues((prevState) => {
          return { ...prevState, [selectedLanguage]: e.target.value };
        });
      }
    }
  };

  const saveRecipientsHandler = (recipients) => {
    setRecipients(recipients);
    setToValuesHandler(recipients);
  };

  const sendMessageHandler = () => {
    const recIds = [];
    recipients?.forEach((r) => {
      r.subscriber?.forEach((s) => {
        recIds.push(s?.id);
      });
    });
    onCreateMessage({
      type: selectedMessageType?.id || selectedMessageType,
      pin: isPinned,
      title: objectValues,
      content: bodyValues,
      subscribers: recIds,
    });
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModalhandler}
        className={classes.modal}
        top="1rem"
      >
        <h2>{t("newMessage")}</h2>
        <div className={classes.container}>
          <ExpandableMenu
            title={t("typeOfMessage")}
            dataSource={[]}
            isExpanded={activeIndex === 0}
            onToggleExpand={() => toggleExpandHandler(0)}
          >
            {allowedMessageTypes.map((type) => (
              <div
                key={type.id}
                className={classes.messageTypeItem}
                onClick={() => selectMessageTypeHandler(type)}
              >
                <InputImage
                  alt="radio"
                  imagePath={`input-select/${
                    (
                      selectedMessageType?.id
                        ? selectedMessageType?.id === type.id
                        : selectedMessageType === type?.id
                    )
                      ? "radio-purple-checked"
                      : "radio"
                  }`}
                />
                <div className={classes.typeContainer}>
                  <img
                    alt="icon"
                    src={require(`../../../assets/img/message/${type.icon}.png`)}
                  />
                  <label>{t(type.title)}</label>
                </div>
                <img
                  alt="message"
                  src={require(`../../../assets/img/message/${type.image}.png`)}
                />
                <p>
                  {t(type.descriptionId)
                    .split("\n")
                    .map((line) => (
                      <React.Fragment key={line}>
                        <span>{line}</span>
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              </div>
            ))}
          </ExpandableMenu>
          <ExpandableMenu
            title={t("message")}
            isExpanded={activeIndex === 1}
            onToggleExpand={() => toggleExpandHandler(1)}
            containerClassName={classes.messageExpandable}
          >
            <div
              className={classes.recipientsContainer}
              onClick={openAddRecipientsModalHandler}
            >
              <span
                style={{
                  color:
                    recipients?.length === 0
                      ? "var(--light-grey)"
                      : "var(--dark-blue)",
                }}
              >
                {toValues}
              </span>
              <Button className={classes.btnEdit} title={t("edit")} />
            </div>
            <div className={classes.messageContainer}>
              {divWidth && (
                <LanguagesList
                  dataSource={["en", "fr", "ar"]}
                  className={classes.lang}
                  selectedLang={selectedLanguage}
                  setSelectedLang={setSelectedLanguage}
                  width={divWidth - 96}
                />
              )}
              <input
                className={classes.object}
                placeholder={t("object") + "..."}
                value={objectValues[selectedLanguage] || ""}
                onChange={objectChangeHandler}
                maxLength={100}
              />
              <textarea
                className={classes.textarea}
                placeholder={t("message") + "..."}
                value={bodyValues[selectedLanguage] || ""}
                onChange={bodyChangeHandler}
                maxLength={maxLength}
              />
            </div>
            <Line />
            <div
              className={classes.pinContainer}
              onClick={() => setIsPinned((prevState) => !prevState)}
            >
              <InputImage
                alt="radio"
                imagePath={`input-select/${
                  isPinned ? "radio-purple-checked-big" : "radio-big"
                }`}
                className={classes.pinImage}
              />
              <label className={classes.pinLabel}>{t("pinThisMessage")}</label>
            </div>
          </ExpandableMenu>
        </div>
        <div className={classes.actionContainer} ref={ref}>
          {isAddMessageLoading ? (
            <Loader positionNotFixed={true} className={classes.loader} />
          ) : (
            <Button
              title={t("send")}
              isDisabled={isDisabled}
              className={classes.btnWording}
              onClick={sendMessageHandler}
            />
          )}
        </div>
      </Modal>
      {isAddRecipientsModalOpen && (
        <TargetAudienceModal
          isModalOpen={isAddRecipientsModalOpen}
          onClose={closeAddRecipientsModalHandler}
          onSaveRecipients={saveRecipientsHandler}
          initialRecipients={recipients}
        />
      )}
      {/* <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModalHandler}
        onCancel={cancelHandler}
        onConfirm={confirmHandler}
        text={t("confirmationDraft")}
        btnCancelTitle={t("no")}
      /> */}
    </>
  );
}

export default AddMessageModal;
