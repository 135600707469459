import { CONTENT_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

let categories = {};

class ContentManager {
  refreshFeedContents = async () => {
    try {
      const response = await loaderCenter.get(
        CONTENT_API.REFRESH_FEED_CONTENTS
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return false;
    }
  };

  getNewContentNotAssignedApi = async (signal) => {
    try {
      const response = await loaderCenter.get(
        CONTENT_API.NEW_CONTENT_NOT_ASSIGNED,
        signal
      );

      const responseData = await response.json();

      categories = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getFeedHistory = async () => {
    try {
      const response = await loaderCenter.get(
        CONTENT_API.FEED_HISTORY
      );

      const responseData = await response.json();

      // categories = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  }

  assignNewContentApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.NEW_CONTENT_ASSIGN,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  attachContentApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.ATTACH_CONTENT,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  detachContentApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.DETACH_CONTENT,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  searchLocally(inputValue) {
    const searchedMoviesCats = {};
    const cats = JSON.parse(JSON.stringify(categories));
    Object.keys(cats).forEach((key) => {
      searchedMoviesCats[key] = cats[key].filter((content) =>
        content?.title?.en?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
    });
    return searchedMoviesCats;
  }
}

const contentManager = new ContentManager();

export default contentManager;
