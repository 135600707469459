import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./LinkContentsToVerticalsAndPackagesModal.module.scss";
import Button from "../../ui/button/Button";
import Line from "../../ui/line/Line";
import Loader from "../../ui/loader/Loader";
import Modal from "../../ui/modal/Modal";
import PickerView from "../../pickerViews/pickerView/PickerView";
import packageManager from "../../../backend/managers/PackageManager";
import verticalManager from "../../../backend/managers/VerticalManager";
import ExpandableMenu from "../../expandables/expandableMenu/ExpandableMenu";

function LinkContentsToVerticalsAndPackagesModal({
  isModalOpen,
  onClose,
  isModalLoading,
  onSubmit,
  title,
  initialData = { verticals: [], offers: [], addons: [], ppv: [] },
  type,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState(initialData.offers);
  const [selectedAddons, setSelectedAddons] = useState(initialData.addons);
  const [selectedPpv, setSelectedPpv] = useState(initialData.ppv);
  const [selectedVerticals, setSelectedVerticals] = useState(
    initialData.verticals
  );

  const VERTICALS = verticalManager.getVerticals();
  const PACKAGES = packageManager.getPackages();

  useEffect(() => {
    const getVerticalsApi = async () => {
      const result = await verticalManager.getVerticalsApi();
      if (result?.status === 0) {
        setVerticals(result?.data);
      }
    };
    const getPackagesApi = async () => {
      const result = await packageManager.getPackagesApi();
      if (result?.status === 0) {
        setPackages(result?.data);
      }
    };

    setIsLoading(true);
    if (VERTICALS?.length === 0) {
      getVerticalsApi();
    } else {
      setVerticals(VERTICALS);
    }
    if (PACKAGES?.length === 0) {
      getPackagesApi();
    } else {
      setPackages(PACKAGES);
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const selectVerticalsHandler = (items) => {
    setSelectedVerticals(items);
  };

  const selectItemsHandler = (items, typeId) => {
    if (typeId === "offers") {
      selectOffersHandler(items);
      // setSelectedOffers(items);
    } else if (typeId === "addons") {
      setSelectedAddons(items);
    } else {
      setSelectedPpv(items);
    }
  };

  const selectOffersHandler = (selectedItems) => {
    const selectedItemIds = selectedItems.map((item) => item.planId);

    // Determine if we are adding or removing items
    const isSelecting = selectedItems.length > selectedOffers.length;

    // If adding items, apply auto-selection logic
    if (isSelecting) {
      const lastSelectedItem = selectedItems[selectedItems.length - 1];

      // If there is no last selected item, just update state
      if (!lastSelectedItem) {
        setSelectedOffers(selectedItems);
        return;
      }

      const lastSelectedPlanId = lastSelectedItem.planId;
      const lastSelectedSuffix = parseInt(lastSelectedPlanId.match(/\d+$/), 10);

      // Get the items that should be auto-selected
      const offersToSelect = packages
        .find((pkg) => pkg.id === "offers")
        ?.content.filter((offer) => {
          const suffix = parseInt(offer.planId.match(/\d+$/), 10);
          return suffix > lastSelectedSuffix; // Select items with suffixes greater than the last selected one
        });

      // Combine selected items with auto-selected items
      const allSelectedItems = [
        ...selectedItems,
        ...(offersToSelect || []),
      ].filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id) // Ensure uniqueness
      );

      // Set the state with the combined items
      setSelectedOffers(allSelectedItems);
    } else {
      // If removing items, just update state
      const allSelectedItems = selectedOffers.filter((item) =>
        selectedItemIds.includes(item.planId)
      );

      setSelectedOffers(allSelectedItems);
    }
  };

  const submitHandler = () => {
    if (type === "newContent") {
      if (
        selectedOffers?.length > 0 ||
        selectedAddons?.length > 0 ||
        selectedPpv?.length > 0 ||
        selectedVerticals?.length > 0
      ) {
        const addonsWithPpv = [...selectedAddons, ...selectedPpv];
        onSubmit(
          selectedVerticals?.map((v) => v?.elementId),
          selectedOffers?.map((p) => p?.planId),
          addonsWithPpv?.map((p) => p?.planId)
        );
      }
    } else {
      const verticalIdsTobeAdded = selectedVerticals
        ?.filter(
          (v) => !initialData.verticals?.find((ver) => ver?.id === v?.id)
        )
        .map((v) => v?.elementId);
      const verticalIdsTobeRemoved = initialData.verticals
        ?.filter(
          (v) =>
            !selectedVerticals?.some(
              (selectedVertical) => selectedVertical?.id === v?.id
            )
        )
        .map((v) => v?.elementId);
      const offerIdsTobeAdded = selectedOffers
        ?.filter(
          (offer) => !initialData.offers?.find((o) => o?.id === offer?.id)
        )
        .map((o) => o?.planId);
      const offerIdsTobeRemoved = initialData.offers
        ?.filter(
          (o) =>
            !selectedOffers.some((selectedOffer) => selectedOffer?.id === o?.id)
        )
        .map((o) => o?.planId);
      const addonIdsTobeAdded = selectedAddons
        ?.filter(
          (a) => !initialData.addons?.find((addon) => addon?.id === a?.id)
        )
        .map((a) => a?.planId);
      const addonIdsTobeRemoved = initialData.addons
        ?.filter(
          (addon) =>
            !selectedAddons.some(
              (selectedAddon) => selectedAddon?.id === addon?.id
            )
        )
        .map((a) => a?.planId);
      const ppvIdsTobeAdded = selectedPpv
        ?.filter((p) => !initialData.ppv?.find((pp) => pp?.id === p?.id))
        .map((p) => p?.planId);
      const ppvIdsTobeRemoved = initialData.ppv
        ?.filter(
          (p) => !selectedPpv.some((selectedP) => selectedP?.id === p?.id)
        )
        .map((p) => p?.planId);
      const addonWithPpvIdsToBeAdded = [
        ...addonIdsTobeAdded,
        ...ppvIdsTobeAdded,
      ];
      const addonWithPpvIdsToBeRemoved = [
        ...addonIdsTobeRemoved,
        ...ppvIdsTobeRemoved,
      ];
      onSubmit(
        verticalIdsTobeAdded,
        offerIdsTobeAdded,
        addonWithPpvIdsToBeAdded,
        verticalIdsTobeRemoved,
        offerIdsTobeRemoved,
        addonWithPpvIdsToBeRemoved
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      className={classes.modal}
      backdropClassName={classes.backdrop}
      top="1rem"
    >
      <h2>{title}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.container}>
            <ExpandableMenu
              title={t("verticals")}
              menuContainerClassName={classes.menuContainerClassName}
            >
              <PickerView
                dataSource={verticals}
                grid={true}
                allowMultipleSelections={true}
                imageName="checkbox-checked"
                onSelectItems={selectVerticalsHandler}
                pickerCellHeight={35}
                pickerCellImageHeight={20}
                pickerCellClassName={classes.pickerCell}
                pickerViewClassName={classes.pickerView}
                initialSelectedItems={selectedVerticals}
              />
            </ExpandableMenu>
            <ExpandableMenu
              title={t("packages")}
              menuContainerClassName={classes.menuContainerClassName}
            >
              {packages?.map((type) => (
                <div key={type?.id}>
                  <p>{t(type?.id)}</p>
                  <PickerView
                    dataSource={type.content}
                    grid={true}
                    allowMultipleSelections={true}
                    imageName="checkbox-checked"
                    onSelectItems={(items) =>
                      selectItemsHandler(items, type?.id)
                    }
                    pickerCellHeight={35}
                    pickerCellImageHeight={20}
                    pickerCellClassName={classes.pickerCell}
                    pickerViewClassName={classes.pickerView}
                    initialSelectedItems={
                      type?.id === "addons"
                        ? selectedAddons
                        : type?.id === "offers"
                        ? selectedOffers
                        : selectedPpv
                    }
                  />
                </div>
              ))}
            </ExpandableMenu>
          </div>
          <Line className={classes.line} />
          <div className={classes.actionContainer}>
            {!isModalLoading ? (
              <Button title={t("btnSave")} onClick={submitHandler} />
            ) : (
              <Loader positionNotFixed={true} className={classes.loader} />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}

export default LinkContentsToVerticalsAndPackagesModal;
