import React from "react";
import classes from "./TableRow.module.scss";

function TableRow({ dataSource, index, onClick }) {
  return (
    <tr
      className={`${classes.tr} ${index && index % 2 !== 0 && classes.bgGrey}`}
      onClick={onClick}
    >
      {dataSource?.map((element) => {
        const icon =
          element?.icon ||
          (!element?.noIcon &&
            "https://pixelrayphotography.com/wp-content/uploads/2023/03/agency-logo-red.png");
        const name = element?.name;
        const content = element?.content;
        const isHidden = element?.isHidden;
        const isObfuscated = element?.isObfuscated;
        return (
          !isHidden && (
            <td key={element?.id} style={{ maxWidth: element?.maxWidth }} className={isObfuscated ? classes.obfuscated : ""}>
              <div className={classes.cellContent}>
                {icon && (
                  <img
                    alt="icon"
                    src={icon}
                    className={element?.isLogo ? classes.logo : classes.icon}
                  />
                )}
                <div className={classes.content}>
                  {content ? (
                    content?.map((c, i) => (
                      <span key={i}>
                        {c}
                        {i < content?.length - 1 && "\u2022"}
                      </span>
                    ))
                  ) : (
                    <span>{name}</span>
                  )}
                </div>
              </div>
            </td>
          )
        );
      })}
    </tr>
  );
}

export default TableRow;
