import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import classes from "./ExpandableList.module.scss";
import Circle from "../../ui/circle/Circle";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";

function ExpandableList({
  title,
  datasource,
  isExpanded = false,
  setIsExpanded,
  onRemove,
  disabled,
  className,
}) {
  const [visibleItems, setVisibleItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const dispatch = useDispatch();

  const toggleExpansion = () => {
    dispatch(setIsExpanded(!isExpanded));
    setShowAll(false);
  };
  
  useEffect(() => {
    if (isExpanded) {
      if (!showAll && datasource?.length > 7) {
        setVisibleItems(datasource.slice(0, 7));
        setHiddenItems(datasource.slice(7));
      } else {
        setVisibleItems(datasource || []);
        setHiddenItems([]);
      }
    }
  }, [isExpanded, showAll, datasource]);

  return (
    <>
      <div
        className={`${classes.expList} ${className}`}
        onClick={toggleExpansion}
      >
        <div className={classes.titleContainer}>
          <span>{title}</span>
          <Circle className={classes.dot} />
          <span>{datasource?.length || 0}</span>
        </div>
        <img
          src={require(`../../../assets/img/icons/${
            isExpanded ? "arrow-bottom" : "arrow-forward"
          }.png`)}
          alt="arrow"
        />
      </div>
      {isExpanded &&
        visibleItems?.map(
          (item, index) =>
            item?.name !== "" && (
              <div
                key={item?.name ? item?.name : item}
                className={classes.listItem}
              >
                <span>{item?.name ? item?.name : item}</span>
                {!disabled && (
                  <ButtonIcon
                    imagePath="icons/remove"
                    onClick={() => onRemove(index)}
                  />
                )}
              </div>
            )
        )}
      {isExpanded && hiddenItems?.length > 0 && (
        <div className={classes.hiddenNumber} onClick={() => setShowAll(true)}>
          + {hiddenItems?.length}
        </div>
      )}
    </>
  );
}

export default ExpandableList;
