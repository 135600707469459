import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./StoreLocation.module.scss";
import SortHelper from "../../../utils/SortHelper";
import doubleArrowImg from "../../../assets/img/icons/double-arrow-grey.svg";
import StoreLocationRow from "./StoreLocationRow";
import Toast from "../../toast/Toast";
import ConfirmationModal from "../../modals/confirmationModal/ConfirmationModal";
import Loader from "../../ui/loader/Loader";
import NoItemsView from "../../ui/noItemsView/NoItemsView";
import ContentHelper from "../../../utils/ContentHelper";
import storeLocationManager from "../../../backend/managers/preferences/StoreLocationManager";
import { errorActions } from "../../../store/reducers/Error-slice";

const TABLE_HEADERS = [
  { firstField: "name", secondField: "en", name: "emirates" },
  { firstField: "areas", name: "town" },
  { firstField: "ranking", name: "rank" },
  { name: "actions" },
];

function StoreLocation({
  dataSource,
  isCardLoading,
  onOpenEditModal,
  searchInputValue,
  onDeleteStoreLocation,
}) {
  const [locations, setLocations] = useState([]);
  const [isDesc, setIsDesc] = useState(false);
  const [isConfModalOpen, setIsConfModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isModalLoading, setIsModalLoading] = useState(false);

  // const [toast, setToast] = useState();

  const dispatch = useDispatch();

  const arrangeTableLocationsHandler = (firstField, secondField) => {
    setIsDesc((prevState) => !prevState);
    if (firstField !== "areas") {
      setLocations((prevState) =>
        SortHelper.arrangeData(prevState, !isDesc, firstField, secondField)
      );
    } else {
      setLocations((prevState) => {
        return prevState.map((location) => {
          return {
            ...location,
            areas: SortHelper.arrangeData(
              location?.areas,
              !isDesc,
              "name",
              "en"
            ),
          };
        });
      });
    }
  };

  const openDeleteConfModal = (item, type) => {
    setIsConfModalOpen(true);
    setSelectedItem({ type, data: item });
  };

  const deleteHandler = () => {
    setIsConfModalOpen(false);
    onDeleteStoreLocation(selectedItem);
  };

  useEffect(() => {
    setLocations(SortHelper.arrangeData(dataSource, isDesc, "name", "en"));
    // eslint-disable-next-line
  }, [dataSource]);

  return isCardLoading ? (
    <div className={classes.loaderContainer}>
      <Loader className={classes.loader} positionNotFixed={true} />
    </div>
  ) : (
    <>
      <div className={classes.table}>
        {locations && locations.length > 0 ? (
          <>
            <div
              className={classes.gridRow}
              style={{
                gridTemplateColumns: `repeat(${TABLE_HEADERS?.length}, 1fr)`,
              }}
            >
              {TABLE_HEADERS?.map((th) => (
                <div
                  key={th?.name}
                  className={`${classes.thContainer} ${
                    th?.name === "actions" ? classes.defaultCursor : ""
                  }`}
                  onClick={() =>
                    th?.firstField &&
                    arrangeTableLocationsHandler(
                      th?.firstField,
                      th?.secondField
                    )
                  }
                >
                  <span className={classes.thTitle}>{t(th?.name)}</span>
                  {th?.firstField && (
                    <img src={doubleArrowImg} alt="double-arrow" />
                  )}
                </div>
              ))}
            </div>
            <div
              className={classes.tbody}
              style={{ maxHeight: window.innerHeight - 295 }} //mainHeader height + page header height + ...
            >
              {locations?.map((location, index) => (
                <StoreLocationRow
                  key={index}
                  data={location}
                  index={index}
                  thLength={TABLE_HEADERS?.length}
                  onEdit={onOpenEditModal}
                  onDelete={openDeleteConfModal}
                />
              ))}
            </div>
          </>
        ) : (
          <NoItemsView
            text={
              searchInputValue && searchInputValue !== ""
                ? t("noItemsMatchSearch")
                : t("noItemsInThisSection")
            }
            showCreateText={searchInputValue!=='' ? true: false}
            className={classes.noItemsView}
            onClick={onOpenEditModal}
          />
        )}
      </div>

      {isConfModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfModalOpen}
          onClose={() => setIsConfModalOpen(false)}
          onConfirm={deleteHandler}
          text={t("delete_type", {
            type: t(selectedItem?.type),
          })}
          description={t("delete_conf_message")}
          name={
            selectedItem?.type === "emirate"
              ? ContentHelper.getNameByLang(selectedItem?.data)?.toUpperCase()
              : ContentHelper.getNameByLang(selectedItem?.data)
          }
          btnConfirmTitle={t("yes")}
          btnCancelTitle={t("cancel")}
          className={classes.confModal}
          isLoading={isModalLoading}
        >
          {selectedItem?.type === "emirate" && (
            <span className={classes.modalSpan}>
              {t("every_nested_town", {
                length: selectedItem?.data?.areas?.length,
              })}
            </span>
          )}
          <span className={classes.span}>{t("disappear_from_frontend")}</span>
        </ConfirmationModal>
      )}
      {/* {toast?.isDisplayed && (
        <Toast
          messageText={toast?.messageText}
          type={toast?.type}
          isDisplayed={toast?.isDisplayed}
          onDisplay={(isDisplayed) =>
            setToast((prevState) => {
              return { ...prevState, isDisplayed: isDisplayed };
            })
          }
          onUndo={toast?.type === "warning" ? undoDeleteHandler : null}
        />
      )} */}
    </>
  );
}

export default StoreLocation;
