import { t } from "i18next";

import classes from "./Footer.module.scss";

function Footer() {
  return (
    <footer className={classes.footer}>
      <span>{t("footer")}</span>
    </footer>
  );
}

export default Footer;
