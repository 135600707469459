import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainData: "",
};

const mainHeaderSlice = createSlice({
  name: "mainHeader",
  initialState,
  reducers: {
    setMainData(state, action) {
      state.mainData = action.payload;
    },
    getMainData(state) {
      return state.mainData;
    },
  },
});

export const mainHeaderActions = mainHeaderSlice.actions;

export default mainHeaderSlice;
