import { useState } from "react";

import classes from "./VerticalModal.module.scss";
import PickerView from "../../pickerViews/pickerView/PickerView";
import VerticalRowGeneralModal from "../vertical&RowGeneralModal/VerticalRowGeneralModal";

function VerticalModal({
  isModalOpen,
  onClose,
  isEditing,
  vertical,
  onSubmit,
  isModalLoading,
}) {
  const [isDefaultSelected, setIsDefaultSelected] = useState(
    vertical?.isDefault || false
  );

  const selectItemsHandler = (items) => {
    setIsDefaultSelected(items?.length > 0 ? true : false);
  };

  return (
    <VerticalRowGeneralModal
      isModalOpen={isModalOpen}
      onClose={onClose}
      content={vertical}
      type={"vertical"}
      isEditing={isEditing}
      isModalLoading={isModalLoading}
      onSubmit={onSubmit}
      rankFieldName={"verticalRank"}
      isDefaultSelected={isDefaultSelected}
    >
      <div className={classes.pickerViewContainer}>
        <PickerView
          dataSource={[{ id: "default" }]}
          initialSelectedItems={isDefaultSelected ? [{ id: "default" }] : []}
          onSelectItems={selectItemsHandler}
          pickerCellHeight={20}
          pickerCellImageHeight={20}
          pickerViewClassName={classes.pickerView}
          pickerCellClassName={classes.pickerCell}
        />
      </div>
    </VerticalRowGeneralModal>
  );
}

export default VerticalModal;
