import { useState, useEffect } from "react";

import classes from "./PickerView.module.scss";
import PickerCell from "./PickerCell";

// PickerView component to display and manage a list of selectable cells
function PickerView({
  dataSource,
  initialSelectedItems,
  allowMultipleSelections,
  imageName,
  pickerViewClassName,
  pickerCellClassName,
  onSelectItems = () => {},
  isDisabled,
  pickerCellHeight,
  pickerCellImageHeight,
  hasIcon,
  iconClassName,
  circleClassName,
  grid,
  maxSelectedItemsLength,
  setSelectAll,
  allowPickerCellBackgroundColor,
  preventUnselection,
  disabledImageName,
  disabledPickerCellIds,
  unchangedInitialSelectedItems,
  selectedLanguage,
}) {
  const [selectedItems, setSelectedItems] = useState(
    unchangedInitialSelectedItems || []
  );

  const selectItemHandler = (item, isChecked) => {
    if (allowMultipleSelections) {
      const updatedSelectedItems = [...selectedItems];
      if (isChecked) {
        if (
          !maxSelectedItemsLength ||
          selectedItems.length < maxSelectedItemsLength
        ) {
          updatedSelectedItems.push(item);
        }
      } else {
        const index = updatedSelectedItems.findIndex((i) => i?.id === item?.id);
        if (index !== -1) {
          updatedSelectedItems.splice(index, 1);
        }
      }
      if (setSelectAll) {
        if (updatedSelectedItems.length !== dataSource.length) {
          setSelectAll(false);
        } else {
          setSelectAll(true);
        }
      }
      setSelectedItems(updatedSelectedItems);
      onSelectItems(updatedSelectedItems);
    } else {
      let selectedItem;
      if (!preventUnselection) {
        selectedItem = isChecked ? [item] : [];
      } else {
        selectedItem = [item];
      }
      onSelectItems(selectedItem);
      setSelectedItems(selectedItem);
    }
  };

  useEffect(() => {
    if (
      initialSelectedItems &&
      JSON.stringify(initialSelectedItems) !== JSON.stringify(selectedItems)
    ) {
      setSelectedItems(initialSelectedItems);
    }
    // eslint-disable-next-line
  }, [initialSelectedItems]);

  return (
    <div
      className={`${classes.pickerView} ${pickerViewClassName} ${
        grid && classes.grid
      }`}
    >
      {/* Render PickerCell components based on the dataSource */}
      {dataSource?.map((pickerData) => (
        <PickerCell
          key={pickerData.id}
          pickerCellData={pickerData}
          onSelectItem={selectItemHandler}
          selectedItems={selectedItems}
          allowMultipleSelections={allowMultipleSelections}
          imageName={imageName}
          className={pickerCellClassName}
          isDisabled={isDisabled}
          pickerCellHeight={pickerCellHeight}
          pickerCellImageHeight={pickerCellImageHeight}
          iconPath={hasIcon && pickerData.icon}
          iconClassName={iconClassName}
          circleClassName={circleClassName}
          allowPickerCellBackgroundColor={allowPickerCellBackgroundColor}
          disabledImageName={disabledImageName}
          disabledPickerCellIds={disabledPickerCellIds}
          selectedLanguage={selectedLanguage}
        />
      ))}
    </div>
  );
}

export default PickerView;
