import VerticalRowGeneralModal from "../vertical&RowGeneralModal/VerticalRowGeneralModal";

function RowModal({
  isModalOpen,
  onClose,
  isEditing,
  row,
  onSubmit,
  isModalLoading,
}) {
  return (
    <VerticalRowGeneralModal
      isModalOpen={isModalOpen}
      onClose={onClose}
      content={row}
      type={"row"}
      isEditing={isEditing}
      isModalLoading={isModalLoading}
      onSubmit={onSubmit}
    />
  );
}

export default RowModal;
