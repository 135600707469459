import { forwardRef, useState } from "react";

import classes from "./ButtonIcon.module.scss";

const ButtonIcon = forwardRef(
  (
    {
      className,
      style,
      type,
      onClick,
      imagePath,
      activeImagePath,
      disabledImagePath,
      isDisabled,
      isSvg,
    },
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnterHandler = () => {
      setIsHovered(true);
    };

    const onMouseLeaveHandler = () => {
      setIsHovered(false);
    };

    return (
      <button
        type={type}
        className={`${classes.btn} ${className}`}
        style={{ ...style, cursor: isDisabled ? "default" : "pointer" }}
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        disabled={isDisabled}
      >
        {imagePath && (
          <img
            alt="icon"
            src={require(`../../../assets/img/${
              isDisabled
                ? disabledImagePath
                : activeImagePath && isHovered
                ? activeImagePath
                : imagePath
            }.${disabledImagePath ? "png" : isSvg ? "svg" : "png"}`)}
          />
        )}
      </button>
    );
  }
);

export default ButtonIcon;
