import { useEffect, useState } from "react";
import { t } from "i18next";
import classes from "./Help.module.scss";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import ContactUsCard from "../../../../components/helpCards/contactUsCard/ContactUsCard";
import TermsAndConditionsCard from "../../../../components/helpCards/termsAndConditionsCard/TermsAndConditionsCard";
import FAQCard from "../../../../components/helpCards/faqCard/FAQCard";
import FAQModal from "../../../../components/modals/FAQModal/FAQModal";
import ButtonTitleIcon from "../../../../components/ui/buttonTitleIcon/ButtonTitleIcon";
import useFetchData from "../../../../hooks/useFetchData";
import helpManager from "../../../../backend/managers/HelpManager";
import Loader from "../../../../components/ui/loader/Loader";
import Toast from "../../../../components/toast/Toast";
import { useDispatch } from "react-redux";
import { errorActions } from "../../../../store/reducers/Error-slice";
import { EditorState } from "draft-js";
import AddButton from "../../../../components/ui/addButton/AddButton";

const SECTIONS = [
  { id: "contact_us" },
  { id: "faq" },
  { id: "terms_conditions" },
];

function Help() {
  const [selectedCategory, setSelectedCategory] = useState(SECTIONS[0]);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isAddFAQModalOpen, setIsAddFAQModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [addingNewFAQSubsection, setAddingNewFAQSubsection] = useState(false);
  const [toastNotification, setToastNotification] = useState();

  const dispatch = useDispatch();

  const {
    data,
    isLoading: loading,
    onRefresh,
  } = useFetchData(helpManager.getHelpApi);

  const callApi = async (api, data) => {
    setIsCardLoading(true);
    const result = await api(data);
    if (result?.status === 0) {
      onRefresh();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const saveContactHandler = async (email, phone, htmlContents) => {
    callApi(helpManager.saveContactApi, {
      email,
      phone,
      details: htmlContents,
    });
  };

  const saveTermsHandler = async (htmlContents) => {
    callApi(helpManager.saveTermsApi, { terms: htmlContents });
  };

  //********************** FAQ SECTION FUNCTIONS **********************//
  const submitFAQHandler = (request, isCreate) => {
    if (!isCreate) {
      callApi(helpManager.updateFAQSectionApi, request);
    } else {
      callApi(helpManager.createFAQSectionApi, request);
    }
  };

  const reorderFAQSections = async (request) => {
    const result = await helpManager.reorderFAQSectionApi(request);
    if (result?.status === 0) {
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const reorderFAQSubSections = async (request) => {
    const result = await helpManager.reorderFAQSubSectionApi(request);
    if (result?.status === 0) {
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const deleteFAQSection = async (id) => {
    const result = await helpManager.deleteFAQSectionApi({ id });
    if (result?.status === 0) {
      setToastNotification({
        messageText: t("toast_delete_faq_section_success"),
        type: "warning",
        isDisplayed: true,
        itemType: "section",
        id: id,
      });
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const deleteFAQSubSection = async (id) => {
    const result = await helpManager.deleteFAQSubSectionApi({ id });
    if (result?.status === 0) {
      setToastNotification({
        messageText: t("toast_delete_faq_subsection_success"),
        type: "warning",
        isDisplayed: true,
        itemType: "subsection",
        id: id,
      });
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const undoDeleteSectionHandler = async (id) => {
    callApi(helpManager.restoreFAQSectionApi, { id });
  };

  const undoDeleteSubSectionHandler = async (id) => {
    callApi(helpManager.restoreFAQSubSectionApi, { id });
  };

  const openEditFAQModal = (data, index) => {
    setEditData(data);
    setExpandedIndex(index || index === 0 ? index : null);
    setIsAddFAQModalOpen(true);
  };

  const addFAQSubSection = (data) => {
    // Initialize a new section with an empty subsection
    setExpandedIndex(data.subSections.length);
    setEditData(data);
    setAddingNewFAQSubsection(true);
    setIsAddFAQModalOpen(true);
  };

  const closeFAQModalHandler = (e) => {
    setAddingNewFAQSubsection(false);
    setIsAddFAQModalOpen(false);
  };

  const handleCategoryChange = (category) => {
    if (category?.id === "faq") {
      setIsCardLoading(true);
      onRefresh();
    }

    setSelectedCategory((previous) => {
      if (previous?.id === category?.id && previous?.id !== "faq") {
        return previous;
      }
      return category;
    });
  };

  useEffect(() => {
    setIsLoading(false);
    if (!loading) {
      setIsCardLoading(false);
    }
  }, [loading, data]);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MenuCardLayout pageTitle={t("help")} noDisplayAll={true}>
            <div className={classes.actionContainer}>
              {selectedCategory?.id === "faq" && (
                <AddButton
                  title={t("add")}
                  onClick={() => openEditFAQModal(null)}
                  className={classes.btn}
                />
              )}
            </div>
            {SECTIONS.map((category) => {
              return (
                <CategoryItem
                  key={category?.id}
                  id={category?.id}
                  name={t(category?.id)}
                  onClick={() => handleCategoryChange(category)}
                  selectedCategoryId={selectedCategory?.id}
                />
              );
            })}
            {/* Card Content - Conditional on Loading State */}
            {selectedCategory?.id === "contact_us" ? (
              <ContactUsCard
                data={data?.contact}
                onSave={saveContactHandler}
                isLoading={isCardLoading}
              />
            ) : selectedCategory?.id === "faq" ? (
              <FAQCard
                data={data?.faq}
                reorderFAQSections={reorderFAQSections}
                reorderFAQSubSections={reorderFAQSubSections}
                deleteFAQSection={deleteFAQSection}
                deleteFAQSubSection={deleteFAQSubSection}
                onAddFAQSubSection={addFAQSubSection}
                onEditFAQ={openEditFAQModal}
                isCardLoading={isCardLoading}
                setIsCardLoading={setIsCardLoading}
              />
            ) : selectedCategory?.id === "terms_conditions" ? (
              <TermsAndConditionsCard
                data={data?.terms}
                onSave={saveTermsHandler}
                isLoading={isCardLoading}
              />
            ) : null}
          </MenuCardLayout>
          {isAddFAQModalOpen && (
            <FAQModal
              isModalOpen={isAddFAQModalOpen}
              onClose={closeFAQModalHandler}
              title={t("add_new_section")}
              onSubmit={submitFAQHandler}
              faqLength={data?.faq?.length}
              editData={editData}
              expandedIndex={expandedIndex}
              setAddingNewFAQSubsection={setAddingNewFAQSubsection}
              addingNewFAQSubsection={addingNewFAQSubsection}
            />
          )}

          {toastNotification?.isDisplayed && (
            <Toast
              messageText={toastNotification?.messageText}
              type={toastNotification?.type}
              isDisplayed={toastNotification?.isDisplayed}
              onDisplay={(isDisplayed) =>
                setToastNotification((prevState) => ({
                  ...prevState,
                  isDisplayed: isDisplayed,
                }))
              }
              onUndo={
                toastNotification?.itemType === "section"
                  ? () => undoDeleteSectionHandler(toastNotification?.id)
                  : () => undoDeleteSubSectionHandler(toastNotification?.id)
              }
            />
          )}
        </>
      )}
    </>
  );
}

export default Help;
