import { useEffect, useState } from "react";
import { t } from "i18next";

import ExpandableMenu from "../../../../components/expandables/expandableMenu/ExpandableMenu";
import classes from "./PackageContents.module.scss";
import packageManager from "../../../../backend/managers/PackageManager";
import Loader from "../../../../components/ui/loader/Loader";
import ChannelContentCard from "../../../../components/contentCards/channelContentCard/ChannelContentCard";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
import channelManager from "../../../../backend/managers/ChannelManager";
import ChannelModal from "../../../../components/modals/channelModal/ChannelModal";
import SeriesModal from "../../../../components/modals/seriesModal/SeriesModal";
import MovieModal from "../../../../components/modals/movieModal/MovieModal";

function PackageContents({ selectedPackage, searchInputValue }) {
  const [contents, setContents] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [isMovieModalOpen, setIsMovieModalOpen] = useState(false);
  const [isSeriesModalOpen, setIsSeriesModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState();
  const [isModalLoading, setIsModalLoading] = useState(false);

  const initialContent = packageManager.getContent();

  useEffect(() => {
    const fetchContents = async () => {
      setIsLoading(true);
      let result = [];
      const planId = selectedPackage?.planId;
      if (planId.includes("OTTTVBase")) {
        result = await packageManager.getOfferContentsApi(planId);
      } else {
        result = await packageManager.getAddonContentsApi(planId);
      }
      setContents(result);
      setIsLoading(false);
    };
    selectedPackage && fetchContents();
  }, [selectedPackage]);

  useEffect(() => {
    if (!searchInputValue) {
      setContents(initialContent);
      return;
    }
    const timeoutId = setTimeout(() => {
      setContents(packageManager.searchContentsLocally(searchInputValue));
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const openModalHandler = (content, type) => {
    setSelectedContent(content);
    type === "channel"
      ? setIsChannelModalOpen(true)
      : type === "movie"
      ? setIsMovieModalOpen(true)
      : setIsSeriesModalOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        Object.keys(contents)?.map((content, index) => {
          const contentArray = contents[content];
          const length = contentArray?.length;
          return (
            <ExpandableMenu
              key={index}
              title={t(content) + ` (${length})`}
              containerClassName={classes.containerClassName}
              menuContainerClassName={classes.menuContainerClassName}
              disableExpanded={length > 0 ? false : true}
            >
              <ul className={classes.contentList}>
                {contentArray?.map((c) => {
                  return content === "channels" ? (
                    <ChannelContentCard
                      key={c?.id}
                      content={c}
                      onEdit={() => openModalHandler(c, "channel")}
                    />
                  ) : (
                    <ContentCard
                      key={c?.id}
                      className={classes.itemCard}
                      content={c}
                      onEdit={() =>
                        openModalHandler(
                          c,
                          content === "movies" ? "movie" : "serie"
                        )
                      }
                    />
                  );
                })}
              </ul>
            </ExpandableMenu>
          );
        })
      )}
      {isChannelModalOpen && (
        <ChannelModal
          isModalOpen={isChannelModalOpen}
          onClose={() => setIsChannelModalOpen(false)}
          channel={selectedContent}
          isEditing={true}
        />
      )}
      {isMovieModalOpen && (
        <MovieModal
          isModalOpen={isMovieModalOpen}
          onClose={() => setIsMovieModalOpen(false)}
          isEditing={true}
          movie={selectedContent}
          disabled={true}
        />
      )}
      {isSeriesModalOpen && (
        <SeriesModal
          isModalOpen={isSeriesModalOpen}
          onClose={() => setIsSeriesModalOpen(false)}
          isEditing={true}
          series={selectedContent}
          disabled={true}
        />
      )}
    </>
  );
}

export default PackageContents;
