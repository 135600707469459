import { CONTENT_API, ROWS_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";
import ContentHelper from "../../utils/ContentHelper";

let ORIGINAL_VODS = [];

class RowManager {
  getRowsApi = async () => {
    try {
      const response = await loaderCenter.get(ROWS_API.ROWS);

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getRowContentsApi = async (type = null) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.CONTENTS(type));

      const responseData = await response.json();
      if (responseData?.data) {
        responseData.data = responseData?.data?.map((vod) => {
          return { ...vod, pickerName: ContentHelper.getNameByLang(vod) };
        });
        ORIGINAL_VODS = responseData.data;
      }
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getOriginalVods = () => {
    return ORIGINAL_VODS;
  };

  createRowApi = async (row) => {
    try {
      const response = await loaderCenter.post(ROWS_API.CREATE_ROW, { ...row });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateRowApi = async (row) => {
    try {
      const response = await loaderCenter.post(ROWS_API.UPDATE_ROW, { ...row });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteRowApi = async (rowId) => {
    try {
      const response = await loaderCenter.post(ROWS_API.DELETE_ROW, {
        id: rowId,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  reorderRowApi = async (updatedRows) => {
    try {
      const response = await loaderCenter.post(ROWS_API.REORDER_ROWS, {
        reorder: updatedRows,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  attachVodsToRowApi = async (rowId, vodIds) => {
    try {
      const response = await loaderCenter.post(ROWS_API.ATTACH_VODS, {
        rowId,
        vodIds,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  detachVodsApi = async (rowId, vodIds) => {
    try {
      const response = await loaderCenter.post(ROWS_API.DETACH_VODS, {
        rowId,
        vodIds,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateRankApi = async (data) => {
    try {
      const response = await loaderCenter.post(ROWS_API.UPDATE_VOD_RANK, data);

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };
}

const rowManager = new RowManager();

export default rowManager;
