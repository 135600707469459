import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./Rows.module.scss";
import Loader from "../../../../components/ui/loader/Loader";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import RowCard from "../../../../components/contentCards/rowCard/RowCard";
import rowManager from "../../../../backend/managers/RowManager";
import useFetchData from "../../../../hooks/useFetchData";
import ButtonIcon from "../../../../components/ui/buttonIcon/ButtonIcon";
import EditPageRowsRankingModal from "../../../../components/modals/editPageRowsRankingModal/EditPageRowsRankingModal";
import RowModal from "../../../../components/modals/rowModal/RowModal";
import ConfirmationModal from "../../../../components/modals/confirmationModal/ConfirmationModal";
import ContentHelper from "../../../../utils/ContentHelper";
import LinkContentsModal from "../../../../components/modals/linkContentsModal/LinkContentsModal";
import { errorActions } from "../../../../store/reducers/Error-slice";

function Rows() {
  const [pages, setPages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditRankLoading, setIsEditRankLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [openEditPageRowsRankingModal, setOpenEditPageRowsRankingModal] =
    useState(false);
  const [isRowModalOpen, setIsRowModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isConfModalOpen, setIsConfModalOpen] = useState(false);
  const [isLinkVodsModalOpen, setIsLinkVodsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    data,
    isLoading: loading,
    onRefresh: refreshRows,
  } = useFetchData(rowManager.getRowsApi);

  useEffect(() => {
    setPages(data);
    data &&
      setSelectedPage((prevState) => {
        return prevState
          ? { ...prevState, content: data[prevState?.id] }
          : { id: "type_home", content: data["type_home"] };
      });
    setIsLoading(loading);
    // eslint-disable-next-line
  }, [data]);

  const saveRowsRankingHandler = async (updatedRows) => {
    setIsEditRankLoading(true);
    const result = await rowManager.reorderRowApi(updatedRows);
    if (result?.status === 0) {
      refreshRows();
      setOpenEditPageRowsRankingModal(false);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsEditRankLoading(false);
  };

  const editNameHandler = async (row) => {
    const result = await rowManager.updateRowApi(row);
    if (result?.status === 0) {
      refreshRows();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
  };

  const createRowHandler = async (title, rank) => {
    setIsModalLoading(true);
    const data = { pageType: selectedPage?.id, title: title, order: rank };
    const result = await rowManager.createRowApi(data);
    if (result?.status === 0) {
      refreshRows();
      setIsRowModalOpen(false);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsModalLoading(false);
  };

  const deleteRowHandler = async () => {
    setIsModalLoading(true);
    const result = await rowManager.deleteRowApi(selectedRow?.id);
    if (result?.status === 0) {
      refreshRows();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsConfModalOpen(false);
    setIsModalLoading(false);
  };

  const linkVodsToRowHandler = async (vodIdsToBeAdded, vodIdsToBeRemoved) => {
    setIsModalLoading(true);
    if (vodIdsToBeAdded?.length > 0) {
      const result1 = await rowManager.attachVodsToRowApi(
        selectedRow?.id,
        vodIdsToBeAdded
      );
      if (result1?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result1?.message || result1));
        setIsModalLoading(false);
        return;
      }
    }
    if (vodIdsToBeRemoved?.length > 0) {
      const result2 = await rowManager.detachVodsApi(
        selectedRow?.id,
        vodIdsToBeRemoved
      );
      if (result2?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result2?.message || result2));
        setIsModalLoading(false);
        return;
      }
    }
    if (vodIdsToBeAdded?.length === 0 && vodIdsToBeRemoved?.length === 0) {
      setIsModalLoading(false);
      setIsLinkVodsModalOpen(false);
      return;
    }
    setIsModalLoading(false);
    setIsLinkVodsModalOpen(false);
    refreshRows();
  };

  const editContentRankHandler = async (e, rank, vodId, rowId) => {
    if (e.key === "Enter") {
      setIsModalLoading(true);
      const result = await rowManager.updateRankApi({
        rowId,
        vodId,
        rank,
      });
      if (result?.status === 0) {
        refreshRows();
      } else {
        dispatch(errorActions.setErrorMessage(result?.message || result));
      }
      setIsModalLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MenuCardLayout
            pageTitle={t("rows")}
            menuTitle={t("pages")}
            noDisplayAll={true}
          >
            <div className={classes.btnRankContainer}>
              <ButtonIcon
                className={classes.btn}
                imagePath="buttons/rank"
                activeImagePath="buttons/rank-active"
                onClick={() =>
                  selectedPage?.content?.length > 0 &&
                  setOpenEditPageRowsRankingModal(true)
                }
              />
              <ButtonIcon
                className={classes.btn}
                imagePath="buttons/add"
                activeImagePath="buttons/add-active"
                onClick={() => setIsRowModalOpen(true)}
              />
            </div>
            {Object.keys(pages)?.map((key) => {
              return (
                <CategoryItem
                  key={key}
                  id={key}
                  name={t(key)}
                  number={pages[key]?.length}
                  onClick={() => {
                    setSelectedPage({ id: key, content: pages[key] });
                  }}
                  selectedCategoryId={selectedPage?.id}
                />
              );
            })}
            {selectedPage && selectedPage?.content?.length > 0 ? (
              <ul className={classes.vodList}>
                {selectedPage?.content?.map((row) => (
                  <RowCard
                    key={row?.id}
                    row={row}
                    onEdit={editNameHandler}
                    onAdd={() => {
                      setSelectedRow(row);
                      setIsLinkVodsModalOpen(true);
                    }}
                    onOpenDeleteConf={() => {
                      setSelectedRow(row);
                      setIsConfModalOpen(true);
                    }}
                    onEditContentRank={editContentRankHandler}
                    isModalLoading={isModalLoading}
                  />
                ))}
              </ul>
            ) : (
              !isLoading && <NoItemsView />
            )}
          </MenuCardLayout>
          {openEditPageRowsRankingModal && (
            <EditPageRowsRankingModal
              isModalOpen={openEditPageRowsRankingModal}
              onClose={() => setOpenEditPageRowsRankingModal(false)}
              pageData={selectedPage}
              onSaveRowsRanking={saveRowsRankingHandler}
              isEditRankLoading={isEditRankLoading}
            />
          )}
          {isRowModalOpen && (
            <RowModal
              isModalOpen={isRowModalOpen}
              onClose={() => setIsRowModalOpen(false)}
              onSubmit={createRowHandler}
              isModalLoading={isModalLoading}
            />
          )}
          {isConfModalOpen && (
            <ConfirmationModal
              isModalOpen={isConfModalOpen}
              onClose={() => setIsConfModalOpen(false)}
              onConfirm={deleteRowHandler}
              text={t("delete_type", {
                type: t("row"),
              })}
              description={t("delete_type_conf_message", {
                type: t("row"),
              })}
              name={ContentHelper.getNameByLang(selectedRow)}
              btnConfirmTitle={t("yes")}
              btnCancelTitle={t("cancel")}
              isLoading={isModalLoading}
            />
          )}
          {isLinkVodsModalOpen && (
            <LinkContentsModal
              isModalOpen={isLinkVodsModalOpen}
              onClose={() => setIsLinkVodsModalOpen(false)}
              isModalLoading={isModalLoading}
              typeData={selectedRow}
              onSubmit={linkVodsToRowHandler}
              api={() => rowManager.getRowContentsApi("vod")}
              getOriginalDataSource={rowManager.getOriginalVods}
              title={t("add_vods_to_type", {
                type: ContentHelper.getNameByLang(selectedRow),
              })}
              initialContents={selectedRow?.vods}
            />
          )}
        </>
      )}
    </>
  );
}

export default Rows;
