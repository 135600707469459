import { USER_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

let users = [];

class AccountManager {
  getUsersApi = async () => {
    try {
      const response = await loaderCenter.get(USER_API.USERS);

      const responseData = await response.json();
      users = responseData?.data;
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getAccountByIdApi = async (accountId) => {
    try {
      const response = await loaderCenter.get(USER_API.USER(accountId));

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  getUserProfileApi = async () => {
    try {
      const response = await loaderCenter.get(USER_API.USER_PROFILE);

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  updateUserProfileEmailApi = async (credentials) => {
    try {
      const response = await loaderCenter.post(USER_API.UDPATE_PROFILE_EMAIL, {
        currentEmail: credentials.currentCredential,
        email: credentials.newCredential,
        emailConfirmation: credentials.confirmCredential,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  updateUserProfilePasswordApi = async (credentials) => {
    try {
      const response = await loaderCenter.post(
        USER_API.UDPATE_PROFILE_PASSWORD,
        {
          currentPassword: credentials.currentCredential,
          password: credentials.newCredential,
          passwordConfirmation: credentials.confirmCredential,
        }
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  updateUserProfileNameApi = async (name) => {
    try {
      const response = await loaderCenter.post(USER_API.UDPATE_PROFILE_NAME, {
        name: name,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  createAccountApi = async (credentials) => {
    try {
      const response = await loaderCenter.post(USER_API.CREATE_USER, {
        ...credentials,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  updateAccountApi = async (credentials) => {
    try {
      const response = await loaderCenter.post(USER_API.UPDATE_USER, {
        ...credentials,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  deleteAccountApi = async (accountId) => {
    try {
      const response = await loaderCenter.post(USER_API.DELETE_USER, {
        id: accountId,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  resetAccountPasswordApi = async (accountId) => {
    try {
      const response = await loaderCenter.post(USER_API.RESET_USER_PASSWORD, {
        id: accountId,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  searchUsers = (inputValue) => {
    return users.filter((user) =>
      user.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
}

const accountManager = new AccountManager();

export default accountManager;
