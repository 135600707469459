import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { ReactComponent as ViewIcon } from "../../../../assets/img/icons/view-icon.svg";
import { ReactComponent as SubmitIcon } from "../../../../assets/img/buttons/submit.svg";
import classes from './SubscriptionTable.module.scss'; 
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DateFormatSubscription from '../dateFormat/DateFormatSubscription';
import { t } from "i18next";

function SubscriptionTable({ data, onClick }) {
  const [sortDirection, setSortDirection] = useState('desc'); 
  const [sortField, setSortField] = useState('subscriberName'); 

  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  const sortedData = [...data].sort((a, b) => {
    const compareA = a[sortField] ? a[sortField].toString() : '';
    const compareB = b[sortField] ? b[sortField].toString() : '';
    if (compareA < compareB) return sortDirection === 'asc' ? -1 : 1;
    if (compareA > compareB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
  
  const handleButtonClick = (type, subscription) => () => onClick(type, subscription);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small">
        <TableHead style={{ backgroundColor: "#f1ecfb"}}>
          <TableRow>
            <TableCell onClick={() => handleSort('subscriberName')} style={{ cursor: 'pointer', alignItems: 'center'}}>
              {t("name")}
              <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '5px' }}>
                <ArrowDropUpIcon style={{color: sortField === 'subscriberName' && sortDirection === 'asc' ? '#000' : '#ccc',margin: '-4'}}/>
                <ArrowDropDownIcon style={{color: sortField === 'subscriberName' && sortDirection === 'desc' ? '#000' : '#ccc',margin: '-4'}}/>
              </div>
            </TableCell>
            <TableCell onClick={() => handleSort('subscriptionCustomer.customerName')} style={{ cursor: 'pointer', alignItems: 'center' }}>
              {t("company")}
              <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '5px', alignItems: 'flex-start' }}>
                <ArrowDropUpIcon style={{ color: sortField === 'subscriptionCustomer.customerName' && sortDirection === 'asc' ? '#000' : '#ccc', margin: '-4' }} />
                <ArrowDropDownIcon style={{ color: sortField === 'subscriptionCustomer.customerName' && sortDirection === 'desc' ? '#000' : '#ccc', margin: '-4' }} />
              </div>
            </TableCell>
            <TableCell onClick={() => handleSort('offer')} style={{ cursor: 'pointer' }}>
              {t("offer")}
              <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginLeft: '4px' }}>
                <ArrowDropUpIcon style={{ color: sortField === 'offer' && sortDirection === 'asc' ? '#000' : '#ccc', margin: '-4' }} />
                <ArrowDropDownIcon style={{ color: sortField === 'offer' && sortDirection === 'desc' ? '#000' : '#ccc', margin: '-4' }} />
              </div>
            </TableCell>
            <TableCell onClick={() => handleSort('addOns')} style={{ cursor: 'pointer' }}>
              {t("add_ons")}
              <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginLeft: '4px' }}>
                <ArrowDropUpIcon style={{ color: sortField === 'addOns' && sortDirection === 'asc' ? '#000' : '#ccc', margin: '-4' }} />
                <ArrowDropDownIcon style={{ color: sortField === 'addOns' && sortDirection === 'desc' ? '#000' : '#ccc', margin: '-4' }} />
              </div>
            </TableCell>
            <TableCell onClick={() => handleSort('isActive')} style={{ cursor: 'pointer' }}>
              {t("status")}
              <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginLeft: '4px' }}>
                <ArrowDropUpIcon style={{ color: sortField === 'isActive' && sortDirection === 'asc' ? '#000' : '#ccc', margin: '-4' }} />
                <ArrowDropDownIcon style={{ color: sortField === 'isActive' && sortDirection === 'desc' ? '#000' : '#ccc', margin: '-4' }} />
              </div>
            </TableCell>
            <TableCell onClick={() => handleSort('lastAction')} style={{ cursor: 'pointer' }}>
              {t("last_action")}
              <div style={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', marginLeft: '4px' }}>
                <ArrowDropUpIcon style={{ color: sortField === 'lastAction' && sortDirection === 'asc' ? '#000' : '#ccc', margin: '-4' }} />
                <ArrowDropDownIcon style={{ color: sortField === 'lastAction' && sortDirection === 'desc' ? '#000' : '#ccc', margin: '-4' }} />
              </div>
            </TableCell>
            <TableCell align="center">{t("actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row,index) => (
            <TableRow key={row.id} hover style={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#E4E4E4',  height: '60px'}}>
              <>
                <TableCell sx={{ width: '15%'  }}>{row.subscriberName}</TableCell>
                <TableCell sx={{ width: '15%' , color: "transparent", textShadow: "0 0 5px rgba(0, 0, 0, 0.3)", userSelect: "none", opacity: 1 }}>{row.subscriptionCustomer.customerName}</TableCell>
                <TableCell sx={{ width: '15%'  }}>{row.offer}</TableCell>
                <TableCell sx={{ width: '15%' }}>
                <Tooltip title={row.addOns && row.addOns.length > 0 ? row.addOns.join(', ') : ''} arrow>
                    <span>
                      {row.addOns && row.addOns.length > 0 
                        ? row.addOns.slice(0, 2).join(', ') + (row.addOns.length > 2 ? '...' : '') 
                        : ''}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: '15%'  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: row.isActive ? '#08c34b' : (row.isActive === null ? '#858b99' : '#d82316'), // Using gray for disabled status
                        marginRight: '8px'
                      }}
                    />
                    {row.isActive === null ? 'Disabled' : row.isActive ? 'Online' : 'Offline'}
                  </div>
                </TableCell>
                <TableCell sx={{ width: '15%'  }}>{DateFormatSubscription(row.lastAction)}</TableCell>
                <TableCell align="center" sx={{ width: '15%'  }}>
                  {
                    row.macAddress === null ?
                      <div onClick={handleButtonClick("submit",row)} style={{ cursor: 'pointer' }}>
                        <SubmitIcon />
                      </div> : 
                      <div onClick={handleButtonClick("edit",row)} style={{ cursor: 'pointer' }}>
                        <ViewIcon />
                      </div> 
                  }
                  
                </TableCell>
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    
    </TableContainer>
  );
}

export default SubscriptionTable;
