import { useEffect, useRef } from "react";
import classes from "./Card.module.scss";

const Card = ({
  id,
  children,
  className,
  style,
  onClick,
  draggable,
  onDragOver,
  onDragStart,
  onDragEnd,
  onDrag,
  onDrop,
  isOpen,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (isOpen) ref.current.scrollTo(0, 0);
  }, [isOpen]);
  
  return (
    <div
      ref={ref}
      id={id}
      style={style}
      className={`${classes.card} ${className}`}
      onClick={onClick}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrag={onDrag}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {children}
    </div>
  );
};

export default Card;
