import React from 'react';

const formatTime = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);
};

const DateFormatSubscription = (lastAction) => {
  if (!lastAction || isNaN(new Date(lastAction).getTime())) {
    return ''; 
  }

  const actionDate = new Date(lastAction);
  const now = new Date();

  const diffTime = now - actionDate;
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return <span>Today {formatTime(actionDate)}</span>;
  } else if (diffDays === 1) {
    return <span>Yesterday {formatTime(actionDate)}</span>;
  } else {
    const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(actionDate);
    return <span>{weekday} {formatTime(actionDate)}</span>;
  }
};

export default DateFormatSubscription;
