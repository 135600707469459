class Message {
  constructor(message) {
    this.messageData = message;
    this.id = message?.id;
    this.type = message?.type;
    this.pin = message?.pin;
    this.isSent = message?.isSent;
    this.timeToSend = message?.timeToSend;
    this.messageStatuses = message?.messageStatuses || [];
  }

  // getContentByLang(lang) {
  //   // get selected language, en by default
  //   const selectedLang = lang ? lang : "en";
  //   const translation = this.messageData?.translation;
  //   if (translation) {
  //     const foundedLang = translation[selectedLang];
  //     if (foundedLang) {
  //       return {
  //         name: foundedLang.name,
  //         body: foundedLang.defaultContent,
  //       };
  //     } else {
  //       const firstLang = translation[Object.keys(translation)[0]];
  //       return {
  //         name: firstLang.name,
  //         body: firstLang.defaultContent,
  //       };
  //     }
  //   }
  // }

  getTitleByLang(lang) {
    // get selected language, en by default
    const selectedLang = lang ? lang : "en";
    const title = this.messageData?.title;
    if (title) {
      const foundedLangName = title[selectedLang];
      if (foundedLangName) {
        return foundedLangName;
      } else {
        const firstLangName = title[Object.keys(title)[0]];
        return firstLangName;
      }
    }
  }

  getContentByLang(lang) {
    // get selected language, en by default
    const selectedLang = lang ? lang : "en";
    const content = this.messageData?.content;
    if (content) {
      const foundedLangName = content[selectedLang];
      if (foundedLangName) {
        return foundedLangName;
      } else {
        const firstLangName = content[Object.keys(content)[0]];
        return firstLangName;
      }
    }
  }

  getStatuses() {
    const read = [],
      unread = [],
      all = [];
    const customers = this.messageData?.customers || {};
    Object.keys(customers)?.forEach((key) => {
      const custRead = [],
        custUnread = [],
        custAll = [];
      customers[key]?.messages?.forEach((sub) => {
        if (sub.isRead === true) {
          custRead.push(sub);
        } else {
          custUnread.push(sub);
        }
        custAll.push(sub);
      });
      if (custRead.length > 0) {
        read.push({
          id: key,
          name: customers[key]?.name,
          read: custRead,
        });
      }
      if (custUnread.length > 0) {
        unread.push({
          id: key,
          name: customers[key]?.name,
          unread: custUnread,
        });
      }
      all.push({
        id: key,
        name: customers[key]?.name,
        read: custRead,
        unread: custUnread,
        all: custAll,
      });
    });
    return {
      all: all,
      read: read,
      unread: unread,
    };
  }
}

export default Message;
