import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import Cookies from "js-cookie";

import { errorActions } from "../store/reducers/Error-slice";

/**
 * Custom hook for fetching data from an API endpoint.
 * @param {Function} api - Function that makes the API request
 * @returns {Object} - Object containing fetched data, loading state, error, and refresh function
 */

export default function useFetchData(api) {
  // State variables to manage fetched data, loading status, error, and a key to trigger useEffect
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [key, setKey] = useState(0);

  // AbortController to handle aborting fetch requests
  // const abortController = new AbortController();
  // const { signal } = abortController;
  const abortControllerRef = useRef(null);
  const location = useLocation(); // Access location object from React Router

  // Function to refresh data fetch by incrementing key to trigger useEffect
  const refresh = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const setIsLoadingHandler = (value) => {
    setIsLoading(value);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true); // Set loading state to true when starting the fetch

    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    // Function to fetch data asynchronously using the provided API function
    const getData = async () => {
      try {
        const result = await api(signal); // Make the API request with AbortController's signal
        if (result?.status === 0 || result?.statusCode === 0) {
          setData(result.data); // Update the fetched data
        } else {
          if (result?.message === "Not Authorized") {
            Cookies.remove("token");
            localStorage.clear();
            navigate("/login");
            return;
          }
          if (result?.message !== "signal is aborted without reason") {
            dispatch(errorActions.setErrorMessage(result.message || result));
            setError(result.message || result);
          }
          setData([]);
        }
      } catch (err) {
        // let message = err?.message;
        // if (err?.message === "Failed to fetch") {
        //   message = t("sww");
        // }
        if (err?.message !== "signal is aborted without reason") {
          dispatch(errorActions.setErrorMessage(t("sww")));
        }
        setData([]);
        setError(err?.message || err);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching (whether success or error)
      }
    };

    getData(); // Initiate the data fetch

    // Cleanup function to abort fetch on unmount or when any of the dependencies change
    return () => {
      // abortController.abort(); // Abort the fetch request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line
  }, [api, location.key, key]); // Dependencies for the useEffect, including API, location key, and key for triggering fetch

  // Return fetched data, loading state, error, and refresh function
  return {
    data,
    isLoading,
    error,
    onRefresh: refresh,
    setIsLoading: setIsLoadingHandler,
  };
}
