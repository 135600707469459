import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./Series.module.scss";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import useFetchData from "../../../../hooks/useFetchData";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
// import Logger from "../../../../utils/Logger";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import SeriesModal from "../../../../components/modals/seriesModal/SeriesModal";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
// import ContentHelper from "../../../../utils/ContentHelper";
import SortHelper from "../../../../utils/SortHelper";
import SearchSortView from "../../../../components/ui/searchSort/searchSortView/SearchSortView";
import serieManager from "../../../../backend/managers/SerieManager";
import Loader from "../../../../components/ui/loader/Loader";
// import MainHeader from "../../../../components/layout/mainHeader/MainHeader";
// import PageHeader from "../../../../components/pageHeader/PageHeader";
// import ConfirmationModal from "../../../../components/modals/confirmationModal/ConfirmationModal";

function Series() {
  const SORT_OPTIONS = SortHelper.getContentSortOptions();

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSeries, setSelectedSeries] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isSeriesModalOpen, setIsSeriesModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[0]);
  // const [hoveredCategoryId, setHoveredCategoryId] = useState();
  // const [showExpandableInput, setShowExpandableInput] = useState(false);
  // const [selectedItems, setSelectedItems] = useState([]);
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [isDragging, setIsDragging] = useState(false);

  const seriesLength = (categories && categories[0]?.series?.length) || 0;

  const { data, isLoading: loading } = useFetchData(
    serieManager.getSeriesCategoriesApi
  );

  // const { isLoading: loading } = useFetchData(seriesManager.getSeriesApi);

  useEffect(() => {
    setCategories(data);
    setSelectedCategory((prevState) => {
      if (prevState) {
        prevState.series = SortHelper.sortData(
          prevState?.series,
          selectedSortType?.id
        );
        return prevState;
      }
      if (data.length > 0) {
        data[0].series = SortHelper.sortData(
          data[0]?.series,
          selectedSortType?.id
        );
      }
      return data[0];
    });
    setIsLoading(loading);
    // eslint-disable-next-line
  }, [data]);

  // const openExpandableInputHandler = () => {
  //   setShowExpandableInput(true);
  // };

  // const closeExpandableInputHandler = () => {
  //   setShowExpandableInput(false);
  //   setCategories(data);
  //   setSelectedCategory((prevState) =>
  //     seriesManager.getLocalCategoryById(prevState?.id)
  //   );
  // };

  // const openSearchExpandableInput = () => {
  //   return { placeholder: t("search"), value: "" };
  // };

  // const searchHandler = (inputValue) => {
  //   setSearchInputValue(inputValue);
  // };

  useEffect(() => {
    if (!searchInputValue) {
      setCategories(data);
      setSelectedCategory((prevState) => {
        const cat = serieManager.getLocalCategoryById(prevState?.id);
        if (cat && cat?.series)
          cat.series = SortHelper.sortData(cat.series, selectedSortType?.id);
        return cat;
      });
      return;
    }
    const timeoutId = setTimeout(() => {
      setCategories(serieManager.searchLocally(searchInputValue));
      setSelectedCategory((prevState) => {
        const updatedCategory = serieManager.getLocalSearchedCategoryById(
          prevState.id
        );
        updatedCategory.series = SortHelper.sortData(
          updatedCategory.series,
          selectedSortType?.id
        );
        return updatedCategory;
      });
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const openSeriesModalHandler = (s, isEditingValue) => {
    setSelectedSeries(s);
    setIsSeriesModalOpen(true);
    setIsEditing(isEditingValue);
  };

  // const openDeleteSeriesHandler = (s) => {
  //   setSelectedSeries(s);
  //   setIsDeleteModalOpen(true);
  // };

  // const deleteSeriesHandler = () => {
  //   if (selectedItems.length > 0) {
  //     selectedItems.forEach((item) => {
  //       seriesManager.deleteSeriesApi(item.id);
  //     });
  //   } else {
  //     seriesManager.deleteSeriesApi(selectedSeries?.id);
  //   }
  //   setIsDeleteModalOpen(false);
  // };

  // const buttons = [
  //   {
  //     id: "delete",
  //     image: "delete",
  //     hidden: true,
  //     action: openDeleteSeriesHandler,
  //   },
  //   {
  //     id: "add-in",
  //     image: "add-in",
  //     action: () => {},
  //     hasLeftBorder: false,
  //   },
  //   {
  //     id: "search",
  //     image: "search",
  //     expandable: true,
  //     action: openSearchExpandableInput,
  //   },
  //   {
  //     id: "add",
  //     action: () => openSeriesModalHandler(null, false),
  //   },
  // ];

  // const [pageHeaderButtons, setPageHeaderButtons] = useState(buttons);

  // const menuButtons = [
  //   {
  //     id: "add",
  //     imagePath: "icons/add",
  //     activeImagePath: "icons/add-active",
  //     action: () => {},
  //   },
  // ];

  // const selectItemsHandler = (item) => {
  //   if (!selectedItems.includes(item)) {
  //     setSelectedItems((prevState) => [...prevState, item]);
  //   } else {
  //     setSelectedItems((prevState) =>
  //       prevState.filter((c) => c.id !== item.id)
  //     );
  //   }
  // };

  // const unselectAllHandler = () => {
  //   setSelectedItems([]);
  // };

  // const setIsDraggingHandler = (isDragging) => {
  //   setIsDragging(isDragging);
  // };

  // const dragHoverCategoryHandler = (catId) => {
  //   setHoveredCategoryId(catId);
  // };

  // const dropHandler = (e) => {
  //   const droppedItems = JSON.parse(e.dataTransfer.getData("selectedItems"));
  //   droppedItems.forEach((series) => {
  //     seriesManager.dropSeriesApi(series.id, hoveredCategoryId);
  //   });
  //   refreshCategories();
  //   setSelectedItems([]);
  // };

  // useEffect(() => {
  //   if (selectedItems.length > 0) {
  //     const updatedButtons = [...buttons];
  //     updatedButtons[0].hidden = false;
  //     updatedButtons[1].hasLeftBorder = true;
  //     setPageHeaderButtons(updatedButtons);
  //   } else {
  //     setPageHeaderButtons(buttons);
  //   }
  //   // eslint-disable-next-line
  // }, [selectedItems]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategory((prevState) => {
        const sortedSeries = SortHelper.sortData(
          prevState?.series || [],
          selectedSortType?.id
        );
        return { ...prevState, series: sortedSeries };
      });
    }
    // eslint-disable-next-line
  }, [selectedSortType]);

  return (
    <>
      <div className={classes.page}>
        {/* <MainHeader /> */}
        {/* <PageHeader
          pageTitle={t("series") + " (" + seriesLength + ")"}
          pageHeaderButtons={pageHeaderButtons}
          showExpandableInput={showExpandableInput}
          onOpenExpandableInput={openExpandableInputHandler}
          onCloseExpandableInput={closeExpandableInputHandler}
          onSearch={searchHandler}
        /> */}
        {isLoading ? (
          <Loader />
        ) : (
          <MenuCardLayout
            // buttons={menuButtons}
            pageTitle={t("series") + " (" + seriesLength + ")"}
            displayAllNumber={
              categories &&
              categories.length > 0 &&
              categories[0]?.series?.length
            }
            isDisplayAllSelected={selectedCategory?.id === "display_all"}
            onSelectDisplayAll={() => {
              const selectedCat =
                categories && categories.length > 0 ? categories[0] : null;
              selectedCat.series = SortHelper.sortData(
                selectedCat?.series,
                selectedSortType?.id
              );
              setSelectedCategory(selectedCat);
            }}
          >
            <SearchSortView
              hasSort={true}
              options={SORT_OPTIONS}
              selectedSortType={selectedSortType}
              onSelectSortType={setSelectedSortType}
              searchInputValue={searchInputValue}
              searchInputPlaceholder={t("searchSerie")}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={(value) => setSearchInputValue(value)}
            />
            {categories.map((category) => {
              return (
                category.id !== "display_all" && (
                  <CategoryItem
                    key={category?.id}
                    id={category?.id}
                    name={
                      category?.id !== "display_all"
                        ? category?.name
                        : t(category?.id)
                    }
                    number={category?.series?.length}
                    onClick={() => {
                      category.series = SortHelper.sortData(
                        category?.series,
                        selectedSortType?.id
                      );
                      setSelectedCategory(category);
                    }}
                    selectedCategoryId={selectedCategory?.id}
                    // isDragging={isDragging}
                    // onDrop={dropHandler}
                    // onHoverCategory={dragHoverCategoryHandler}
                    // dragHoveredCatId={hoveredCategoryId}
                  />
                )
              );
            })}
            {selectedCategory?.series && selectedCategory?.series.length > 0 ? (
              <ul className={classes.list}>
                {selectedCategory?.series?.map((s) => (
                  <ContentCard
                    className={classes.itemCard}
                    key={s?.id}
                    content={serieManager.getLocalSeriesById(s?.id)}
                    onEdit={() =>
                      openSeriesModalHandler(
                        serieManager.getLocalSeriesById(s?.id),
                        true
                      )
                    }
                    // onDelete={() =>
                    //   openDeleteSeriesHandler(
                    //     seriesManager.getLocalSeriesById(s?.id)
                    //   )
                    // }
                    // selectedItems={selectedItems}
                    // onSelectItems={selectItemsHandler}
                    // onSetIsDragging={setIsDraggingHandler}
                    // setHoveredCategoryId={setHoveredCategoryId}
                    // ghostName={t("series")}
                  />
                ))}
              </ul>
            ) : (
              !isLoading && (
                <NoItemsView
                  text={t("noItemsRow")}
                  // onClick={() => openSeriesModalHandler(null, false)}
                />
              )
            )}
          </MenuCardLayout>
        )}
      </div>
      {isSeriesModalOpen && (
        <SeriesModal
          isModalOpen={isSeriesModalOpen}
          onClose={() => setIsSeriesModalOpen(false)}
          isEditing={isEditing}
          series={isEditing && selectedSeries}
          disabled={true}
        />
      )}
    </>
  );
}

export default Series;
