import { t } from "i18next";
import { CONTENT_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

let PACKAGES = [],
  PACKAGE = {};

class PackageManager {
  getPackagesApi = async (signal) => {
    try {
      const response = await loaderCenter.get(CONTENT_API.PACKAGES, signal);

      const responseData = await response.json();
      PACKAGES = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getPackages = () => {
    return PACKAGES;
  };

  searchLocally(inputValue) {
    const searchedMoviesCats = [];
    const cats = JSON.parse(JSON.stringify(PACKAGES));
    cats.forEach((cat) => {
      cat.content = cat?.content.filter(
        (p) =>
          p?.sku_name?.toLowerCase()?.includes(inputValue.toLowerCase()) ||
          p?.skuName?.toLowerCase()?.includes(inputValue.toLowerCase())
      );
      searchedMoviesCats.push(cat);
    });

    return searchedMoviesCats;
  }

  getOfferContentsApi = async (offerId) => {
    try {
      const response = await loaderCenter.get(
        CONTENT_API.OFFER_CONTENTS(offerId)
      );

      const responseData = await response.json();

      PACKAGE = responseData?.data;

      return responseData.data;
    } catch (err) {
      throw err;
    }
  };

  getAddonContentsApi = async (addonId, signal) => {
    try {
      const response = await loaderCenter.get(
        CONTENT_API.ADDON_CONTENTS(addonId),
        signal
      );

      const responseData = await response.json();

      PACKAGE = responseData?.data;

      return responseData.data;
    } catch (err) {
      throw err;
    }
  };

  searchContentsLocally(inputValue) {
    const searchPackage = {};
    const p = JSON.parse(JSON.stringify(PACKAGE));
    Object.keys(p).forEach((content) => {
      p[content] = p[content]?.filter((c) =>
        c?.title?.en?.toLowerCase()?.includes(inputValue.toLowerCase())
      );
      searchPackage[content] = p[content];
    });
    return searchPackage;
  }

  getContent() {
    return PACKAGE;
  }

  getMedia() {
    return [
      { id: "vod", name: t("movie") },
      { id: "series", name: t("series") },
      { id: "channel", name: t("channel") },
    ];
  }
}

const packageManager = new PackageManager();

export default packageManager;
