import { t } from "i18next";

import classes from "./AddButton.module.scss";
import ButtonTitleIcon from "../buttonTitleIcon/ButtonTitleIcon";

function AddButton({ className, onClick, isDisabled }) {
  return (
    <ButtonTitleIcon
      className={`${classes.btn} ${className}`}
      title={t("add")}
      imagePath={"icons/add-white"}
      position={"right"}
      isImgSvg={true}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}

export default AddButton;
