import classes from "./LegendItem.module.scss";

function LegendItem({ color, text }) {
  return (
    <div className={classes.item}>
      <div className={classes.color} style={{ backgroundColor: color }}></div>
      <span>{text}</span>
    </div>
  );
}

export default LegendItem;
