import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./ChannelModal.module.scss";
import Modal from "../../ui/modal/Modal";
import ContentModel from "../../../models/ContentModel";
import Button from "../../ui/button/Button";
import LanguagesList from "../../languagesList/LanguagesList";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import PickerView from "../../pickerViews/pickerView/PickerView";
import channelManager from "../../../backend/managers/ChannelManager";
import Line from "../../ui/line/Line";
import Loader from "../../ui/loader/Loader";
import LinkContentsToVerticalsAndPackagesModal from "../linkContentsToVerticalsAndPackagesModal/LinkContentsToVericalsAndPackagesModal";
import contentManager from "../../../backend/managers/ContentManager";
import LinkChannelWithEPGModal from "../linkChannelWithEPGModal/LinkChannelWithEPGModal";
import { errorActions } from "../../../store/reducers/Error-slice";

function ChannelModal({ isModalOpen, isEditing, onClose, channel }) {
  const [updatedChannel, setUpdatedChannel] = useState(channel);

  const contentModel = new ContentModel(updatedChannel);
  const data = contentModel.data;

  const [names, setNames] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [logoFile, setLogoFile] = useState();
  const [rank, setRank] = useState("");
  const [channelUrl, setChannelUrl] = useState("");
  const [selectedCatValues, setSelectedCatValues] = useState([]);
  const [isRankTaken, setIsRankTaken] = useState(false);
  const [openLinkContentsModal, setOpenLinkContentsModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [openLinkChannelWithEpgModal, setOpenLinkChannelWithEpgModal] =
    useState(false);

  const ref = useRef();

  const fetchData = async () => {
    if (isEditing) {
      setIsLoading(true);
      const c = await channelManager.getChannelByIdApi(channel?.id);
      setUpdatedChannel(c);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isEditing && fetchData();
  }, [isEditing]);

  const logoImage = logoFile
    ? logoFile
    : isEditing
    ? contentModel.getPosterImage()
    : require("../../../assets/img/card-content-logo.png");

  const categories = channelManager.getCategoriesWithoutDisplayAll() || [];
  const langListDataSource = data?.languages || ["en", "ar"];
  const cats =
    data?.liveTvGenres?.length > 0 ? data?.liveTvGenres : [{ id: 0 }];
  const age = data?.age
  const offers = data?.package;
  const addOns = data?.addOn?.filter((e) => e?.isOneTime === false);
  const verticals = data?.verticals;
  const ppv = data?.addOn?.filter((e) => e?.isOneTime === true);
  const channelId = data?.channelId;

  const logoImageChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setLogoFile(URL.createObjectURL(file));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    onClose();
  };

  const selectItemsHandler = (selectedItems) => {
    setSelectedCatValues(selectedItems);
  };

  const changeRankInputHandler = (e) => {
    const value = +e.target.value;
    value >= 0 && setRank(value);
    channelManager.prepareRankMapAndGaps();
    setIsRankTaken(channelManager.isRankTaken(value));
  };

  useEffect(() => {
    setSelectedCatValues(isEditing ? cats : []);
    // setLogoFile();
    const object = {};
    isEditing &&
      data?.title &&
      Object.keys(data?.title).forEach((lang) => {
        object[lang] = contentModel.getNameByLang(lang);
      });
    setNames(isEditing ? object : { en: "", fr: "", ar: "" });
    setSelectedLanguage("en");
    // setChannelUrl(isEditing ? data?.defaultUrl : "");
    // setRank(isEditing ? data?.ranking : "");
    // eslint-disable-next-line
  }, [updatedChannel, isModalOpen, isEditing]);

  const dispatch = useDispatch();

  const openEditAttachmentHandler = (e) => {
    e.preventDefault();
    setOpenLinkContentsModal(true);
  };

  const submitLinkContentsHandler = async (
    verticalIdsTobeAdded,
    offerIdsTobeAdded,
    addonWithPpvIdsToBeAdded,
    verticalIdsTobeRemoved,
    offerIdsTobeRemoved,
    addonWithPpvIdsToBeRemoved
  ) => {
    setIsModalLoading(true);
    if (
      verticalIdsTobeAdded?.length > 0 ||
      offerIdsTobeAdded?.length > 0 ||
      addonWithPpvIdsToBeAdded?.length > 0
    ) {
      const result1 = await contentManager.attachContentApi({
        contentId: data?.id,
        verticalIds: verticalIdsTobeAdded,
        planIds: offerIdsTobeAdded,
        addOnIds: addonWithPpvIdsToBeAdded,
      });
      if (result1?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result1?.message || result1));
        setIsModalLoading(false);
        return;
      }
    }
    if (
      verticalIdsTobeRemoved?.length > 0 ||
      offerIdsTobeRemoved?.length > 0 ||
      addonWithPpvIdsToBeRemoved?.length > 0
    ) {
      const result2 = await contentManager.detachContentApi({
        contentId: data?.id,
        verticalIds: verticalIdsTobeRemoved,
        planIds: offerIdsTobeRemoved,
        addOnIds: addonWithPpvIdsToBeRemoved,
      });
      if (result2?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result2?.message || result2));
        setIsModalLoading(false);
        return;
      }
    }
    if (
      verticalIdsTobeAdded?.length === 0 &&
      offerIdsTobeAdded?.length === 0 &&
      addonWithPpvIdsToBeAdded?.length === 0 &&
      verticalIdsTobeRemoved?.length === 0 &&
      offerIdsTobeRemoved?.length === 0 &&
      addonWithPpvIdsToBeRemoved?.length === 0
    ) {
      setIsModalLoading(false);
      return;
    }
    setIsModalLoading(false);
    setOpenLinkContentsModal(false);
    fetchData();
  };

  const submitLinkChannelWithEpgHandler = async (selectedChannelId) => {
    setIsModalLoading(true);
    let result;
    if (selectedChannelId) {
      result = await channelManager.attachEpgChannelApi({
        liveTvId: data?.id,
        epgChannelId: selectedChannelId,
      });
    } else {
      result = await channelManager.detachEpgChannelApi({
        liveTvId: data?.id,
      });
    }
    if (result?.status === 0) {
      setOpenLinkChannelWithEpgModal(false);
      fetchData();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsModalLoading(false);
  };

  const unlinkEpgChannelHandler = async (e) => {
    e.preventDefault();
    setIsModalLoading(true);
    const result = await channelManager.detachEpgChannelApi({
      liveTvId: data?.id,
    });
    if (result?.status === 0) {
      fetchData();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsModalLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        isOpen={isModalOpen}
        onClose={onClose}
        top="1rem"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <h2>
              {isEditing
                ? t("modify") + " " + contentModel.getNameByLang()
                : t("newChannel")}
            </h2>
            <form onSubmit={submitHandler}>
              <div className={classes.gridLayout}>
                <div className={classes.btnLinkContainer}>
                  {langListDataSource.length > 1 && (
                    <div ref={ref} className={classes.langListContainer}>
                      <LanguagesList
                        dataSource={langListDataSource}
                        selectedLang={selectedLanguage}
                        setSelectedLang={setSelectedLanguage}
                        width={ref.current?.clientWidth}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.fileContainer}>
                  <div>
                    <img alt="logo" src={logoImage} className={classes.image} />
                    {/* <input
                  type="file"
                  className={classes.fileInput}
                  onChange={logoImageChangeHandler}
                /> */}
                  </div>
                  {/* <span>{t("logo_format")}</span> */}
                </div>
                <div>
                  <div
                    className={`${classes.gridRow} ${classes.columnStart} ${classes.noMarginTop} ${classes.row}`}
                  >
                    <div className={classes.linkActionsContainer}>
                      {!isModalLoading ? (
                        <>
                          <Button
                            className={classes.btn}
                            title={t(
                              channelId ? "edit_link_with_epg" : "link_with_epg"
                            )}
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenLinkChannelWithEpgModal(true);
                            }}
                          />
                          {channelId && (
                            <Button
                              title={t("unlink_epg")}
                              className={`${classes.btn} ${classes.marginLeft}`}
                              onClick={unlinkEpgChannelHandler}
                            />
                          )}
                        </>
                      ) : (
                        <Loader
                          positionNotFixed={true}
                          className={classes.loader}
                        />
                      )}
                    </div>
                  </div>
                  <CustomInput
                    label={t("name")}
                    row
                    className={classes.customInput}
                    value={names[selectedLanguage] || ""}
                    onChange={(e) =>
                      setNames({ ...names, [selectedLanguage]: e.target.value })
                    }
                    disabled={true}
                  />
                  {channelId && (
                    <CustomInput
                      label={t("epg")}
                      row
                      className={classes.customInput}
                      value={channelId || ""}
                      disabled={true}
                    />
                  )}
                  {categories?.length > 0 && (
                    <div
                      className={`${classes.theme} ${classes.gridRow} ${classes.alignStart}`}
                    >
                      <label>{t("themes")}</label>
                      <div className={classes.pickerViewContainer}>
                        <PickerView
                          grid={true}
                          dataSource={categories}
                          allowMultipleSelections={true}
                          imageName="checkbox-checked"
                          pickerCellHeight={35}
                          pickerCellImageHeight={20}
                          pickerCellClassName={classes.pickerCell}
                          pickerViewClassName={classes.pickerView}
                          maxSelectedItemsLength={4}
                          initialSelectedItems={selectedCatValues}
                          onSelectItems={selectItemsHandler}
                          isDisabled={true}
                        />
                        {/* <span>{t("only_4_themes")}</span> */}
                      </div>
                    </div>
                  )}
                  {rank && (
                    <CustomInput
                      label={t("rank")}
                      row
                      className={`${classes.customInput}`}
                      inputClassName={isRankTaken ? classes.taken : ""}
                      type={"number"}
                      value={rank}
                      onChange={changeRankInputHandler}
                      disabled={true}
                    />
                  )}
                  {channelUrl && (
                    <CustomInput
                      label={t("url")}
                      row
                      className={classes.customInput}
                      value={channelUrl}
                      disabled={true}
                    />
                  )}
                  {age && (
                      <CustomInput
                        label={t("age")}
                        row
                        className={classes.customInput}
                        value={age}
                        disabled={true}
                      />
                  )}
                  {offers?.length > 0 && (
                    <div
                      className={`${classes.gridRow} ${classes.alignStart} ${classes.noMargin}`}
                    >
                      <label>{t("offers")}</label>
                      <div>
                        {offers?.map((offer, index) => (
                          <CustomInput
                            className={classes.input}
                            key={offer?.id || index}
                            value={offer?.sku_name}
                            disabled={true}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {addOns?.length > 0 && (
                    <div
                      className={`${classes.gridRow} ${classes.alignStart} ${classes.noMargin}`}
                    >
                      <label>{t("add_ons")}</label>
                      <div>
                        {addOns?.map((addOn) => (
                          <CustomInput
                            className={classes.input}
                            key={addOn?.id}
                            value={addOn?.skuName}
                            disabled={true}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {ppv?.length > 0 && (
                    <div
                      className={`${classes.gridRow} ${classes.alignStart} ${classes.noMargin}`}
                    >
                      <label>{t("ppv")}</label>
                      <div>
                        {ppv?.map((p) => (
                          <CustomInput
                            className={classes.input}
                            key={p?.id}
                            value={p?.skuName}
                            disabled={true}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {verticals?.length > 0 && (
                    <div
                      className={`${classes.gridRow} ${classes.alignStart} ${classes.noMargin}`}
                    >
                      <label>{t("verticals")}</label>
                      <div>
                        {verticals?.map((vertical) => (
                          <CustomInput
                            className={classes.input}
                            key={vertical?.elementId}
                            value={vertical?.value}
                            disabled={true}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  <div
                    className={`${classes.gridRow} ${classes.columnStart} ${classes.noMarginTop}`}
                  >
                    <Button
                      className={`${classes.btn} ${classes.columnStart} ${classes.marginTop}`}
                      title={t("edit_attachments")}
                      onClick={openEditAttachmentHandler}
                    />
                  </div>
                </div>
              </div>
              <Line />
              <div className={classes.actionContainer}>
                <Button title={t("close")} type={"submit"} />
              </div>
            </form>
          </>
        )}
      </Modal>
      {openLinkContentsModal && (
        <LinkContentsToVerticalsAndPackagesModal
          isModalOpen={openLinkContentsModal}
          onClose={() => setOpenLinkContentsModal(false)}
          onSubmit={submitLinkContentsHandler}
          title={t("linkContents")}
          isModalLoading={isModalLoading}
          initialData={{
            verticals: verticals,
            offers: offers,
            addons: addOns,
            ppv: ppv,
          }}
        />
      )}
      {openLinkChannelWithEpgModal && (
        <LinkChannelWithEPGModal
          isModalOpen={openLinkChannelWithEpgModal}
          onClose={() => setOpenLinkChannelWithEpgModal(false)}
          onSubmit={submitLinkChannelWithEpgHandler}
          isModalLoading={isModalLoading}
          channelId={channelId}
          channelName={contentModel.getNameByLang()}
        />
      )}
    </>
  );
}

export default ChannelModal;
