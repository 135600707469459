import { t } from "i18next";

import classes from "./PageHeaderSearchSort.module.scss";
import ExpandableSelectButton from "../../../expandables/expandableSelectButton/ExpandableSelectButton";
import PickerView from "../../../pickerViews/pickerView/PickerView";
import SearchInput from "../../inputs/searchInput/SearchInput";

function PageHeaderSearchSort({
  pageTitle,
  secondPageTitle,
  hasSort,
  options,
  selectedSortType,
  onSelectSortType,
  searchInputValue,
  searchInputPlaceholder,
  onChangeSearchInput,
  onClearSearchIput,
  onBack,
}) {
  const selectSortTypeHandler = (items) => {
    onSelectSortType(items[0]);
  };

  return (
    <header className={classes.header}>
      {!secondPageTitle ? (
        <h2>{pageTitle}</h2>
      ) : (
        <div className={classes.pageTitleContainer}>
          <span onClick={onBack}>
            {pageTitle}
            <span className={classes.arrow}>{"<"}</span>
          </span>
          <h2>{secondPageTitle}</h2>
        </div>
      )}
      <div
        className={classes.searchSortContainer}
        // style={{ width:  `calc(100vw - 500.8px)` }}
      >
        {hasSort && (
          <ExpandableSelectButton
            className={classes.expBtn}
            expCardClassName={classes.expCard}
            title={t(selectedSortType?.id)}
          >
            <PickerView
              dataSource={options}
              pickerCellHeight={32}
              pickerCellImageHeight={18}
              pickerViewClassName={classes.pickerView}
              onSelectItems={selectSortTypeHandler}
              initialSelectedItems={[selectedSortType ? selectedSortType : ""]}
              imageName={"radio-checked"}
              preventUnselection={true}
            />
          </ExpandableSelectButton>
        )}
        <SearchInput
          className={classes.searchInput}
          placeholder={searchInputPlaceholder}
          value={searchInputValue}
          onChangeInput={onChangeSearchInput}
          onClear={onClearSearchIput}
        />
      </div>
    </header>
  );
}

export default PageHeaderSearchSort;
