import { PREFERENCES_API } from "../../ApiUrls";
import loaderCenter from "../../LoaderCenter";

let originalPreferredContents = []

class PreferredContentManager {

  getPreferredContentsApi = async () => {
    try {
      const response = await loaderCenter.get(
        PREFERENCES_API.PREFERRED_CONTENTS
      );
      const responseData = await response.json();
      originalPreferredContents = responseData?.data; // Store original data for search
      return responseData;
    } catch (err) {
      return err;
    }
  };

  reorderPreferredContentsApi = async (contentList) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.REORDER_PREFERRED_CONTENTS,
        { reorder: contentList } 
      );
      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  createPreferredContentsApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.CREATE_PREFERRED_CONTENT,
        data
      );
      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  }

  updatePreferredContentsApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.UPDATE_PREFERRED_CONTENT,
        data 
      );
      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  }

  deletePreferredContentsApi = async (id) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.DELETE_PREFERRED_CONTENT,
        { elementId: id } 
      );
      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  restorePreferredContentsApi = async (id) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.RESTORE_PREFERRED_CONTENT,
        { elementId: id} 
      );
      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  }

  getOriginalPreferredContents = () => {
    return originalPreferredContents;
  }

  searchManager = (dataSource, inputValue, field, secondField) => {
    if (!inputValue || inputValue=='') {
      return dataSource; // Return all items if no input value
    }
  
    const lowercaseInput = inputValue.toLowerCase().trim();
  
    return dataSource.filter((item) => {
      // If secondField is provided, search within the nested field
      if (secondField) {
        const fieldValue = item[field] && String(item[field][secondField]).toLowerCase();
        return fieldValue && fieldValue.includes(lowercaseInput);
      } else {
        // Otherwise, search in the main field
        const fieldValue = item[field] && String(item[field]).toLowerCase();
        return fieldValue && fieldValue.includes(lowercaseInput);
      }
    });
  };
}

const preferredContentManager = new PreferredContentManager();

export default preferredContentManager;
