import { t } from "i18next";

import InputImage from "../../ui/inputs/inputImage/InputImage";
import classes from "./PickerCell.module.scss";
import Circle from "../../ui/circle/Circle";
import ContentHelper from "../../../utils/ContentHelper";

// PickerCell component used for selecting items in a cell
function PickerCell({
  pickerCellData,
  imageName,
  disabledImageName,
  className,
  iconClassName,
  onSelectItem,
  selectedItems,
  allowMultipleSelections,
  isDisabled,
  pickerCellHeight,
  pickerCellImageHeight,
  iconPath,
  circleClassName,
  allowPickerCellBackgroundColor,
  disabledPickerCellIds = [],
  selectedLanguage,
}) {
  const isPickerCellDisabled = disabledPickerCellIds.find(
    (pc) => pc === pickerCellData?.id
  );

  const isChecked =
    selectedItems?.some((cell) => cell?.id === pickerCellData?.id) ||
    isPickerCellDisabled;

  // Function to handle image click events
  const clickImageHandler = () => {
    // Allow item selection if it's not disabled
    !isDisabled &&
      !isPickerCellDisabled &&
      onSelectItem(pickerCellData, !isChecked);
  };

  // Determine the image based on various conditions
  let image;
  if (isChecked) {
    if (!isDisabled && !isPickerCellDisabled) {
      // Choose checked image based on conditions
      if (pickerCellData.id === "selectAll") {
        image = "radio-purple-checked-big";
      } else {
        if (imageName) {
          image = imageName;
        } else {
          image = allowMultipleSelections
            ? "checkbox-checked"
            : "radio-purple-checked-big";
        }
      }
    } else {
      image = disabledImageName
        ? disabledImageName
        : `${imageName ? imageName : "checkbox-checked"}-disabled`; // Set disabled image if disabled
    }
  } else {
    // Choose unchecked image based on conditions
    pickerCellData.id === "selectAll"
      ? (image = "radio-big")
      : (image = allowMultipleSelections
          ? isDisabled
            ? "checkbox-disabled"
            : "checkbox"
          : "radio-big");
  }

  // Calculate top position for the image
  const top = pickerCellHeight
    ? pickerCellHeight / 2 -
      (pickerCellImageHeight ? pickerCellImageHeight / 2 : "7")
    : "";
  const style = {
    height: pickerCellImageHeight ? pickerCellImageHeight : "15px",
    top: top,
  };

  const label = ContentHelper.getPickerCellName(
    pickerCellData,
    selectedLanguage
  );

  return (
    <>
      <div
        className={`${classes.cell} ${className} ${
          pickerCellData.id === "selectAll" && classes.bold
        } ${allowPickerCellBackgroundColor && isChecked && classes.bgColor} ${
          isDisabled && classes.defaultCursor
        }`}
        onClick={clickImageHandler} // Handle click events
      >
        <div
          style={{
            height: pickerCellHeight ? pickerCellHeight : "max-content",
          }}
        >
          {/* Render input image based on state */}
          <InputImage
            alt={allowMultipleSelections ? "checkbox" : "radio"}
            imagePath={`input-select/${image}`}
            style={style}
          />
          {/* Render icon if provided */}
          {iconPath && (
            <img
              style={style}
              className={`${classes.icon} ${iconClassName}`}
              alt="icon"
              src={require(`../../../assets/img/${iconPath}.png`)}
            />
          )}
          {pickerCellData?.circleColor && (
            <Circle
              className={circleClassName}
              color={pickerCellData.circleColor}
            />
          )}
          {/* Render label text */}
          <label>{t(label)}</label>
        </div>
      </div>
    </>
  );
}

export default PickerCell;
