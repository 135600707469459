import { useState } from "react";
import { t } from "i18next";

import classes from "./DashboardBarChart.module.scss";
import BarChart from "../chart/BarChart";

const DashboardBarChart = ({
  dataSource = { monthlyData: [], dailyData: [] },
  options,
  colors,
}) => {
  // State to manage selected period
  const [period, setPeriod] = useState("monthly");

  // Choose data based on selected period
  const data =
    period === "monthly"
      ? dataSource?.monthlyData
      : period === "daily"
      ? dataSource?.dailyData
      : dataSource?.weeklyData;

  return (
    <div>
      <div className={classes.optionContainer}>
        {options?.map((option) => (
          <label key={option} className={classes.option}>
            <input
              type="radio"
              value={option}
              checked={period === option}
              onChange={() => setPeriod(option)}
            />
            {t(option)}
          </label>
        ))}
      </div>
      <BarChart
        data={data}
        xAxisTitle={"Active Users"}
        yAxisTitle={
          period === "monthly" ? "Month" : period === "daily" ? "Day" : "Week"
        }
        colors={colors}
      />
    </div>
  );
};

export default DashboardBarChart;
