import { t } from "i18next";

import classes from "./ChannelContentCard.module.scss";
import ContentModel from "../../../models/ContentModel";
import ContentCard from "../contentCard/ContentCard";

function ChannelContentCard({
  content,
  // selectedItems,
  // onSelectItems,
  onEdit,
  // onDelete,
  onSetIsDragging,
  setHoveredCategoryId,
}) {
  const contentModel = new ContentModel(content);
  const image = contentModel.getPosterImage();

  return (
    <ContentCard
      className={classes.channelCard}
      style={{ backgroundImage: "none" }}
      content={content}
      onEdit={onEdit}
      // onDelete={onDelete}
      // selectedItems={selectedItems}
      // onSelectItems={onSelectItems}
      onSetIsDragging={onSetIsDragging}
      setHoveredCategoryId={setHoveredCategoryId}
      ghostName={t("channels")}
    >
      <div className={classes.foreground}>
        {contentModel.data?.channelId && (
          <img
            className={classes.linked}
            src={require("../../../assets/img/icons/linked.png")}
            alt="linked"
          />
        )}
        <div className={classes.container}>
          <span>{contentModel.data?.ranking}</span>
          <div className={classes.imgContainer}>
            {image && (
              <img className={classes.img} alt={t("logo")} src={image} />
            )}
          </div>
          <span>{contentModel.getNameByLang()}</span>
        </div>
      </div>
    </ContentCard>
  );
}

export default ChannelContentCard;
