import classes from "./CustomSelectInput.module.scss";

function CustomSelectInput({
  label,
  options,
  className,
  onChange,
  value,
  disabled,
  optionValueField,
}) {
  return (
    <div
      className={`${classes.inputModalSelect} ${className}`}
      style={{ gridTemplateColumns: label ? "30% 70%" : "100%" }}
    >
      {label && <label>{label}</label>}
      <select
        className={disabled && classes.disabled}
        onChange={onChange}
        value={value?.id}
        disabled={disabled}
      >
        {options?.map((option) => (
          <option
            key={option?.id ? option.id : option?.name ? option.name : option}
            value={option?.id ? option.id : option}
          >
            {option[optionValueField]
              ? option[optionValueField]
              : option?.pickerName
              ? option.pickerName
              : option?.name
              ? option.name
              : option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CustomSelectInput;
