import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEmpty: false,
  selectedItems: [],
};

const recipientsSlice = createSlice({
  name: "recipients",
  initialState,
  reducers: {
    setIsEmpty(state, action) {
      state.isEmpty = action.payload;
    },
    setSelectedItems(state, action) {
      state.selectedItems = action.payload;
    },
  },
});

export const recipientsActions = recipientsSlice.actions;

export default recipientsSlice;
