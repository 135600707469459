import { useState } from "react";

import classes from "./MessageItem.module.scss";
// import DateHelper from "../../../utils/DateHelper";
import messageManager from "../../../backend/managers/MessageManager";
import Loader from "../../ui/loader/Loader";

function MessageItem({
  message,
  onSelectMessageItem,
  selectedMessageId,
  onRefresh,
}) {
  const [isLoading, setIsLoading] = useState(false);
  // Extract properties from the 'message' object
  const type = message?.type;
  const title = message?.getTitleByLang();
  const body = message?.getContentByLang();
  const isPinned = message?.pin;
  // const time = DateHelper.formatDate(message?.timeToSend, "hh:mm a");

  // Check if the current message is selected
  const isSelected = selectedMessageId === message?.id;

  // State for managing the pinned status of the message
  // const [isPinned, setIsPinned] = useState(message?.pin);

  // Determine the icon name based on the message type and selection status
  let iconName = "";
  switch (type) {
    case "type_alert_urgent":
      iconName = isSelected ? "-urgent-white" : "-urgent";
      break;
    case "type_alert_warn":
      iconName = isSelected ? "-warn-white" : "-warn";
      break;
    case "type_alert_info":
      iconName = isSelected ? "-info-white" : "-info";
      break;
    default:
      iconName = isSelected ? "-white" : "";
      break;
  }

  // Determine the pinned image based on selection and pin status
  let pinnedImage = "";
  if (isSelected) {
    pinnedImage = isPinned ? "-active-white" : "-white";
  } else {
    pinnedImage = isPinned ? "-active" : "";
  }

  // Function to handle selecting a message item
  const selectMessageItemHandler = () => {
    onSelectMessageItem(message);
  };

  // Function to toggle the pinned status of the message
  const togglePinHandler = async (e) => {
    e.stopPropagation();
    const messageData = {
      id: message?.id,
      type: message?.type,
      pin: !message?.pin,
      title: message?.messageData?.title,
      content: message?.messageData?.content,
    };
    setIsLoading(true);
    const result = await messageManager.updateMessageApi(messageData);
    if (result?.status === 0) {
      onRefresh();
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`${classes.messageItem} ${isSelected && classes.white} ${
        isSelected && classes.selected
      } ${isSelected && classes.showArrow}`}
      onClick={selectMessageItemHandler} // Click handler for selecting the message
    >
      <div className={classes.row}>
        <span className={classes.messageTitle}>{title}</span>
        {/* <span
          className={`${classes.messageTime} ${isSelected && classes.white}`}
        >
          {time}
        </span> */}
      </div>
      <div className={`${classes.row} ${classes.rowHeight}`}>
        <p>{body}</p>
        <div className={classes.icons}>
          {/* Icon representing the message type */}
          <img
            alt="type"
            src={require(`../../../assets/img/message/icon-message${iconName}.png`)}
          />
          {/* Icon for pinned status with click handler to toggle pin */}
          {isLoading ? (
            <Loader positionNotFixed={true} className={classes.loader} />
          ) : (
            <img
              alt="pinned"
              src={require(`../../../assets/img/message/message-pinned${pinnedImage}.png`)}
              className={classes.pinned}
              onClick={togglePinHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageItem;
