import { CONTENT_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";
import ContentHelper from "../../utils/ContentHelper";

let seriesMap = {},
  catMap = {},
  searchCatMap = {};
let categories = [],
  series = [];

class SeriesManager {
  getSeriesApi = async (signal) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.SERIES,
        {
          notEmpty: false,
        },
        signal
      );

      const responseData = await response.json();
      series = responseData.data;

      const map = {};
      series = series.map((s) => {
        if (s && s.id && !map[s.id]) {
          map[s.id] = s;
        }
        s.pickerName = ContentHelper.getNameByLang(s);
        return s;
      });
      seriesMap = map;
      return {
        data: series,
        dataMap: map,
        statusCode: responseData.statusCode,
      };
    } catch (err) {
      throw err;
    }
  };

  getSeriesCategoriesApi = async (signal) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.CATEGORIES,
        {
          type: "serie",
        },
        signal
      );

      const responseData = await response.json();
      const data = responseData.data;

      const allSeries = [];
      const allSeriesMap = {};
      if (data && data.length > 0) {
        data.forEach((cat) => {
          if (!catMap[cat.id]) {
            catMap[cat.id] = cat;
            cat.pickerName = ContentHelper.getNameByLang(cat);
            categories.push(cat);
          }
          cat?.series?.forEach((s) => {
            s.pickerName = ContentHelper.getNameByLang(s);
            if (!allSeriesMap[s.id]) {
              allSeries.push(s);
              allSeriesMap[s.id] = s;
            }
          });
        });
      }

      const displayAllObject = {
        id: "display_all",
        series: allSeries,
      };
      data.unshift(displayAllObject);
      catMap[displayAllObject.id] = displayAllObject;

      return {
        data: data,
        statusCode: responseData.statusCode,
      };
    } catch (err) {
      throw err;
    }
  };

  getSeriesSeasonsApi = async (seriesId) => {
    try {
      const response = await loaderCenter.post(
        CONTENT_API.SERIES_SEASONS(seriesId)
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getSeasonEpisodesApi = async (seasonId) => {
    try {
      const response = await loaderCenter.post(CONTENT_API.MEDIA, {
        season: seasonId,
        type: "episode",
      });

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getCategories() {
    return categories;
  }

  getSeries() {
    return series;
  }

  getLocalSeriesById(id) {
    if (id && seriesMap && seriesMap[id]) {
      return seriesMap[id];
    }
  }

  searchApi = async (inputValue) => {
    try {
      const response = await loaderCenter.post(CONTENT_API.SERIES, {
        search: inputValue.trim(),
        notEmpty: false,
      });

      const responseData = await response.json();
      const series = responseData.data;

      let array = [],
        catIndexMap = {};
      categories.forEach((c, index) => {
        if (!catIndexMap[c.id]) {
          catIndexMap[c.id] = index;
          array.push({ ...c, series: [] });
        }
      });

      series?.forEach((s) => {
        s.pickerName = ContentHelper.getNameByLang(s);
        if (s && s?.categories.length > 0) {
          s?.categories?.forEach((category) => {
            const index = catIndexMap[category.id];
            array[index].series.push(s);
          });
        }
      });

      searchCatMap = {};
      array.forEach((cat) => {
        if (!searchCatMap[cat.id]) {
          searchCatMap[cat.id] = cat;
        }
      });

      const displayAllObject = {
        id: "display_all",
        series: series,
      };
      array.unshift(displayAllObject);
      searchCatMap[displayAllObject.id] = displayAllObject;

      return array;
    } catch (err) {
      throw err;
    }
  };

  getLocalCategoryById(id) {
    return catMap[id];
  }

  getLocalSearchedCategoryById(id) {
    return searchCatMap[id];
  }

  dropSeriesApi = async (seriesId, catId) => {
    await loaderCenter.get(
      CONTENT_API.DROP_SERIES_IN_CATEGORY(seriesId, catId)
    );
  };

  //   editSeriesApi = async (seriesId, data) => {
  //     await loaderCenter.post(CONTENT_API.EDIT_VOD(seriesId), data);
  //   };

  deleteSeriesApi = async (seriesId) => {
    await loaderCenter.get(CONTENT_API.DELETE_SERIES(seriesId));
  };

  editCategoryApi = async (catId, data) => {
    await loaderCenter.post(CONTENT_API.EDIT_CATEGORY(catId), data);
  };

  deleteCategoryApi = async (catId) => {
    await loaderCenter.get(CONTENT_API.DELETE_CATEGORY(catId));
  };

  addSeasonApi = async (seriesId, number, name) => {
    await loaderCenter.post(CONTENT_API.ADD_SEASON(seriesId), {
      name: { en: name },
      description: { en: "" },
      number: number,
    });
  };

  deleteSeasonApi = async (seasonId) => {
    await loaderCenter.get(CONTENT_API.DELETE_SEASON(seasonId));
  };

  deleteEpisodeApi = async (seasonId, episodeId) => {
    await loaderCenter.get(CONTENT_API.DELETE_EPISODE(seasonId, episodeId));
  };
}

const seriesManager = new SeriesManager();

export default seriesManager;
