import { Fragment, useState } from "react";
import { t } from "i18next";

import classes from "./CustomerCard.module.scss";
import Card from "../../ui/card/Card";
import Circle from "../../ui/circle/Circle";
import Button from "../../ui/button/Button";
import backdropStyle from "../../../styles/Backdrop.module.scss";

import deviceIcon from "../../../assets/img/icons/device.svg";
import allDeviceIcon from "../../../assets/img/icons/all-devices.svg";

function CustomerCard({ customer, onClick }) {
  const logo =
    customer?.logo ||
    "https://pixelrayphotography.com/wp-content/uploads/2023/03/agency-logo-red.png";
  const customerName = customer?.customerName;
  const subscription = customer?.totalSubscriptions;
  const devices = customer?.activeDevices;
  const offers = customer?.offers;
  const options = customer?.options;

  const [showAllOptions, setShowAllOptions] = useState(false);
  const [allOptionsCardClassName, setAllOptionsCardClassName] = useState(
    classes.leftCard
  );
  const [position, setPosition] = useState();

  const showAllOptionsHandler = (e) => {
    setShowAllOptions(true);
    setAllOptionsCardClassName(
      e.clientX < 450 ? classes.rightCard : classes.leftCard
    );
    setPosition(
      e.clientX < 450
        ? document.getElementById("customer-card").getBoundingClientRect()
            .width + 16
        : "-16rem"
    );
  };

  return (
    <Card id="customer-card" className={classes.card}>
      {logo && <img alt="logo" src={logo} className={classes.logo} />}
      <span
        className={classes.name}
        style={{
          fontSize: logo ? "0.75rem" : "1rem",
          marginBottom: logo ? "1.2rem" : "0.9rem",
        }}
      >
        {customerName}
      </span>
      <div className={classes.infoContainer}>
        <div className={classes.row}>
          <img alt="icon" src={allDeviceIcon} />
          <div className={classes.content}>
            <span>{subscription + " " + t("subscriptions")}</span>
          </div>
        </div>
        <div className={classes.row}>
          <img alt="icon" src={deviceIcon} />
          <span>{devices + " " + t("devices")}</span>
        </div>
        {offers && (
          <div className={classes.row}>
            <img
              alt="icon"
              src={require("../../../assets/img/icons/file.png")}
            />
            <div className={classes.content}>
              {Array.isArray(offers) ? (
                offers?.map((offer, i) => (
                  <Fragment key={i}>
                    <span>
                      {offer}
                      {i < offers?.length - 1 && "\u2022"}
                    </span>
                  </Fragment>
                ))
              ) : (
                <span>{offers}</span>
              )}
            </div>
          </div>
        )}
        {options && (
          <div className={classes.row} onClick={showAllOptionsHandler}>
            <img
              alt="icon"
              src={require("../../../assets/img/icons/folder.png")}
            />
            <div className={classes.content}>
              {options?.map((option, i) => (
                <span key={i}>
                  {option}
                  {i < options?.length - 1 && "\u2022"}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      {showAllOptions && (
        <>
          <div
            className={backdropStyle.backdrop}
            onClick={() => setShowAllOptions(false)}
          />
          <Card
            className={`${classes.allOptionsCard} ${allOptionsCardClassName}`}
            style={{ left: position }}
          >
            <span>{t("allOptions") + ": "}</span>
            <ul className={classes.allOptionsList}>
              {options?.map((option) => (
                <li key={option}>
                  <Circle
                    color={"var(--grey-800)"}
                    className={classes.listBullet}
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Card>
        </>
      )}
      <Button
        title={t("view_more")}
        className={classes.btnViewMore}
        onClick={onClick}
      />
    </Card>
  );
}

export default CustomerCard;
