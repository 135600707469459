import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import classes from "./StoreLocationModal.module.scss";
import Modal from "../../ui/modal/Modal";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import LanguagesList from "../../languagesList/LanguagesList";
import useElementWidth from "../../../hooks/useElementWidth";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import ExpandableSelectInput from "../../expandables/expandableSelectInput/ExpandableSelectInput";
import PickerView from "../../pickerViews/pickerView/PickerView";
import ButtonTitleIcon from "../../ui/buttonTitleIcon/ButtonTitleIcon";
import DraggableItem from "../../items/draggableItem/DraggableItem";
import ContentHelper from "../../../utils/ContentHelper";
import storeLocationManager from "../../../backend/managers/preferences/StoreLocationManager";

function StoreLocationModal({
  isModalOpen,
  onClose,
  onSave,
  dataSource,
  itemData,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedEmirate, setSelectedEmirate] = useState();
  const [emirateInputValue, setEmirateInputValue] = useState({
    en: "",
    ar: "",
  });

  const initialEmirateRank =
    selectedEmirate?.ranking || selectedEmirate?.ranking === 0
      ? selectedEmirate.ranking
      : "";
  const initialTownRank =
    itemData?.data?.ranking || itemData?.data?.ranking === 0
      ? itemData.data.ranking
      : "";
  const initialTownName = itemData?.data?.name;
  const townEmirate = itemData?.townEmirate;
  const type = itemData?.type;
  const title = itemData
    ? type === "emirate"
      ? t("edit_emirate")
      : t("edit_town")
    : t("create_new_location");

  const originalEmirates =
    dataSource?.length === 0
      ? storeLocationManager.getOriginalStoreLocations()
      : dataSource;

  const [emirates, setEmirates] = useState(originalEmirates || []);
  const [towns, setTowns] = useState([]);
  const [townNameInput, setTownNameInput] = useState({ en: "", ar: "" });
  const [townRankInput, setTownRankInput] = useState(initialTownRank);
  const [emiarteRankInput, setEmirateRankInput] = useState(initialEmirateRank);

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const modalRef = useRef();
  const endOfDraggableItemListRef = useRef();

  const selectEmirateHandler = (items) => {
    const emirate = items?.length > 0 ? items[0] : null;
    if (emirate) {
      setEmirateInputValue(emirate?.name);
      setSelectedEmirate(emirate);
      setEmirateRankInput(emirate?.ranking);
      setEmirates(dataSource);
      setTowns(emirate?.areas);
    }
  };

  const inputChangeHandler = (e) => {
    const value = e.target?.value;
    setEmirateInputValue((prevState) => {
      return { ...prevState, [selectedLanguage]: value };
    });
    setEmirates(
      dataSource?.filter((item) =>
        ContentHelper.getNameByLang(item, selectedLanguage)
          ?.toLowerCase()
          ?.includes(value?.toLowerCase())
      )
    );
    setSelectedEmirate();
  };

  // const addEmirateInDBHandler = () => {
  //   const updatedEmirates = [...data];
  //   updatedEmirates.unshift({ id: inputValue });
  //   setEmirates(updatedEmirates);
  //   setSelectedEmirate({ id: inputValue });
  // };

  const addTownHandler = () => {
    const updatedTowns = [...towns];
    updatedTowns.push({
      fakeId: towns.length + 1 + "-" + Math.random(),
      name: { en: "", ar: "" },
    });
    setTowns(updatedTowns);
    setTimeout(() => {
      endOfDraggableItemListRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;
    const reorderedTowns = Array.from(towns);
    const [movedSection] = reorderedTowns.splice(source.index, 1);
    reorderedTowns.splice(destination.index, 0, movedSection);
    setTowns(reorderedTowns);
  };

  const changeTownNameHandler = (inputValue, town) => {
    const updatedTowns = towns?.map((item) =>
      (item?.id && item?.id === town?.id) ||
      (item?.fakeId && item?.fakeId === town?.fakeId)
        ? { ...item, name: { ...item?.name, [selectedLanguage]: inputValue } }
        : item
    );
    setTowns(updatedTowns);
  };

  const deleteTownHandler = (e, town) => {
    setTowns((prevState) => prevState.filter((item) => item?.id !== town?.id));
  };

  const saveHandler = () => {
    // EMIRATE
    const filteredTowns = towns.filter((town) => town?.name?.en !== "");
    if (
      selectedEmirate &&
      (JSON.stringify(selectedEmirate?.areas) !==
        JSON.stringify(filteredTowns) ||
        emiarteRankInput !== initialEmirateRank)
    ) {
      const updatedEmirate = { ...selectedEmirate };
      if (
        JSON.stringify(selectedEmirate?.areas) !== JSON.stringify(filteredTowns)
      ) {
        updatedEmirate.areas = filteredTowns.map((town, index) => {
          // delete town.fakeId;
          return { ...town, ranking: index + 1 };
        });
      }
      if (emiarteRankInput !== initialEmirateRank) {
        updatedEmirate.ranking = +emiarteRankInput;
      }
      onSave(updatedEmirate, "emirate");
    }
    // TOWN
    if (
      type === "town" &&
      (JSON.stringify(townNameInput) !== JSON.stringify(initialTownName) ||
        townRankInput !== initialTownRank ||
        townEmirate?.id !== selectedEmirate?.id)
    ) {
      const updatedTown = { ...itemData?.data };
      updatedTown.emirateId = selectedEmirate?.id;
      updatedTown.name = townNameInput;
      updatedTown.ranking = townRankInput;
      townNameInput?.en && onSave(updatedTown, "town");
    }
  };

  useEffect(() => {
    if (type === "emirate") {
      selectEmirateHandler([itemData?.data]);
    } else if (type === "town") {
      selectEmirateHandler([townEmirate]);
      setTownNameInput(initialTownName);
    }
  }, [itemData]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      className={classes.modal}
      top={"1rem"}
    >
      <h2>{title}</h2>
      <div className={classes.emirateContainer} ref={ref}>
        {divWidth && (
          <LanguagesList
            selectedLang={selectedLanguage}
            setSelectedLang={setSelectedLanguage}
            width={divWidth - 48}
          />
        )}
        <div className={classes.inputsContainer}>
          <ExpandableSelectInput
            label={t("emirate")}
            placeholder={t("select_emirate")}
            inputValue={emirateInputValue[selectedLanguage]}
            onInputChange={inputChangeHandler}
          >
            {emirates?.length > 0 ? (
              <PickerView
                dataSource={emirates}
                pickerCellHeight={30}
                pickerCellImageHeight={25}
                pickerViewClassName={classes.pickerView}
                pickerCellClassName={classes.pickerCell}
                onSelectItems={selectEmirateHandler}
                initialSelectedItems={[selectedEmirate]}
                selectedLanguage={selectedLanguage}
              />
            ) : (
              <div className={classes.noResultContainer}>
                <img
                  src={require("../../../assets/img/no-file.png")}
                  alt="no-file"
                />
                <span>{t("no_result_found")}</span>
                {/* <Button
                  title={t("add_in_database")}
                  className={classes.btn}
                  onClick={addEmirateInDBHandler}
                /> */}
              </div>
            )}
          </ExpandableSelectInput>
          <CustomInput
            label={t("rank")}
            type={"number"}
            placeholder={0}
            row={true}
            className={classes.rankInput}
            value={emiarteRankInput}
            onChange={(e) =>
              selectedEmirate && setEmirateRankInput(e.target.value)
            }
            disabled={type === "town"}
            showArrows={true}
            arrowsClassName={classes.arrows}
          />
          {type === "town" && (
            <>
              <CustomInput
                className={classes.townNameInputContainer}
                label={t("town_name")}
                value={townNameInput[selectedLanguage]}
                row={true}
                onChange={(e) =>
                  setTownNameInput((prevState) => {
                    return {
                      ...prevState,
                      [selectedLanguage]: e?.target?.value,
                    };
                  })
                }
              />
              <CustomInput
                label={t("rank")}
                type={"number"}
                placeholder={0}
                row={true}
                className={classes.rankInput}
                value={townRankInput}
                onChange={(e) => setTownRankInput(e?.target?.value)}
                showArrows={true}
                arrowsClassName={classes.arrows}
              />
            </>
          )}
        </div>
      </div>
      {selectedEmirate && type !== "town" && (
        <>
          <Line />
          <div className={classes.townsContainer}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${classes.droppableContainer} ${
                      towns?.length > 0 ? classes.marginBottom : ""
                    }`}
                  >
                    <div
                      className={
                        towns?.length > 0 ? classes.itemsContainer : ""
                      }
                    >
                      {towns?.map((town, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            const offset =
                              modalRef.current?.getBoundingClientRect().top ??
                              0;
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  left: "auto !important",
                                  top: snapshot.isDragging
                                    ? provided.draggableProps.style?.top -
                                      offset
                                    : "auto !important",
                                }}
                              >
                                <DraggableItem
                                  className={classes.draggableItemContainer}
                                  itemClassName={classes.draggableItem}
                                  draggingClassName={classes.dragging}
                                  dataSource={town}
                                  index={index}
                                  iconsList={[
                                    {
                                      id: "delete3",
                                      imagePath:
                                        "buttons/delete-purple-background",
                                      onClick: (e) =>
                                        deleteTownHandler(e, town),
                                    },
                                  ]}
                                  isDragging={snapshot.isDragging}
                                  placeholder={t("town_name")}
                                  initialValue={ContentHelper.getNameByLang(
                                    town,
                                    selectedLanguage
                                  )}
                                  onInputChange={(inputValue) =>
                                    changeTownNameHandler(inputValue, town)
                                  }
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                    </div>
                    <div ref={endOfDraggableItemListRef} />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <ButtonTitleIcon
              title={t("add_town")}
              imagePath={"icons/add-black"}
              isImgSvg={true}
              className={classes.btnAddTown}
              position={"right"}
              onClick={addTownHandler}
            />
          </div>
        </>
      )}
      <Line />
      <div className={classes.actionsContainer}>
        <Button
          title={t("cancel")}
          className={classes.btnCancel}
          onClick={onClose}
        />
        <Button title={t("btnSave")} onClick={saveHandler} />
      </div>
    </Modal>
  );
}

export default StoreLocationModal;
