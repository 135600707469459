import { useEffect, useState } from "react";
import { t } from "i18next";

import classes from "./Permissions.module.scss";
import ExpandableMenu from "../../expandables/expandableMenu/ExpandableMenu";
import ExpandablePickerView from "../../pickerViews/ExpandablePickerView/ExpandablePickerView";
import PickerView from "../../pickerViews/pickerView/PickerView";
import InputImage from "../../ui/inputs/inputImage/InputImage";

function Permissions({ initialValues, permissions, setPermissions }) {
  const [devices, setDevices] = useState({
    edit: {
      isChecked: (initialValues && initialValues["devices"]?.edit) || false,
    },
    delete: {
      isChecked: (initialValues && initialValues["devices"]?.delete) || false,
    },
  });
  const [messages, setMessages] = useState({
    type_message_info: {
      isChecked:
        initialValues &&
        initialValues["messages"]?.types?.find(
          (e) => e === "type_message" || e === "type_alert_info"
        )
          ? true
          : false,
    },
    type_warning_urgent: {
      isChecked:
        initialValues &&
        initialValues["messages"]?.types?.find(
          (e) => e === "type_alert_urgent" || e === "type_alert_warn"
        )
          ? true
          : false,
    },
  });

  const selectItemsHandler = (items, id) => {
    let object = { edit: true, delete: false };
    if (id === "profile") {
      object = { edit: true, delete: true };
    }
    if (items?.length > 0) {
      setPermissions((prevState) => {
        return { ...prevState, [id]: object };
      });
    } else {
      setPermissions((prevState) => {
        return { ...prevState, [id]: { edit: false, delete: false } };
      });
    }
  };

  const selectCustomPagesItemsHandler = (items) => {
    if (items?.filter((e) => e !== null)?.length > 0) {
      setPermissions((prevState) => {
        return {
          ...prevState,
          customPages: {
            edit: items?.find((e) => e?.id === "edit") ? true : false,
            delete: items?.find((e) => e?.id === "delete") ? true : false,
          },
        };
      });
    } else {
      setPermissions((prevState) => {
        return { ...prevState, customPages: { edit: false, delete: false } };
      });
    }
  };

  useEffect(() => {
    if (
      messages.type_message_info?.isChecked ||
      messages.type_warning_urgent?.isChecked
    ) {
      setPermissions((prevState) => {
        return {
          ...prevState,
          messages: {
            ...permissions?.messages,
            edit: true,
            delete: true,
          },
        };
      });
    } else {
      setPermissions((prevState) => {
        return {
          ...prevState,
          messages: {
            ...permissions?.messages,
            edit: false,
            delete: false,
          },
        };
      });
    }
    // eslint-disable-next-line
  }, [messages]);

  return (
    <>
      <ExpandableMenu
        title={t("devices")}
        expandableClassName={classes.expandable}
      >
        <div className={classes.container}>
          <div
            className={`${classes.row} ${classes.marginBottom}`}
            onClick={() => {
              setDevices((prevState) => {
                return {
                  ...prevState,
                  edit: { isChecked: !prevState?.edit?.isChecked },
                };
              });
              setPermissions((prevState) => {
                return {
                  ...prevState,
                  devices: {
                    edit: !devices?.edit?.isChecked,
                    delete: devices?.delete?.isChecked,
                  },
                };
              });
            }}
          >
            <InputImage
              imagePath={`input-select/${
                devices?.edit?.isChecked ? "checkbox-checked" : "checkbox"
              }`}
            />
            <label>{t("edit")}</label>
          </div>
          <div
            className={classes.row}
            onClick={() => {
              setDevices((prevState) => {
                return {
                  ...prevState,
                  delete: { isChecked: !prevState?.delete?.isChecked },
                };
              });
              setPermissions((prevState) => {
                return {
                  ...prevState,
                  devices: {
                    edit: devices?.edit?.isChecked,
                    delete: !devices?.delete?.isChecked,
                  },
                };
              });
            }}
          >
            <InputImage
              imagePath={`input-select/${
                devices?.delete?.isChecked ? "checkbox-checked" : "checkbox"
              }`}
            />
            <label>{t("delete")}</label>
          </div>
        </div>
      </ExpandableMenu>
      <ExpandableMenu
        title={t("messages")}
        expandableClassName={classes.expandable}
      >
        <div className={classes.container}>
          <div
            className={`${classes.row} ${classes.marginBottom}`}
            onClick={() => {
              setMessages((prevState) => {
                return {
                  ...prevState,
                  type_message_info: {
                    isChecked: !prevState?.type_message_info?.isChecked,
                  },
                };
              });
              setPermissions((prevState) => {
                return {
                  ...prevState,
                  messages: {
                    ...permissions?.messages,
                    types: !messages?.type_message_info?.isChecked
                      ? permissions?.messages?.types
                        ? [
                            ...permissions?.messages?.types,
                            "type_message",
                            "type_alert_info",
                          ]
                        : ["type_message", "type_alert_info"]
                      : permissions?.messages?.types?.filter(
                          (e) => e !== "type_message" && e !== "type_alert_info"
                        ),
                  },
                };
              });
            }}
          >
            <InputImage
              imagePath={`input-select/${
                messages?.type_message_info?.isChecked
                  ? "checkbox-checked"
                  : "checkbox"
              }`}
            />
            <label>{t("type_message_info")}</label>
          </div>
          <div
            className={classes.row}
            onClick={() => {
              setMessages((prevState) => {
                return {
                  ...prevState,
                  type_warning_urgent: {
                    isChecked: !prevState?.type_warning_urgent?.isChecked,
                  },
                };
              });
              setPermissions((prevState) => {
                return {
                  ...prevState,
                  messages: {
                    ...permissions?.messages,
                    types: !messages?.type_warning_urgent?.isChecked
                      ? permissions?.messages?.types
                        ? [
                            ...permissions?.messages?.types,
                            "type_alert_warn",
                            "type_alert_urgent",
                          ]
                        : ["type_alert_warn", "type_alert_urgent"]
                      : permissions?.messages?.types?.filter(
                          (e) =>
                            e !== "type_alert_warn" && e !== "type_alert_urgent"
                        ),
                  },
                };
              });
            }}
          >
            <InputImage
              imagePath={`input-select/${
                messages?.type_warning_urgent?.isChecked
                  ? "checkbox-checked"
                  : "checkbox"
              }`}
            />
            <label>{t("type_warning_urgent")}</label>
          </div>
        </div>
      </ExpandableMenu>
      <ExpandableMenu
        title={t("portal")}
        expandableClassName={classes.expandable}
      >
        <PickerView
          pickerViewClassName={`${classes.pickerView} ${classes.border}`}
          initialSelectedItems={
            permissions &&
            permissions["homeSettings"] &&
            permissions["homeSettings"].edit === true
              ? [{ id: "edit_home_settings" }]
              : []
          }
          dataSource={[{ id: "edit_home_settings" }]}
          allowMultipleSelections={true}
          pickerCellHeight={30}
          pickerCellImageHeight={20}
          onSelectItems={(items) => selectItemsHandler(items, "homeSettings")}
        />
        <PickerView
          pickerViewClassName={classes.pickerView}
          initialSelectedItems={
            permissions &&
            permissions["menu"] &&
            permissions["menu"].edit === true
              ? [{ id: "edit_menu" }]
              : []
          }
          dataSource={[{ id: "edit_menu" }]}
          allowMultipleSelections={true}
          pickerCellHeight={30}
          pickerCellImageHeight={20}
          onSelectItems={(items) => selectItemsHandler(items, "menu")}
        />
        <ExpandablePickerView
          className={classes.expPicker}
          title={t("customPage")}
          initialSelectedItems={[
            permissions &&
            permissions["customPages"] &&
            permissions["customPages"]?.edit
              ? { id: "edit" }
              : null,
            permissions &&
            permissions["customPages"] &&
            permissions["customPages"]?.delete
              ? { id: "delete" }
              : null,
          ]}
          dataSource={[{ id: "edit" }, { id: "delete" }]}
          onSelectItems={selectCustomPagesItemsHandler}
        />
      </ExpandableMenu>
      <PickerView
        pickerViewClassName={`${classes.pickerView} ${classes.profile}`}
        initialSelectedItems={
          permissions &&
          permissions["profile"] &&
          permissions["profile"].edit === true
            ? [{ id: "profileManagement" }]
            : []
        }
        dataSource={[{ id: "profileManagement" }]}
        allowMultipleSelections={true}
        pickerCellHeight={30}
        pickerCellImageHeight={20}
        onSelectItems={(items) => selectItemsHandler(items, "profile")}
      />
    </>
  );
  // return PERMISSIONS?.map((section, index) => {
  //   return section?.id !== "profileManagement" ? (
  //     <ExpandableMenu
  //       key={section.id}
  //       id={section.id}
  //       title={t(section.id)}
  //       expandableClassName={classes.expandable}
  //       hasPickerView={section?.dataSource ? true : false}
  //       dataSource={section?.dataSource}
  //       onSelectExpItems={selectExpItemsHandler}
  //       // initialSelectedItems={
  //       //   (initialValues &&
  //       //     initialValues[section?.id] &&
  //       //     Object.keys(initialValues[section?.id])?.map((e) => {
  //       //       return { id: e };
  //       //     })) ||
  //       //   []
  //       // }
  //     >
  //       {section?.subSections &&
  //         section.subSections?.map((subSection) => (
  //           <ExpandablePickerView
  //             key={subSection.id}
  //             id={subSection.id}
  //             className={classes.expPicker}
  //             title={t(subSection.title)}
  //             dataSource={subSection?.dataSource}
  //             onSelectItems={selectItemsHandler}
  //           />
  //         ))}
  //     </ExpandableMenu>
  //   ) : (
  //     <PickerView
  //       key={index}
  //       pickerViewClassName={classes.userMgtPickerView}
  //       dataSource={[section]}
  //       allowMultipleSelections={true}
  //       pickerCellHeight={30}
  //       pickerCellImageHeight={20}
  //       onSelectItems={selectProfileManagementHandler}
  //     />
  //   );
  // });
}

export default Permissions;
