/**
 * Logger class provides static methods to enable/disable logging and log messages/errors.
 */
class Logger {
  // Static property to determine whether logging is enabled globally
  static loggingEnabled = true;

  /**
   * Constructor initializes logging as enabled for individual instances.
   */
  constructor() {
    this.loggingEnabled = true;
  }

  /**
   * Static method to globally enable logging.
   */
  static enableLogging() {
    this.loggingEnabled = true;
  }

  /**
   * Static method to globally disable logging.
   */
  static disableLogging() {
    this.loggingEnabled = false;
  }

  /**
   * Static method to log messages if logging is enabled.
   * @param {...any} args - Arguments to be logged.
   */
  static log(...args) {
    // Log the arguments if logging is enabled
    if (this.loggingEnabled) {
      console.log(...args);
    }
  }

  /**
   * Static method to log errors with a specified tag and message if logging is enabled.
   * @param {string} tag - Tag or identifier for the error.
   * @param {string} message - Error message to be logged.
   */
  static error(tag, message) {
    // Log error with specified tag and message if logging is enabled
    if (this.loggingEnabled) {
      console.error(tag + ": ", message);
    }
  }
}

export default Logger;
