import { useRef, useState } from "react";
import { t } from "i18next";

import classes from "./ThemeModal.module.scss";
import Modal from "../../ui/modal/Modal";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Loader from "../../ui/loader/Loader";
import LanguagesList from "../../languagesList/LanguagesList";
import channelManager from "../../../backend/managers/ChannelManager";
import useElementWidth from "../../../hooks/useElementWidth";

const THEME_ICONS = [
  "Drame",
  "Evenements",
  "Fantastic",
  "historique",
  "Horreur",
  "Kids",
  "Music",
  "News",
  "Pin",
  "Policier",
  "Recently added",
  "Restau",
  "Room servive_Hover",
  "Shopping",
  "Sortir",
  "Sy-fy",
  "Thriller",
  "Weather",
];

function CategoryModal({
  isModalOpen,
  onClose,
  category,
  onSubmit,
  isLoadingModal,
  hasIcon,
}) {
  const initialIcon = category?.iconUrl;
  const initialName = category?.name;
  const initialOrder = category?.order;

  const [names, setNames] = useState(
    initialName ? initialName : { en: "", ar: "" }
  );
  const [rank, setRank] = useState(category ? initialOrder : "");
  const [icon, setIcon] = useState();
  const [selectedLang, setSelectedLang] = useState("en");

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const categories = channelManager.getCategoriesWithoutDisplayAll();

  const iconFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setIcon(file);
    }
  };

  const selectIconHandler = async (icon) => {
    const response = await fetch(
      require(`../../../assets/img/theme/${icon}.svg`)
    );
    const blob = await response.blob();
    const file = new File([blob], `${icon}.svg`, { type: blob.type });
    setIcon(file);
  };

  const submitHandler = () => {
    if (names.en !== "" && rank) {
      if (!category && !icon) {
        return;
      }
      if (category) {
        if (
          JSON.stringify(initialName) === JSON.stringify(names) &&
          rank === initialOrder &&
          !icon
        ) {
          return;
        }
      }
      onSubmit(names, icon, rank, category?.id);
    }
  };

  const iconUrl = icon
    ? URL.createObjectURL(icon)
    : initialIcon
    ? initialIcon
    : require("../../../assets/img/card-theme-icon.png");

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      className={classes.modal}
      top={"0.5rem"}
    >
      <h2>
        {category
          ? t("edit_category", { name: initialName?.en })
          : t("createCategory")}
      </h2>
      <div className={hasIcon ? classes.gridContainer : classes.container}>
        <div ref={ref} className={classes.langContainer}>
          {divWidth && (
            <LanguagesList
              dataSource={["en", "ar"]}
              selectedLang={selectedLang}
              setSelectedLang={setSelectedLang}
              className={classes.langList}
              width={divWidth}
            />
          )}
        </div>
        <div className={classes.uploadContainer}>
          {hasIcon && (
            <div className={classes.fileContainer}>
              <div className={classes.icon}>
                <img
                  alt="icon"
                  src={iconUrl}
                  className={icon || initialIcon ? classes.img : ""}
                />
              </div>
              <input
                type="file"
                accept="image/*,image/svg+xml"
                className={classes.fileInput}
                onChange={iconFileChangeHandler}
              />
            </div>
          )}
          <span>{t("upload_icon_theme")}</span>
        </div>
        <div>
          <CustomInput
            placeholder={t("name")}
            row={true}
            label={t("name")}
            value={names[selectedLang]}
            onChange={(e) =>
              setNames((prevState) => {
                return { ...prevState, [selectedLang]: e.target.value };
              })
            }
          />
          <CustomInput
            type={"number"}
            placeholder={t("rank")}
            row={true}
            label={t("rank")}
            value={rank}
            onChange={(e) => setRank(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.iconsContainer}>
        <span className={classes.text}>{t("choose_cat_icon")}</span>
        <Line />
        <ul className={classes.iconList}>
          {THEME_ICONS.map((icon) => (
            <div
              key={icon}
              className={classes.iconItem}
              onClick={() => selectIconHandler(icon)}
            >
              <img
                src={require(`../../../assets/img/theme/${icon}.svg`)}
                alt="icon"
              />
            </div>
          ))}
        </ul>
        <Line />
      </div>
      <div className={classes.themesContainer}>
        <span className={classes.text}>{t("existingThemes")}</span>
        <ul className={classes.themeList}>
          {categories?.map(
            (cat) =>
              cat?.id !== 0 && (
                <div key={cat?.id} className={classes.catItem}>
                  <img src={cat?.iconUrl} alt="icon" />
                  <span>{cat?.order}</span>
                  <span>{cat?.name?.en}</span>
                </div>
              )
          )}
        </ul>
      </div>
      <Line />
      <div className={classes.actionContainer}>
        {isLoadingModal ? (
          <Loader positionNotFixed={true} className={classes.loader} />
        ) : (
          <Button title={t("btnSave")} onClick={submitHandler} />
        )}
      </div>
    </Modal>
  );
}

export default CategoryModal;
