import { forwardRef, useState } from "react";

import classes from "./CustomInput.module.scss";
import ButtonIcon from "../../buttonIcon/ButtonIcon";

const CustomInput = forwardRef(
  (
    {
      imagePath,
      activeImagePath,
      className,
      inputClassName,
      spanClassName,
      label,
      placeholder,
      type,
      value,
      hasIcon,
      onChange = () => {},
      onClick,
      onInputClick,
      row,
      id,
      span,
      btnClassName,
      required,
      disabled,
      onKeyDown,
      autoFocus,
      showArrows,
      isImgSvg,
      onInputFocused,
      onInputBlurred,
      arrowsClassName,
    },
    ref
  ) => {
    let height;
    if (!row && !span) {
      height = "52px";
    } else if (!row && span) {
      height = "100px";
    }
    if (row && !span) {
      height = "31px";
    } else if (row && span) {
      height = "66px";
    }

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      setIsFocused(true);
      onInputFocused && onInputFocused();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onInputBlurred && onInputBlurred();
    };

    const handleIncrement = () => {
      if (type === "number" && !disabled) {
        const newValue = (parseFloat(value) || 0) + 1;
        onChange({ target: { value: newValue } });
      }
    };

    const handleDecrement = () => {
      if (type === "number" && !disabled) {
        const newValue = (parseFloat(value) || 0) - 1;
        onChange({ target: { value: newValue } });
      }
    };

    return (
      <div
        className={`${classes.customInputContainer} ${
          row && classes.row
        } ${className}`}
        style={{ height: height }}
      >
        {label && (
          <label>
            {label} <span className={classes.star}>{required ? "*" : ""}</span>
          </label>
        )}
        <div
          className={`${classes.customInput} ${
            isFocused ? classes.focused : ""
          } ${disabled && classes.disabled} ${inputClassName}`}
          style={{ height: span ? "31px" : "fit-content" }}
          onClick={onInputClick}
        >
          <input
            className={!hasIcon ? classes.inputWithoutIcon : ""}
            id={id}
            type={type}
            placeholder={placeholder}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => onChange(e)}
            disabled={disabled}
            autoComplete="new-password"
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            ref={ref}
          />

          {type === "number" && showArrows && !disabled && (
            <div className={`${classes.customArrows} ${arrowsClassName}`}>
              <button
                type="button"
                className={classes.incrementButton}
                onClick={handleIncrement}
              >
                ▲
              </button>
              <button
                type="button"
                className={classes.decrementButton}
                onClick={handleDecrement}
              >
                ▼
              </button>
            </div>
          )}

          {hasIcon && !disabled && (
            <ButtonIcon
              className={`${classes.btn} ${btnClassName}`}
              onClick={onClick}
              imagePath={imagePath}
              activeImagePath={activeImagePath}
              isSvg={isImgSvg}
            />
          )}
        </div>
        {span && (
          <span className={`${classes.span} ${spanClassName}`}>{span}</span>
        )}
      </div>
    );
  }
);

export default CustomInput;
