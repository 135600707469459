import { t } from "i18next";

import classes from "./SearchableGroupedPickerView.module.scss";
import SearchInput from "../../ui/inputs/searchInput/SearchInput";
import InputImage from "../../ui/inputs/inputImage/InputImage";
import GroupedPickerView from "./GroupedPickerView";

function SearchableGroupedPickerView({
  title,
  inputPlaceholder,
  dataSource = [],
  onSelectItems = () => {},
  isDisabled,
  onSelectAll = () => {},
  isSelectAllChecked,
  setIsSelectAllChecked = () => {},
  onSearchCustomer = () => {},
  onSearchSubscriber = () => {},
  // initial selected items of customers including only selected subscribers
  initialSelectedItems,
}) {
  return (
    <div className={classes.section}>
      <h2>{title}</h2>
      <SearchInput
        placeholder={inputPlaceholder}
        onChangeInput={onSearchCustomer}
      />
      {dataSource.length > 0 ? (
        <div className={classes.selectAllPickerViewContainer}>
          <div
            className={`${classes.selectAllRow} ${
              isSelectAllChecked && classes.selectAllChecked
            }`}
            // onClick={() => {
            //   onSelectAll(!isSelectAllChecked);
            //   setIsSelectAllChecked((prevState) => !prevState);
            // }}
          >
            {/* {!isDisabled && (
              <>
                <InputImage
                  imagePath={`input-select/checkbox${
                    isSelectAllChecked ? "-checked-bg-white" : ""
                  }`}
                />
                <label>{t("all")}</label>
              </>
            )} */}
          </div>
          <div className={classes.groupedPickerViewContainer}>
            <GroupedPickerView
              initialSelectedItems={initialSelectedItems}
              dataSource={dataSource}
              onSelectItems={onSelectItems}
              isDisabled={isDisabled}
              onSearchSubscriber={onSearchSubscriber}
            />
          </div>
        </div>
      ) : (
        <div className={classes.noContent}>
          <img
            alt="no-content"
            src={require("../../../assets/img/no-content.png")}
          />
          <p>{t("noContent")}</p>
        </div>
      )}
    </div>
  );
}

export default SearchableGroupedPickerView;
