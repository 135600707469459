import { PREFERENCES_API } from "../../ApiUrls";
import loaderCenter from "../../LoaderCenter";

let originalStoreLocations = [];

class StoreLocationManager {
  getStoreLocationsApi = async () => {
    try {
      const response = await loaderCenter.get(PREFERENCES_API.STORE_LOCATIONS);

      const responseData = await response.json();

      originalStoreLocations = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateEmirateApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.UPDATE_EMIRATE,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteEmirateApi = async (id) => {
    try {
      const response = await loaderCenter.post(PREFERENCES_API.DELETE_EMIRATE, {
        id,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  restoreEmirateApi = async (id) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.RESTORE_EMIRATE,
        {
          id,
        }
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  createAreaApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.CREATE_AREA,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  updateAreaApi = async (data) => {
    try {
      const response = await loaderCenter.post(
        PREFERENCES_API.UPDATE_AREA,
        data
      );

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  deleteAreaApi = async (id) => {
    try {
      const response = await loaderCenter.post(PREFERENCES_API.DELETE_AREA, {
        id,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  restoreAreaApi = async (id) => {
    try {
      const response = await loaderCenter.post(PREFERENCES_API.RESTORE_AREA, {
        id,
      });

      const responseData = await response.json();
      return responseData;
    } catch (err) {
      return err;
    }
  };

  getOriginalStoreLocations = () => {
    return originalStoreLocations;
  };
  
}

const storeLocationManager = new StoreLocationManager();

export default storeLocationManager;
