import accountManager from "../backend/managers/AccountManager"; 

export const refreshUserData = async () => {
  try {
    const userResult = await accountManager.getUserProfileApi();
    if (userResult?.status === 0) {
      localStorage.setItem("userData", JSON.stringify(userResult.data));
      return userResult.data;
    } else {
      console.error("Failed to fetch user profile:", userResult.message);
      return null;
    }
  } catch (error) {
    console.error("Error refreshing user data:", error);
    return null;
  }
};
