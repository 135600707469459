import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { t } from "i18next";

import classes from "./SidebarItem.module.scss";

function SideBarItem({ item, gridRowStart, onShowDrawer, onHideDrawer }) {
  // Destructure properties from the 'item' object
  const { id, to, contents, image: imageName } = item;

  // State to manage item's active state and image name
  const [isActive, setIsActive] = useState(false);
  const [imgName, setImgName] = useState(imageName);

  // Get the current location using useLocation hook
  const location = useLocation();

  // Effect to update the active state based on the route change
  useEffect(() => {
    if (
      contents?.map((c) => c.to).includes(location.pathname) ||
      to === location.pathname ||
      location.pathname.split(/(?=\/)/g).includes(to)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location, to, contents]);

  // Effect to set the active image based on the active state change
  useEffect(() => {
    setImgName(isActive ? imageName + "-active" : imageName);
  }, [isActive, imageName]);

  // Handler to show the drawer menu
  const onShowDrawerHandler = () => {
    onShowDrawer(contents, gridRowStart);
  };

  // Handler to hide the drawer menu
  const hideDrawerHandler = () => {
    onHideDrawer();
  };

  return (
    <li
      className={classes.navItem}
      onMouseEnter={onShowDrawerHandler}
      onMouseLeave={hideDrawerHandler}
      onClick={hideDrawerHandler}
    >
      {/* Link component to navigate to specified route */}
      <Link to={to} className={isActive ? classes.active : ""}>
        <img
          src={require(`../../../assets/img/sidebar/${imgName}.png`)}
          alt={imageName}
        />
        <span>{t(id)}</span>
      </Link>
    </li>
  );
}

export default SideBarItem;
